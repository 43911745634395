import { ApolloClient, FetchPolicy, gql } from "@apollo/client";
import { getStoreViewCodeForLocale, Locale } from "../i18n/locale";
import {
  Campaign,
  RemoteCampaign,
  transformRemoteCampaignToCampaign,
} from "../models/Campaign";
import {
  getVariantGraphQLAttributes,
  ModelKeys,
  ProductEnableAgeDeclaration,
  ProductEnableAgeDeclarationGraphQLAttributes,
  ProductEstimatedDeliveryDateGraphQLAttributes,
  ProductInstalmentGraphQLAttributes,
  ProductInstalmentType,
  ProductIsPreOrder,
  ProductIsPreOrderGraphQLAttributes,
  ProductOverviewBaseClubTierQuotaGraphQLAttributes,
  ProductOverviewVariantProductCubTierQuotaGraphQLAttributes,
  ProductThirdPartyProductShowPriceGraphQLAttributes,
  ProductThirdPartyProductShowPriceType,
  ProductVariantProduct,
  RemoteProductEstimatedDeliveryDate,
  RemoteProductOverviewBaseClubTierQuota,
  RemoteVariantProductEstimatedDeliveryDate,
  VariantProductClubTierQuota,
  VariantProductEnableAgeDeclaration,
  VariantProductEnableAgeDeclarationGraphQLAttributes,
  VariantProductEstimatedDeliveryDateGraphQLAttributes,
  VariantProductIsPreOrder,
  VariantProductIsPreOrderGraphQLAttributes,
} from "../models/product";
import {
  assembleProduct,
  Product,
  ProductBaseGraphQLAttributes,
  ProductDetailsAdditionalGraphQLAttributes,
  ProductDetailsRelatedProductsGraphQLAttributes,
  ProductRelatedProducts,
  RemoteProductAdditional,
  RemoteProductBase,
} from "../models/ProductDetails";
import {
  ProductOverview,
  ProductOverviewGraphQLAttributes,
} from "../models/ProductOverview";
import { filterNullOrUndefined } from "../utils/array";
import { parseGraphQLError } from "./GraphQL";

const campaignGraphQLAttributes = `
id: campaign_id
banner
description
end
image
qty
start
status
tc
title
venderId: vendor_id
`;

export function getCampaign(
  client: ApolloClient<unknown>,
  id: number
): Campaign | null {
  const result = client.readQuery<
    { campaign: { campaign: RemoteCampaign | null } | null },
    { campaignId: number }
  >({
    query: gql`
      query Campaign($campaignId: Int!) {
        campaign(campaign_id: $campaignId) {
          campaign {
            ${campaignGraphQLAttributes}
          }
        }
      }
    `,
    variables: {
      campaignId: id,
    },
  });

  if (result && result.campaign && result.campaign.campaign) {
    return transformRemoteCampaignToCampaign(result.campaign.campaign);
  }

  return null;
}

export async function fetchCampaign(
  client: ApolloClient<any>,
  id: number,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<Campaign> {
  try {
    const result = await client.query<
      { campaign: { campaign: RemoteCampaign | null } | null },
      { campaignId: number }
    >({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      query: gql`
        query Campaign($campaignId: Int!) {
          campaign(campaign_id: $campaignId) {
            campaign {
              ${campaignGraphQLAttributes}
            }
          }
        }
      `,
      variables: {
        campaignId: id,
      },
      fetchPolicy,
    });

    if (result.data.campaign && result.data.campaign.campaign) {
      return transformRemoteCampaignToCampaign(result.data.campaign.campaign);
    }

    throw new Error("Unknown error");
  } catch (e) {
    const graphqlError = parseGraphQLError(e);
    if (graphqlError) {
      throw new Error(graphqlError);
    }
    throw e;
  }
}

export function getCampaignProducts(
  client: ApolloClient<unknown>,
  id: number
): ProductOverview[] | null {
  const result = client.readQuery<
    {
      campaign: {
        campaignProducts: {
          items: (ProductOverview | null)[] | null;
        } | null;
      } | null;
    },
    { campaignId: number; page: number; pageSize: number }
  >({
    query: gql`
      query CampaignProducts(
        $campaignId: Int!
        $page: Int!
        $pageSize: Int!
      ) {
        campaign(campaign_id: $campaignId) {
          campaignProducts: campaign_products(
            currentPage: $page
            pageSize: $pageSize
          ) {
            items {
              ${ProductOverviewGraphQLAttributes}
            }
          }
        }
      }
    `,
    variables: {
      campaignId: id,
      // Only query first page of 100 items to assume no pagination
      page: 1,
      pageSize: 100,
    },
  });

  if (result && result.campaign && result.campaign.campaignProducts) {
    const { items: _items } = result.campaign.campaignProducts;
    const items = _items ? filterNullOrUndefined(_items) : [];
    return items;
  }

  return null;
}

export async function fetchCampaignProducts(
  client: ApolloClient<unknown>,
  id: number,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<ProductOverview[]> {
  try {
    const result = await client.query<
      {
        campaign: {
          campaignProducts: {
            items: (ProductOverview | null)[] | null;
          } | null;
        } | null;
      },
      { campaignId: number; page: number; pageSize: number }
    >({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      query: gql`
        query CampaignProducts(
          $campaignId: Int!
          $page: Int!
          $pageSize: Int!
        ) {
          campaign(campaign_id: $campaignId) {
            campaignProducts: campaign_products(
              currentPage: $page
              pageSize: $pageSize
            ) {
              items {
                ${ProductOverviewGraphQLAttributes}
              }
            }
          }
        }
      `,
      variables: {
        campaignId: id,
        // Only query first page of 100 items to assume no pagination
        page: 1,
        pageSize: 100,
      },
      fetchPolicy,
    });

    if (result.data.campaign && result.data.campaign.campaignProducts) {
      const { items: _items } = result.data.campaign.campaignProducts;
      const items = _items ? filterNullOrUndefined(_items) : [];
      return items;
    }

    throw new Error("Unknown error");
  } catch (e) {
    const graphqlError = parseGraphQLError(e);
    if (graphqlError) {
      throw new Error(graphqlError);
    }
    throw e;
  }
}

export function getFixedProducts(
  client: ApolloClient<unknown>,
  id: number
): ProductOverview[] | null {
  const result = client.readQuery<
    {
      campaign: {
        fixedProducts: {
          items: (ProductOverview | null)[] | null;
        } | null;
      } | null;
    },
    { campaignId: number; page: number; pageSize: number }
  >({
    query: gql`
      query FixedProducts(
        $campaignId: Int!
        $page: Int!
        $pageSize: Int!
      ) {
        campaign(campaign_id: $campaignId) {
          fixedProducts: fixed_products(
            currentPage: $page
            pageSize: $pageSize
          ) {
            items {
              ${ProductOverviewGraphQLAttributes}
            }
          }
        }
      }
    `,
    variables: {
      campaignId: id,
      // Only query first page of 100 items to assume no pagination
      page: 1,
      pageSize: 100,
    },
  });

  if (result && result.campaign && result.campaign.fixedProducts) {
    const { items: _items } = result.campaign.fixedProducts;
    const items = _items ? filterNullOrUndefined(_items) : [];
    return items;
  }

  return null;
}

export async function fetchFixedProducts(
  client: ApolloClient<unknown>,
  id: number,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<ProductOverview[]> {
  try {
    const result = await client.query<
      {
        campaign: {
          fixedProducts: {
            items: (ProductOverview | null)[] | null;
          } | null;
        } | null;
      },
      { campaignId: number; page: number; pageSize: number }
    >({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      query: gql`
        query FixedProducts(
          $campaignId: Int!
          $page: Int!
          $pageSize: Int!
        ) {
          campaign(campaign_id: $campaignId) {
            fixedProducts: fixed_products(
              currentPage: $page
              pageSize: $pageSize
            ) {
              items {
                ${ProductOverviewGraphQLAttributes}
              }
            }
          }
        }
      `,
      variables: {
        campaignId: id,
        // Only query first page of 100 items to assume no pagination
        page: 1,
        pageSize: 100,
      },
      fetchPolicy,
    });

    if (result.data.campaign && result.data.campaign.fixedProducts) {
      const { items: _items } = result.data.campaign.fixedProducts;
      const items = _items ? filterNullOrUndefined(_items) : [];
      return items;
    }

    throw new Error("Unknown error");
  } catch (e) {
    const graphqlError = parseGraphQLError(e);
    if (graphqlError) {
      throw new Error(graphqlError);
    }
    throw e;
  }
}

// Special function to fetch product from campaign
// because campaign product is not available
// in normal product query
export async function fetchCampaignProductBySKU(
  client: ApolloClient<unknown>,
  campaignId: number,
  sku: string,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<Product> {
  const query = async <T extends ModelKeys>(
    graphQLAttributes: string
  ): Promise<T> => {
    const result = await client.query<
      {
        campaign: {
          campaignProducts: {
            items: (T | null)[] | null;
          } | null;
        } | null;
      },
      { campaignId: number; page: number; pageSize: number }
    >({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      query: gql`
        query CampaignProducts(
          $campaignId: Int!
          $page: Int!
          $pageSize: Int!
        ) {
          campaign(campaign_id: $campaignId) {
            campaignProducts: campaign_products(
              currentPage: $page
              pageSize: $pageSize
            ) {
              items {
                ${graphQLAttributes}
              }
            }
          }
        }
      `,
      variables: {
        campaignId,
        page: 1,
        pageSize: 100,
      },
      fetchPolicy,
    });
    if (
      result.data.campaign &&
      result.data.campaign.campaignProducts &&
      result.data.campaign.campaignProducts.items
    ) {
      const items = filterNullOrUndefined(
        result.data.campaign.campaignProducts.items
      );
      const selectedProducts = items.filter(product => product.sku === sku);
      if (selectedProducts.length > 0) {
        return selectedProducts[0];
      }
    }
    throw new Error("Product not found");
  };
  const [
    [
      baseData,
      additionalData,
      relatedProductsData,
      clubTierQuotaData,
      enableAgeDeclarationData,
      variantProductClubTierQuota,
      variantEnableAgeDeclarationData,
      thirdPartyProductShowPriceData,
      instalmentData,
      variantInstalmentData,
    ],
    [
      isPreOrderData,
      variantIsPreOrderData,
      estimatedDeliveryDateData,
      variantEstimatedDeliveryDateData,
    ],
  ] = await Promise.all([
    Promise.all([
      query<RemoteProductBase>(ProductBaseGraphQLAttributes),
      query<RemoteProductAdditional>(ProductDetailsAdditionalGraphQLAttributes),
      query<ProductRelatedProducts>(
        ProductDetailsRelatedProductsGraphQLAttributes
      ).catch(() => null),
      // FEATURE_MERGE: club_tier_quota present in product interface => [0]
      query<RemoteProductOverviewBaseClubTierQuota>(
        ProductOverviewBaseClubTierQuotaGraphQLAttributes
      ).catch(() => null),
      // FEATURE_MERGE: enable_age_declaration present in product interface => [0]
      query<ProductEnableAgeDeclaration>(
        ProductEnableAgeDeclarationGraphQLAttributes
      ).catch(() => null),
      // FEATURE_MERGE: club_tier_quota present in product.variants.product interface => [0]
      query<VariantProductClubTierQuota>(
        ProductOverviewVariantProductCubTierQuotaGraphQLAttributes
      ).catch(() => null),
      // FEATURE_MERGE: enable_age_declaration present in product.variants.product interface => [0]
      query<VariantProductEnableAgeDeclaration>(
        VariantProductEnableAgeDeclarationGraphQLAttributes
      ).catch(() => null),
      query<ProductThirdPartyProductShowPriceType>(
        ProductThirdPartyProductShowPriceGraphQLAttributes
      ).catch(() => null),
      query<ProductInstalmentType>(ProductInstalmentGraphQLAttributes).catch(
        () => null
      ),
      query<ProductVariantProduct<ProductInstalmentType>>(
        getVariantGraphQLAttributes(ProductInstalmentGraphQLAttributes)
      ).catch(() => null),
    ]),
    Promise.all([
      query<ProductIsPreOrder>(ProductIsPreOrderGraphQLAttributes).catch(
        () => null
      ),
      query<VariantProductIsPreOrder>(
        VariantProductIsPreOrderGraphQLAttributes
      ).catch(() => null),
      query<RemoteProductEstimatedDeliveryDate>(
        ProductEstimatedDeliveryDateGraphQLAttributes
      ).catch(() => null),
      query<RemoteVariantProductEstimatedDeliveryDate>(
        VariantProductEstimatedDeliveryDateGraphQLAttributes
      ).catch(() => null),
    ]),
  ]);

  return assembleProduct(
    baseData,
    additionalData,
    relatedProductsData,
    clubTierQuotaData,
    enableAgeDeclarationData,
    variantProductClubTierQuota,
    variantEnableAgeDeclarationData,
    thirdPartyProductShowPriceData,
    instalmentData,
    variantInstalmentData,
    isPreOrderData,
    variantIsPreOrderData,
    estimatedDeliveryDateData,
    variantEstimatedDeliveryDateData
  );
}

export function getCampaignProductBySKU(
  client: ApolloClient<any>,
  campaignId: number,
  sku: string
): Product | null {
  const query = <T extends ModelKeys>(graphQLAttributes: string) => {
    const result = client.readQuery<
      {
        campaign: {
          campaignProducts: {
            items: (T | null)[] | null;
          } | null;
        } | null;
      },
      { campaignId: number; page: number; pageSize: number }
    >({
      query: gql`
        query CampaignProducts(
          $campaignId: Int!
          $page: Int!
          $pageSize: Int!
        ) {
          campaign(campaign_id: $campaignId) {
            campaignProducts: campaign_products(
              currentPage: $page
              pageSize: $pageSize
            ) {
              items {
                ${graphQLAttributes}
              }
            }
          }
        }
      `,
      variables: {
        campaignId,
        page: 1,
        pageSize: 100,
      },
    });
    if (
      result &&
      result.campaign &&
      result.campaign.campaignProducts &&
      result.campaign.campaignProducts.items
    ) {
      const items = filterNullOrUndefined(
        result.campaign.campaignProducts.items
      );
      const selectedProducts = items.filter(product => product.sku === sku);
      if (selectedProducts.length > 0) {
        return selectedProducts[0];
      }
    }
    return null;
  };
  const [
    baseData,
    additionalData,
    relatedProductsData,
    clubTierQuotaData,
    enableAgeDeclarationData,
    variantProductClubTierQuota,
    variantEnableAgeDeclarationData,
    thirdPartyProductShowPriceData,
    instalmentData,
    variantInstalmentData,
    isPreOrderData,
    variantIsPreOrderData,
    estimatedDeliveryDateData,
    variantEstimatedDeliveryDateData,
  ] = [
    query<RemoteProductBase>(ProductBaseGraphQLAttributes),
    query<RemoteProductAdditional>(ProductDetailsAdditionalGraphQLAttributes),
    query<ProductRelatedProducts>(
      ProductDetailsRelatedProductsGraphQLAttributes
    ),
    // FEATURE_MERGE: club_tier_quota present in product interface => [0]
    query<RemoteProductOverviewBaseClubTierQuota>(
      ProductOverviewBaseClubTierQuotaGraphQLAttributes
    ),
    // FEATURE_MERGE: enable_age_declaration present in product interface => [0]
    query<ProductEnableAgeDeclaration>(
      ProductEnableAgeDeclarationGraphQLAttributes
    ),
    // FEATURE_MERGE: club_tier_quota present in product.variants.product interface => [0]
    query<VariantProductClubTierQuota>(
      ProductOverviewVariantProductCubTierQuotaGraphQLAttributes
    ),
    // FEATURE_MERGE: enable_age_declaration present in product.variants.product interface => [0]
    query<VariantProductEnableAgeDeclaration>(
      VariantProductEnableAgeDeclarationGraphQLAttributes
    ),
    query<ProductThirdPartyProductShowPriceType>(
      ProductThirdPartyProductShowPriceGraphQLAttributes
    ),
    query<ProductInstalmentType>(ProductInstalmentGraphQLAttributes),
    query<ProductVariantProduct<ProductInstalmentType>>(
      getVariantGraphQLAttributes(ProductInstalmentGraphQLAttributes)
    ),
    query<ProductIsPreOrder>(ProductIsPreOrderGraphQLAttributes),
    query<VariantProductIsPreOrder>(VariantProductIsPreOrderGraphQLAttributes),
    query<RemoteProductEstimatedDeliveryDate>(
      ProductEstimatedDeliveryDateGraphQLAttributes
    ),
    query<RemoteVariantProductEstimatedDeliveryDate>(
      VariantProductEstimatedDeliveryDateGraphQLAttributes
    ),
  ];

  if (!baseData || !additionalData) {
    return null;
  }

  return assembleProduct(
    baseData,
    additionalData,
    relatedProductsData,
    clubTierQuotaData,
    enableAgeDeclarationData,
    variantProductClubTierQuota,
    variantEnableAgeDeclarationData,
    thirdPartyProductShowPriceData,
    instalmentData,
    variantInstalmentData,
    isPreOrderData,
    variantIsPreOrderData,
    estimatedDeliveryDateData,
    variantEstimatedDeliveryDateData
  );
}
