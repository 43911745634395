import React, {
  useCallback,
  useMemo,
  useContext,
  useState,
  useEffect,
} from "react";
import { Decimal } from "decimal.js";
import { IonIcon, IonLifeCycleContextInterface } from "@ionic/react";

import {
  getPathForProductDetailPage,
  RootTab,
  getPathForProductReviews,
  getPathForIFramePage,
} from "../../navigation/routes";

import {
  getRebateRate,
  getClubPointConversionRate,
} from "../../models/AppConfig";
import {
  getProductShareUrl,
  getRebatedClubPoint,
  isThirdPartyProduct,
  getConfiguredProductOfLowestPrice,
  getConfiguredProductOfHighestPrice,
  isProductOrItsVariantsHasClubProtect,
  isClubProtectEligible,
  ModelKeys,
  IsSoldOutFunction,
  ShouldProductShowPriceFunction,
  isRebatedClubPointEligible,
} from "../../models/product";
import { Product, ConfiguredProduct } from "../../models/ProductDetails";
import {
  ProductOverview,
  ConfiguredProductOverview,
} from "../../models/ProductOverview";
import { ProductReview } from "../../models/ProductReview";
import { StoreConfig } from "../../models/StoreConfig";
import { getResources } from "../../models/ResourcesRequestState";
import { resolveConfiguredProduct } from "../../models/ProductConfigurationDependency";
import {
  ProductSaleBundle,
  ProductSaleBundleItem,
} from "../../models/ProductSaleBundle";
import {
  useGetProductLabelsByProductId,
  useFetchProductLabelsByProductId,
} from "../../repository/ProductLabelRepository";
import { useAppConfig } from "../../repository/ConfigRepository";
import { useFetchPromotionBannersForProduct } from "../../repository/PromotionBannerRespository";
import { useCustomer } from "../../repository/AuthRepository";

import { ProfileSessionContext } from "../../contexts/ProfileSessionContext";

import { actionEvent } from "../../utils/GTM";
import { useIntl, LocalizedText } from "../../i18n/Localization";
import { socialShare, ShareChannel } from "../../utils/SocialShare";
import { shuffle } from "../../utils/array";
import { IndexMap } from "../../utils/type";
import { ProductDetailPageSession } from "../../utils/PerformanceRecordStore/sessions";
import { addPerformanceRecord } from "../../utils/PerformanceRecordStore";
import { useEffectOnce } from "../../hook/useEffectOnce";
import {
  usePresentAddToCartModal,
  usePresentAddToCartMultipleModal,
} from "../../hook/usePresentAddToCartModal";

import { OurNavContext, PresentationContext } from "../../our-navigation";

import { AddProductReviewModalContext } from "../AddProductReviewModalProvider";
import { LoginSignupModalContext } from "../LoginSignupModalProvider";
import { LocalizedAlertContext } from "../LocalizedAlertProvider";

import {
  FormState,
  PurchaseProductFormStateReducer,
  getFormInitialState,
} from "./PurchaseProductModal/PurchaseProductFormStateHook";
import {
  ProductData,
  ConfiguredProductData,
  ProductOverviewData,
  asProductOverview,
} from "./type";

import ShareModal from "../ShareModal/lazy";
import ProductBadges from "../ProductBadges";
import RebateCircle from "../RebateCircle";
import ProductOverviewHorizontalProductList from "../HorizontalProductList/ProductOverviewHorizontalProductList";
import ProductSaleBundleView from "../ProductSaleBundleView";
import TryClubpointsButton from "./TryClubpointsButton";
import ProductDeliveryMethodBlock from "./ProductDeliveryMethodBlock";
import ProductDescription from "./ProductDescription";
import ProductDescriptionMediaGallery from "./Gallery/ProductDescriptionMediaGallery.lazy";
import ProductDetailDescription from "./ProductDetailDescription";
import ProductGalleryModal from "./Gallery/ProductGalleryModal.lazy";
import ProductImageCarousel from "./ProductImageCarousel";
import ProductNameLabel from "./ProductNameLabel";
import ProductPriceView from "./ProductPriceView";
import ProductRatingScore from "./ProductRatingScore";
import ProductSKULabel from "./ProductSKULabel";
import ProductSpecification from "./ProductSpecification";
import ProductReviewComponent from "./ProductReview";
import PromotionBannersSection from "./PromotionBannersSection";
import SubscriptionInfo from "./SubscriptionInfo";
import ClubTierQuotaMessage from "./ClubTierQuotaMessage";
import ThirdPartyProductContent from "./ThirdPartyProductContent";
import ProductConfigurationOptionsPanel from "./ProductConfigurationOptionsPanel.lazy";
import ClubProtectBanner from "./ClubProtectBanner";
import InstalmentPriceView from "./InstalmentPriceView";

import Config from "../../Config";

import styles from "./styles.module.scss";

/* eslint-disable complexity */
const ProductDetailPageContent: React.FC<{
  ionLifeCycleContext: IonLifeCycleContextInterface;
  isLoggedIn: boolean;
  storeConfig: StoreConfig;
  currentTab: RootTab;
  purchaseProductFormStateReducer: PurchaseProductFormStateReducer;
  productOverview: ProductOverview;
  product: Product | null;
  configuredProduct: ConfiguredProduct | null;
  setConfiguredProduct: (configuredProduct: ConfiguredProduct | null) => void;
  scrollToTop: () => any;
  onClickHorizontalProductListLikeButton: (sku: string) => void;
  shareModalOpened: boolean;
  onRequestShareModalDismiss: () => void;
  productReviews: ProductReview[];
  bundle: ProductSaleBundle<Product> | null;
  relatedProductBundleProductIdMap: {
    [key in ModelKeys["id"]]: ProductSaleBundle<ModelKeys>;
  };
  isSoldOut: IsSoldOutFunction;
  shouldShowPrice: ShouldProductShowPriceFunction;
}> = React.memo(props => {
  const {
    ionLifeCycleContext,
    isLoggedIn,
    storeConfig,
    currentTab,
    purchaseProductFormStateReducer,
    productOverview: _productOverview,
    product: _product,
    configuredProduct,
    setConfiguredProduct,
    scrollToTop,
    onClickHorizontalProductListLikeButton,
    shareModalOpened,
    onRequestShareModalDismiss,
    productReviews,
    bundle,
    relatedProductBundleProductIdMap,
    shouldShowPrice,
  } = props;

  const { id, sku } = _productOverview;

  const [viewDidEnterOnce, setViewDidEnterOnce] = useState(false);

  const viewDidEnter = useCallback(() => {
    setViewDidEnterOnce(true);
  }, []);

  ionLifeCycleContext.onIonViewDidEnter(viewDidEnter);

  const { presentLoginModal } = useContext(LoginSignupModalContext);
  const { present } = useContext(PresentationContext);
  const { navigate } = useContext(OurNavContext);
  const appConfig = useAppConfig();
  const customer = useCustomer();

  const getProductLabelsByProductIds = useGetProductLabelsByProductId(
    ProductDetailPageSession(sku)
  );

  const productLabelsInMemory = useMemo(
    () => getProductLabelsByProductIds([id], "product"),
    [id, getProductLabelsByProductIds]
  );

  const [
    productLabelRequestState,
    fetchProductLabelByProductId,
  ] = useFetchProductLabelsByProductId();

  const [
    promotionBannersResourcesState,
    fetchPromotionBannerForProduct,
  ] = useFetchPromotionBannersForProduct();

  useEffect(() => {
    if (_product) {
      fetchPromotionBannerForProduct(_product).catch(() => {});
    }
  }, [_product, fetchPromotionBannerForProduct]);

  const { show: showAddProductReviewModal } = useContext(
    AddProductReviewModalContext
  );

  const [isProductGalleryOpen, setIsProductGalleryOpen] = useState(false);
  const onClickProductImageCarouselImage = useCallback(() => {
    setIsProductGalleryOpen(true);
  }, [setIsProductGalleryOpen]);
  const onProductGalleryRequestDismiss = useCallback(() => {
    setIsProductGalleryOpen(false);
  }, [setIsProductGalleryOpen]);

  const [
    isProductDescriptionMediaGalleryOpen,
    setIsProductDescriptionMediaGalleryOpen,
  ] = useState(false);
  const onClickProductDescriptionMedia = useCallback(() => {
    setIsProductDescriptionMediaGalleryOpen(true);
  }, []);
  const onProductDescriptionMediaGalleryRequestDismiss = useCallback(() => {
    setIsProductDescriptionMediaGalleryOpen(false);
  }, []);

  const productData = useMemo<ProductData>(() => {
    if (_product != null) {
      return ConfiguredProductData(configuredProduct || _product);
    }
    return ProductOverviewData(_productOverview);
  }, [_productOverview, _product, configuredProduct]);

  const {
    formState: configProductFormState,
    formStateDispatch: configProductFormStateDispatch,
  } = purchaseProductFormStateReducer;

  const onSubmitConfigForm = useCallback(
    (formState: FormState) => {
      configProductFormStateDispatch({
        type: "SetFormState",
        formState,
      });
      if (_product) {
        setConfiguredProduct(
          resolveConfiguredProduct(_product, formState.configurationOptionValue)
        );
      }
      scrollToTop();
    },
    [
      _product,
      configProductFormStateDispatch,
      scrollToTop,
      setConfiguredProduct,
    ]
  );

  const hrefForProduct = useCallback(
    (productOverview: ProductOverview) => {
      return getPathForProductDetailPage(currentTab, productOverview.sku);
    },
    [currentTab]
  );

  const presentAddToCartModal = usePresentAddToCartModal();
  const openPurchaseProductModal = useCallback(
    (product: ProductOverview) => {
      actionEvent("Product Details", "Click", "Add To Cart");
      presentAddToCartModal(product.sku, getFormInitialState());
    },
    [presentAddToCartModal]
  );

  const onClickLikeButton = useCallback(
    (productOverview: ProductOverview) => {
      actionEvent("Product Details", "Click", "Likelist");
      onClickHorizontalProductListLikeButton(productOverview.sku);
    },
    [onClickHorizontalProductListLikeButton]
  );

  const { translate } = useIntl();
  const { presentLocalizedAlert } = useContext(LocalizedAlertContext);
  const shareProduct = useCallback(
    async (shareChannel: ShareChannel) => {
      if (_product != null) {
        const link = getProductShareUrl(_product);
        const title = translate("share.product.title");
        const message = _product.name;
        const shareMessengerString = translate(
          "share.product.messenger.message",
          {
            PRODUCT_NAME: _product.name,
            PRODUCT_LINK: link,
          }
        );
        try {
          await socialShare(
            shareChannel,
            title,
            message,
            shareMessengerString,
            link
          );
          actionEvent("Product Details", "Click", shareChannel);
        } catch (e) {
          if (e !== "cancelled") {
            presentLocalizedAlert({
              headerId: "share.product.error",
              buttons: [{ textMessageID: "try_again" }],
            });
          }
        }
      }
    },
    [translate, _product, presentLocalizedAlert]
  );

  const productOverview_ = useMemo(() => asProductOverview(productData), [
    productData,
  ]);

  const numberOfCustomerReviews = useMemo(() => {
    if (_product) {
      return _product.reviewCount;
    }
    return null;
  }, [_product]);

  const configuredProduct_ = configuredProduct || _product;
  const productLabels = useMemo(() => {
    const productLabelsByProductId = getResources(productLabelRequestState);
    const productId = (configuredProduct || _productOverview).id;
    return productLabelsByProductId
      ? productLabelsByProductId[productId]
      : productLabelsInMemory[productId];
  }, [
    productLabelRequestState,
    configuredProduct,
    _productOverview,
    productLabelsInMemory,
  ]);

  useEffect(() => {
    if (!viewDidEnterOnce) {
      return;
    }
    if (configuredProduct_ != null) {
      fetchProductLabelByProductId([configuredProduct_.id], "product").catch(
        () => {}
      );
    }
  }, [viewDidEnterOnce, configuredProduct_, fetchProductLabelByProductId]);

  const handleAddReviewClick = useCallback(() => {
    showAddProductReviewModal(_productOverview.id);
  }, [showAddProductReviewModal, _productOverview]);

  const handleViewAllReviewsClick = useCallback(() => {
    present(getPathForProductReviews(_productOverview.sku));
  }, [present, _productOverview]);

  const handleLoginClick = useCallback(() => {
    presentLoginModal();
  }, [presentLoginModal]);

  const productOfDisplayPrice = useMemo<ProductOverview>(() => {
    if (configuredProduct) {
      return configuredProduct;
    }
    const productOverview = asProductOverview(productData);
    if (!appConfig) {
      return productOverview;
    }
    return (
      getConfiguredProductOfLowestPrice(
        productOverview,
        getClubPointConversionRate(appConfig)
      ) || productOverview
    );
  }, [configuredProduct, productData, appConfig]);

  const isNoPrice = useMemo(() => !shouldShowPrice(productOfDisplayPrice), [
    shouldShowPrice,
    productOfDisplayPrice,
  ]);

  const productOfMinValue = useMemo(
    () =>
      configuredProduct
        ? configuredProduct
        : appConfig
        ? getConfiguredProductOfLowestPrice<
            ConfiguredProductOverview,
            ProductOverview
          >(_productOverview, getClubPointConversionRate(appConfig)) ||
          _productOverview
        : _productOverview,
    [configuredProduct, _productOverview, appConfig]
  );

  const productOfMaxValue = useMemo(
    () =>
      configuredProduct
        ? configuredProduct
        : appConfig
        ? getConfiguredProductOfHighestPrice<
            ConfiguredProductOverview,
            ProductOverview
          >(_productOverview, getClubPointConversionRate(appConfig)) ||
          _productOverview
        : _productOverview,
    [configuredProduct, _productOverview, appConfig]
  );

  const rebatedClubPoint = useMemo(
    () => getRebatedClubPoint(productOfDisplayPrice),
    [productOfDisplayPrice]
  );

  const rebateEligible = useMemo(
    () => isRebatedClubPointEligible(rebatedClubPoint.toNumber()),
    [rebatedClubPoint]
  );

  const rebateAmount = useMemo<number | null>(() => {
    const rebateRate = getRebateRate(appConfig);
    if (!rebateRate) {
      return null;
    }
    return rebatedClubPoint
      .times(rebateRate)
      .times(new Decimal(100))
      .toNumber();
  }, [rebatedClubPoint, appConfig]);

  const rebateCircleData = useMemo<{
    state: "show";
    rebateAmount: number;
  } | null>(() => {
    // https://3.basecamp.com/3096882/buckets/12323301/messages/2788848052#__recording_2802722163
    if (rebateAmount != null && rebateEligible) {
      return {
        state: "show",
        rebateAmount,
      };
    }
    return null;
  }, [rebateAmount, rebateEligible]);

  const earnClubPointAmount = useMemo<number | null>(() => {
    if (!productOfDisplayPrice.priceRange || !appConfig) {
      return null;
    }
    const { earnClubPointsRate } = appConfig;
    if (!earnClubPointsRate) {
      return null;
    }
    const productPrice = new Decimal(
      productOfDisplayPrice.priceRange.minimumPrice.finalPrice.value
    );
    return rebatedClubPoint
      .times(productPrice)
      .dividedBy(
        earnClubPointsRate.currencyAmount.dividedBy(earnClubPointsRate.points)
      )
      .times(new Decimal(10))
      .floor()
      .dividedBy(new Decimal(10))
      .toNumber();
  }, [productOfDisplayPrice, rebatedClubPoint, appConfig]);

  const earnClubPointAmountInCurrency = useMemo<number | null>(() => {
    if (!earnClubPointAmount || !appConfig) {
      return null;
    }
    const {
      useClubPointsRate: { points, currencyAmount },
    } = appConfig;
    return new Decimal(earnClubPointAmount)
      .dividedBy(points.dividedBy(currencyAmount))
      .floor()
      .toNumber();
  }, [earnClubPointAmount, appConfig]);

  const enableClubProtection = useMemo(() => {
    if (configuredProduct) {
      const {
        enableClubProtection: _enableClubProtection,
        priceRange,
      } = configuredProduct;
      if (_enableClubProtection == null || priceRange == null) {
        return false;
      }
      return isClubProtectEligible(
        customer,
        _enableClubProtection,
        priceRange.minimumPrice.finalPrice.value
      );
    }
    if (_product) {
      return isProductOrItsVariantsHasClubProtect(customer, _product);
    }
    return false;
  }, [_product, configuredProduct, customer]);

  const handleBundleSaleProductClick = useCallback(
    (product: ModelKeys) => {
      navigate(getPathForProductDetailPage(currentTab, product.sku));
    },
    [currentTab, navigate]
  );

  const presentAddToCartMultipleModal = usePresentAddToCartMultipleModal();
  const handleBundleProductsAddToCart = useCallback(
    (
      mainProduct: ModelKeys,
      bundleDetails: ProductSaleBundleItem<ModelKeys>,
      itemsSelected: { [key in number]: boolean },
      skuFormStateMap: IndexMap<string, FormState>
    ) => {
      const skus: string[] = [];

      skus.push(mainProduct.sku);

      for (const item of bundleDetails.items) {
        const {
          product: { sku: _sku, id: _id },
        } = item;
        if (itemsSelected[_id]) {
          skus.push(_sku);
        }
      }

      const formStateMap = {
        ...skuFormStateMap,
        [mainProduct.sku]: configProductFormState,
      };

      presentAddToCartMultipleModal(skus, formStateMap);
    },
    [presentAddToCartMultipleModal, configProductFormState]
  );

  const relatedProducts = useMemo(() => {
    if (!_product || !_product.relatedProducts) {
      return [];
    }

    let products = _product.relatedProducts;
    if (
      Config.PRODUCT_DETAILS_RELATED_PRODUCTS_SHUFFLE_WHEN_COUNT_GREATER_THEN !=
        null &&
      _product.relatedProducts.length >=
        Config.PRODUCT_DETAILS_RELATED_PRODUCTS_SHUFFLE_WHEN_COUNT_GREATER_THEN
    ) {
      products = shuffle(products);
    }

    if (
      Config.PRODUCT_DETAILS_RELATED_PRODUCTS_MAX_COUNT != null &&
      _product.relatedProducts.length >=
        Config.PRODUCT_DETAILS_RELATED_PRODUCTS_MAX_COUNT
    ) {
      products = products.slice(
        0,
        Config.PRODUCT_DETAILS_RELATED_PRODUCTS_MAX_COUNT
      );
    }

    return products;
  }, [_product]);

  const profileSessionContext = useContext(ProfileSessionContext);

  useEffectOnce(() => {
    if (profileSessionContext) {
      addPerformanceRecord(
        profileSessionContext.rootProfileSession,
        "Contents (ProductDetailPage) Shown"
      );
    }
  });

  const handleClubProtectBannerClick = useMemo(
    () =>
      Config.SMART_PROTECTION_DETAILS_URL
        ? ((url: string) => () => {
            navigate(getPathForIFramePage(currentTab, url));
          })(Config.SMART_PROTECTION_DETAILS_URL)
        : undefined,
    [navigate, currentTab]
  );

  return (
    <>
      <ProductImageCarousel
        storeConfig={storeConfig}
        productData={productData}
        onClickImage={onClickProductImageCarouselImage}
        productLabels={productLabels}
      />
      <div className={styles.productNameAndBrandContainer}>
        <ProductNameLabel productData={productData} />
      </div>
      <ProductBadges
        containerClassName={styles.badgesContainer}
        productOverview={configuredProduct_ || productOverview_}
        showFreeShippingBadge={Config.ENABLE_SHOPPING_CART_NEW_LAYOUT_2912}
        hasBundle={(bundle && bundle.items && bundle.items.length > 0) || false}
      />
      {enableClubProtection ? (
        <div className={styles.clubProtectBannerContainer}>
          <ClubProtectBanner onClick={handleClubProtectBannerClick} />
        </div>
      ) : null}
      <div className={styles.skuContainer}>
        <ProductSKULabel productData={productData} />
      </div>
      {isThirdPartyProduct(productOverview_) ? (
        <div className={styles.thirdPartyContentContainer}>
          <ThirdPartyProductContent product={productOverview_} />
        </div>
      ) : (
        <>
          <div className={styles.productRatingScoreContainer}>
            <ProductRatingScore
              overallReviewScore={productOverview_.rating}
              size="normal"
            />
          </div>
          {numberOfCustomerReviews ? (
            <div
              className={styles.reviewCountAndFAQCountContainer}
              onClick={handleViewAllReviewsClick}
            >
              <span
                className={styles.productReviewsCountButton}
                onClick={handleViewAllReviewsClick}
              >
                <LocalizedText
                  messageID="page.product_detail.button.customer_reviews"
                  messageArgs={{ numberOfCustomerReviews }}
                />
              </span>
            </div>
          ) : null}
          {isNoPrice ? null : (
            <div className={styles.productPriceRow}>
              <div className={styles.productPriceContainer}>
                <ProductPriceView
                  productOfMinValue={productOfMinValue}
                  productOfMaxValue={productOfMaxValue}
                />
              </div>
              {rebateCircleData != null ? (
                <div className={styles.rebateCircleContainer}>
                  <RebateCircle
                    rebateAmount={rebateCircleData.rebateAmount}
                    className={styles.rebateCircle}
                  />
                </div>
              ) : null}
            </div>
          )}
          {configuredProduct_ && configuredProduct_.instalmentAmountPerMonth ? (
            <div className={styles.instalmentContainer}>
              <InstalmentPriceView
                instalmentAmountPerMonth={
                  configuredProduct_.instalmentAmountPerMonth
                }
              />
            </div>
          ) : null}
          {!isNoPrice && (
            <TryClubpointsButton
              className={styles.tryClubpointsButton}
              productOverview={productOfDisplayPrice}
            />
          )}
          {rebateAmount &&
          earnClubPointAmount &&
          !isNoPrice &&
          earnClubPointAmountInCurrency != null ? (
            !rebateEligible ? (
              <p className={styles.productClubPoints}>
                <LocalizedText
                  messageID="page.product_detail.club_points_rebate"
                  messageArgs={{
                    earnClubPointAmount,
                    iconClassName: styles.productClubPointsIcon,
                  }}
                  components={{ IonIcon }}
                />
              </p>
            ) : earnClubPointAmountInCurrency === 0 ? (
              <p className={styles.productClubPoints}>
                <LocalizedText
                  messageID="page.product_detail.club_points_rebate_with_rebate_amount"
                  messageArgs={{
                    rebateAmount,
                    earnClubPointAmount,
                    iconClassName: styles.productClubPointsIcon,
                  }}
                  components={{ IonIcon }}
                />
              </p>
            ) : (
              <p className={styles.productClubPoints}>
                <LocalizedText
                  messageID="page.product_detail.club_points_rebate_with_rebate_amount_currency"
                  messageArgs={{
                    rebateAmount,
                    earnClubPointAmount,
                    earnClubPointAmountInCurrency,
                    iconClassName: styles.productClubPointsIcon,
                  }}
                  components={{ IonIcon }}
                />
              </p>
            )
          ) : null}
          {configuredProduct_ != null ? (
            <div className={styles.clubTierQuotaMessage}>
              <ClubTierQuotaMessage product={configuredProduct_} />
            </div>
          ) : null}
        </>
      )}
      {configuredProduct_ && (
        <ProductDescription configuredProduct={configuredProduct_} />
      )}
      {_product && (
        <ProductConfigurationOptionsPanel
          product={_product}
          formState={configProductFormState}
          onSubmitConfigForm={onSubmitConfigForm}
        />
      )}
      <PromotionBannersSection
        promotionBannersResourcesState={promotionBannersResourcesState}
      />
      <div className={styles.contentSeparator} />
      {Config.ENABLE_SUBSCRIPTION &&
      _product &&
      _product.recurringConfiguration &&
      _product.recurringConfiguration.isRecurringEnable ? (
        <div className={styles.subscriptionInfo}>
          <h2 className={styles.title}>
            <LocalizedText messageID="page.product_detail.subscription_info.title" />
          </h2>
          <SubscriptionInfo
            recurringConfiguration={_product.recurringConfiguration}
          />
        </div>
      ) : null}
      {_product && (
        <ProductDeliveryMethodBlock
          className={styles.productDeliveryMethod}
          product={_product}
        />
      )}
      {Config.ENABLE_BUNDLE_SALE &&
      configuredProduct_ &&
      bundle &&
      bundle.items ? (
        <>
          {bundle.items.map((b, i) => (
            <div key={i} className={styles.productSaleBundleContainer}>
              <ProductSaleBundleView
                mainProduct={configuredProduct_}
                bundleDetails={b}
                onClickProduct={handleBundleSaleProductClick}
                onAddToCartClick={handleBundleProductsAddToCart}
              />
            </div>
          ))}
        </>
      ) : null}
      {_product && (
        <ShareModal
          onClickShare={shareProduct}
          isOpen={shareModalOpened}
          onRequestDismiss={onRequestShareModalDismiss}
        />
      )}
      {relatedProducts.length > 0 ? (
        <ProductOverviewHorizontalProductList
          title={translate("page.product_detail.similar_product.title")}
          productOverviews={relatedProducts}
          bundleByProductId={relatedProductBundleProductIdMap}
          hrefForProduct={hrefForProduct}
          onAddToCart={openPurchaseProductModal}
          onClickLikeButton={onClickLikeButton}
        />
      ) : null}
      {configuredProduct_ && (
        <>
          <ProductDetailDescription
            configuredProduct={configuredProduct_}
            onClickMedia={onClickProductDescriptionMedia}
          />
          <ProductDescriptionMediaGallery
            configuredProduct={configuredProduct_}
            isOpen={isProductDescriptionMediaGalleryOpen}
            onRequestDismiss={onProductDescriptionMediaGalleryRequestDismiss}
          />
          <ProductSpecification configuredProduct={configuredProduct_} />
          <ProductReviewComponent
            productReviews={productReviews}
            isLoggedIn={isLoggedIn}
            onAddReviewClick={handleAddReviewClick}
            onViewAllReviewsClick={handleViewAllReviewsClick}
            onLoginClick={handleLoginClick}
          />
          <ProductGalleryModal
            storeConfig={storeConfig}
            configuredProduct={configuredProduct_}
            isOpen={isProductGalleryOpen}
            onRequestDismiss={onProductGalleryRequestDismiss}
          />
        </>
      )}
      {_product ? <FullProductShown product={_product} /> : null}
    </>
  );
});
/* eslint-enable complexity */

export default ProductDetailPageContent;

interface FullProductShownProps {
  product: Product;
}

const FullProductShown: React.FC<FullProductShownProps> = () => {
  const profileSessionContext = useContext(ProfileSessionContext);

  useEffectOnce(() => {
    if (profileSessionContext) {
      addPerformanceRecord(
        profileSessionContext.rootProfileSession,
        "Contents (ProductDetailPage) Full Shown"
      );
    }
  });

  return null;
};
