import React, { useEffect, useCallback, useContext, useRef } from "react";
import PurchaseProductModal from "./ProductDetailPage/PurchaseProductModal/lazy";
import {
  isCampaignProductSoldOut,
  isClubProtectEligible,
  isNormalProductSoldOut,
} from "../models/product";
import { resolveConfiguredProduct } from "../models/ProductConfigurationDependency";
import { Product, ConfiguredProduct } from "../models/ProductDetails";
import {
  FormState,
  getFormInitialState,
} from "./ProductDetailPage/PurchaseProductModal/PurchaseProductFormStateHook";
import {
  Cart,
  ReeService,
  SubscribeEnum,
  CustomizableOptionInput,
  getMinClubPointUsed,
  ClubProtectionInput,
} from "../models/cart";
import AddToCartSuccessModal from "./ProductDetailPage/AddToCartSuccessModal.lazy";
import {
  useFetchCampaignProductDetailBySKU,
  useFetchProductDetailBySKU,
} from "../repository/ProductRepository";
import {
  useAddProductToCart,
  useSetClubPointOnCart,
} from "../repository/CartRepository";
import { useCustomer } from "../repository/AuthRepository";
import LoadingModalProvider, {
  LoadingModalContext,
} from "./LoadingModalProvider";
import { LocalizedAlertContext } from "./LocalizedAlertProvider";
import { withProviders } from "./Provider";
import { ShoppingCartItemCountContext } from "./ShoppingCartItemCountProvider";
import { useModalState } from "../hook/modal";
import { actionEvent, pageView } from "../utils/GTM";
import { profiledAsyncActionFn } from "../utils/performance";
import { CheckoutSession } from "../utils/PerformanceRecordStore/sessions";
import { addPerformanceRecord } from "../utils/PerformanceRecordStore";
import { IndexMap } from "../utils/type";
import { parseGraphQLError } from "../api/GraphQL";
import { MessageID } from "../i18n/translations/type";
import { OrderItem } from "../models/Order";
import { canCustomerSetClubPointOnCart } from "../models/Customer";

import Config from "../Config";

export interface AddToCartParameters {
  product: Product;
  configuredProduct: ConfiguredProduct | null;
  quantity: number;
  subscribe: SubscribeEnum;
  customizableOptions?: CustomizableOptionInput[];
  campaignId?: number;
  options?: {
    ree?: {
      agreement?: boolean;
      service?: ReeService;
    };
    clubprotection?: ClubProtectionInput;
  };
}

interface AddToCartProductInfoSku {
  type: "sku";
  sku: string;
  campaignId?: number;
}
interface AddToCartProductInfoOrderItem {
  type: "orderItem";
  orderItem: OrderItem;
}
type AddToCartProductInfo =
  | AddToCartProductInfoSku
  | AddToCartProductInfoOrderItem;

function makeSkuType(
  sku: string,
  campaignId?: number
): AddToCartProductInfoSku {
  return {
    type: "sku",
    sku,
    campaignId,
  };
}

function makeOrderItemType(
  orderItem: OrderItem
): AddToCartProductInfoOrderItem {
  return {
    type: "orderItem",
    orderItem,
  };
}

interface AddToCartResultPending {
  type: "pending";
  parameters: AddToCartParameters;
}
interface AddToCartResultSuccess {
  type: "success";
  product: Product;
  configuredProduct: ConfiguredProduct | null;
  cart: Cart;
}
type AddToCartResultFailed =
  | { type: "failed"; reason: "PRODUCT_NOT_FOUND"; sku: string }
  | {
      type: "failed";
      reason: "OUT_OF_STOCK";
      product: Product;
      configuredProduct: ConfiguredProduct | null;
    }
  | {
      type: "failed";
      reason: "ERROR";
      sku: string;
      product?: Product;
      configuredProduct?: ConfiguredProduct | null;
      error: any;
    };
type AddToCartResult =
  | AddToCartResultPending
  | AddToCartResultSuccess
  | AddToCartResultFailed;
export type AddToCartFinalResult = Exclude<
  AddToCartResult,
  { type: "pending" }
>;
function isSuccessResult(
  result: AddToCartResult
): result is AddToCartResultSuccess {
  return result.type === "success";
}
export function isOutOfStockResult(result: AddToCartResult): boolean {
  return result.type === "failed" && result.reason === "OUT_OF_STOCK";
}

function isSoldOut(message: string) {
  const normalized = message.toLowerCase();
  const matched = /售罄|sold out/.exec(normalized);
  return !!matched;
}

enum AddToCartFailedReason {
  CANCELLED = "CANCELLED",
  PRODUCT_NOT_FOUND = "PRODUCT_NOT_FOUND",
}

interface AddToCartModalContext {
  presentAddToCartModal: (
    productSku: string,
    formInitialState: FormState,
    options?: {
      campaignId?: number;
    }
  ) => Promise<AddToCartFinalResult | undefined>;
  presentAddToCartModalWithOrderItem: (
    orderItem: OrderItem
  ) => Promise<AddToCartFinalResult | undefined>;
  presentAddToCartMultipleModal: (
    skus: string[],
    skuFormStateMap?: IndexMap<string, FormState>
  ) => Promise<AddToCartFinalResult[]>;
}

interface AddToCartSuccessModalProps {
  count: number;
  cart: Cart;
}

export const AddToCartModalContext = React.createContext<AddToCartModalContext>(
  null as any
);

const AddToCartModalProvider_: React.FC = props => {
  const [
    isPurchaseProductModalOpen,
    presentPurchaseProductionModal,
    dismissPurchaseProductModal,
  ] = useModalState();

  const [
    isSuccessModalOpen,
    presentSuccessModal,
    dismissSuccessModal,
  ] = useModalState();

  const [product, setProduct] = React.useState<Product | null>(null);
  const [formState, setFormState] = React.useState<FormState | null>(null);
  const campaignIdRef = React.useRef<number | undefined>(undefined);

  const presentPurchaseProductModalAddToCartParametersRef = useRef<AddToCartParameters | null>(
    null
  );
  const presentPurchaseProductModalResolveRef = useRef<
    ((addToCartParameters: AddToCartParameters) => void) | null
  >(null);
  const presentPurchaseProductModalRejectRef = useRef<
    ((reason?: Error) => void) | null
  >(null);

  const [
    addToCartSuccessModalState,
    setAddToCartSuccessModalState,
  ] = React.useState<AddToCartSuccessModalProps | null>(null);

  const [, startRequestingNormalProductWithSku] = useFetchProductDetailBySKU();
  const [
    ,
    startRequestingCampaignProductWithSku,
  ] = useFetchCampaignProductDetailBySKU();

  const {
    count: shoppingCartItemCount,
    setCount: setShoppingCartItemCount,
  } = useContext(ShoppingCartItemCountContext);

  const { presentLocalizedAlert } = useContext(LocalizedAlertContext);

  const loadingModalContext = React.useContext(LoadingModalContext);

  const prevCartItemCountRef = useRef(shoppingCartItemCount);

  useEffect(() => {
    prevCartItemCountRef.current = shoppingCartItemCount;
  }, [shoppingCartItemCount]);

  const customer = useCustomer();
  const addProductToCart = useAddProductToCart();
  const setClubPointOnCart = useSetClubPointOnCart();

  const handlePurchaseProductModalAddToCartParameters = useCallback(
    async (
      _product: Product,
      configuredProduct: ConfiguredProduct | null,
      quantity: number,
      subscribe: SubscribeEnum,
      customizableOptions?: CustomizableOptionInput[],
      options?: {
        ree?: {
          agreement?: boolean;
          service?: ReeService;
        };
        clubprotection?: ClubProtectionInput;
      }
    ) => {
      presentPurchaseProductModalAddToCartParametersRef.current = {
        product: _product,
        configuredProduct,
        quantity,
        subscribe,
        campaignId: campaignIdRef.current,
        customizableOptions,
        options,
      };
      loadingModalContext.show();
      dismissPurchaseProductModal();
    },
    [loadingModalContext, dismissPurchaseProductModal]
  );

  const fetchProduct = useCallback(
    async (sku: string, campaignId?: number) => {
      return campaignId
        ? startRequestingCampaignProductWithSku(campaignId, sku)
        : startRequestingNormalProductWithSku(sku);
    },
    [startRequestingNormalProductWithSku, startRequestingCampaignProductWithSku]
  );

  const getAddProductToCartParameters = useCallback(
    async (
      productData: AddToCartProductInfo,
      skuFormStateMap?: IndexMap<string, FormState>
    ): Promise<AddToCartParameters> => {
      const sku =
        productData.type === "sku"
          ? productData.sku
          : productData.orderItem.sku;
      const campaignId =
        productData.type === "sku" ? productData.campaignId : undefined;
      let productResult: Product | null = null;
      try {
        loadingModalContext.show();
        productResult = await profiledAsyncActionFn(
          CheckoutSession(),
          `- Add To Cart fetchProduct API (sku=${sku})`,
          fetchProduct
        )(sku, campaignId);
      } finally {
        loadingModalContext.hide();
      }
      if (productResult == null) {
        throw new Error(AddToCartFailedReason.PRODUCT_NOT_FOUND);
      }
      const _formState =
        productData.type === "sku"
          ? (skuFormStateMap
              ? skuFormStateMap[productData.sku]
              : getFormInitialState()) || getFormInitialState()
          : getFormInitialStateFromOrderItem(
              productData.orderItem,
              productResult
            );
      const {
        enableDisclaimer,
        enableAgeDeclaration,
        recurringConfiguration,
        enableClubProtection,
        priceRange,
      } = productResult;
      const configuredProduct = resolveConfiguredProduct(
        productResult,
        _formState.configurationOptionValue
      );
      const shouldShowPurchaseForm =
        (productResult.type === "configurable" && !configuredProduct) ||
        enableAgeDeclaration ||
        enableDisclaimer ||
        (enableClubProtection != null && priceRange != null
          ? isClubProtectEligible(
              customer,
              enableClubProtection,
              priceRange.minimumPrice.finalPrice.value
            )
          : false) ||
        (productResult.customizableOptions &&
          productResult.customizableOptions.length > 0) ||
        (Config.ENABLE_SUBSCRIPTION &&
          recurringConfiguration &&
          recurringConfiguration.isRecurringEnable);
      if (!shouldShowPurchaseForm) {
        return {
          product: productResult,
          configuredProduct: configuredProduct,
          quantity: _formState.quantity,
          campaignId,
          subscribe: SubscribeEnum.notSubscribe,
        };
      }
      setProduct(productResult);
      const addToCartParameters = await new Promise<AddToCartParameters>(
        (resolve, reject) => {
          setFormState(_formState);
          campaignIdRef.current =
            productData.type === "sku" ? productData.campaignId : undefined;
          pageView({ page: "Add To Cart" });
          presentPurchaseProductionModal();
          presentPurchaseProductModalAddToCartParametersRef.current = null;
          presentPurchaseProductModalResolveRef.current = resolve;
          presentPurchaseProductModalRejectRef.current = reject;
        }
      );
      presentPurchaseProductModalAddToCartParametersRef.current = null;
      presentPurchaseProductModalResolveRef.current = null;
      presentPurchaseProductModalRejectRef.current = null;
      return addToCartParameters;
    },
    [
      customer,
      loadingModalContext,
      fetchProduct,
      presentPurchaseProductionModal,
    ]
  );

  const addProductDataListToCart = React.useCallback(
    async (
      productDataList: AddToCartProductInfo[],
      skuFormStateMap?: IndexMap<string, FormState>
    ): Promise<AddToCartFinalResult[]> => {
      const pendingAddToCartResults: AddToCartResult[] = [];
      for (let i = 0; i < productDataList.length; i++) {
        const productData = productDataList[i];
        const sku =
          productData.type === "sku"
            ? productData.sku
            : productData.orderItem.sku;
        try {
          // eslint-disable-next-line no-await-in-loop
          pendingAddToCartResults.push({
            type: "pending",
            parameters: await getAddProductToCartParameters(
              productData,
              skuFormStateMap
            ),
          });
        } catch (e) {
          if (e instanceof Error) {
            if (e.message === AddToCartFailedReason.CANCELLED) {
              // Terminate add to cart process
              return [];
            }
            if (e.message === AddToCartFailedReason.PRODUCT_NOT_FOUND) {
              pendingAddToCartResults.push({
                type: "failed",
                reason: "PRODUCT_NOT_FOUND",
                sku,
              });
              continue;
            }
            pendingAddToCartResults.push({
              type: "failed",
              reason: "ERROR",
              sku,
              error: e,
            });
          }
        }
      }

      loadingModalContext.show();
      const finalAddToCartResults: AddToCartFinalResult[] = [];
      for (const addToCartResult of pendingAddToCartResults) {
        if (addToCartResult.type !== "pending") {
          finalAddToCartResults.push(addToCartResult);
          continue;
        }
        try {
          // eslint-disable-next-line no-await-in-loop
          finalAddToCartResults.push({
            type: "success",
            product: addToCartResult.parameters.product,
            configuredProduct: addToCartResult.parameters.configuredProduct,
            cart: await profiledAsyncActionFn(
              CheckoutSession(),
              `- Add To Cart addProductToCart API (sku=${addToCartResult.parameters.product.sku})`,
              addProductToCart
            )(
              addToCartResult.parameters.product,
              addToCartResult.parameters.configuredProduct,
              addToCartResult.parameters.quantity,
              addToCartResult.parameters.subscribe,
              addToCartResult.parameters.campaignId,
              addToCartResult.parameters.customizableOptions,
              addToCartResult.parameters.options
            ),
          });
        } catch (e) {
          const errorMessage =
            parseGraphQLError(e) || (e instanceof Error && e.message);
          if (errorMessage && isSoldOut(errorMessage)) {
            finalAddToCartResults.push({
              type: "failed",
              reason: "OUT_OF_STOCK",
              product: addToCartResult.parameters.product,
              configuredProduct: addToCartResult.parameters.configuredProduct,
            });
          } else {
            finalAddToCartResults.push({
              type: "failed",
              reason: "ERROR",
              sku: addToCartResult.parameters.product.sku,
              product: addToCartResult.parameters.product,
              configuredProduct: addToCartResult.parameters.configuredProduct,
              error: e,
            });
          }
        }
      }
      loadingModalContext.hide();

      const lastSuccessResult = [...finalAddToCartResults]
        .reverse()
        .find(isSuccessResult);

      if (lastSuccessResult != null) {
        let { cart } = lastSuccessResult;

        /**
         * Reset club point on cart after changing cart item
         */
        if (
          customer &&
          canCustomerSetClubPointOnCart(customer, getMinClubPointUsed(cart))
        ) {
          loadingModalContext.show();
          const originalCart = cart;
          cart = await setClubPointOnCart(0).catch(() => originalCart);
          loadingModalContext.hide();
        }

        const prevCartItemCount = prevCartItemCountRef.current;
        let count = 0;
        for (const item of cart.items) {
          count += item.quantity;
        }
        setShoppingCartItemCount(count);
        setAddToCartSuccessModalState({
          count: count - prevCartItemCount,
          cart,
        });
        presentSuccessModal();
      }

      return finalAddToCartResults;
    },
    [
      customer,
      addProductToCart,
      getAddProductToCartParameters,
      presentSuccessModal,
      setShoppingCartItemCount,
      setClubPointOnCart,
      loadingModalContext,
    ]
  );

  const handleAddToCartError = useCallback(
    (r: AddToCartResultFailed) => {
      if (r.reason === "ERROR") {
        const e = r.error;
        if (e instanceof Error) {
          const errorMessage = parseGraphQLError(e) || e.message;
          const displayMessageID = errorMessage
            ? getAddToCartErrorMessageID(errorMessage)
            : null;
          presentLocalizedAlert({
            headerId: "alert.error.title",
            messageId: displayMessageID
              ? displayMessageID
              : errorMessage
              ? undefined
              : "alert.error.message",
            message: displayMessageID
              ? undefined
              : errorMessage
              ? errorMessage
              : undefined,
            buttons: [
              {
                textMessageID: "alert.button.back",
              },
            ],
          });
        }
      } else if (r.reason === "PRODUCT_NOT_FOUND") {
        presentLocalizedAlert({
          headerId: "alert.error.title",
          messageId: "page.product_detail.alert.product_not_found.message",
          buttons: [
            {
              textMessageID: "alert.button.back",
            },
          ],
        });
      } else if (r.reason === "OUT_OF_STOCK") {
        presentLocalizedAlert({
          headerId: "alert.error.title",
          messageId: "add_to_cart.error.sold_out",
          buttons: [
            {
              textMessageID: "alert.button.back",
            },
          ],
        });
      }
    },
    [presentLocalizedAlert]
  );

  const presentAddToCartModal = useCallback(
    async (
      productSku: string,
      formInitialState: FormState,
      options?: {
        campaignId?: number;
      }
    ) => {
      addPerformanceRecord(
        CheckoutSession(),
        `Add To Cart (sku=${productSku})`
      );
      actionEvent("Add To Cart", "Click", "Add To Cart");
      const formStateMap = { [productSku]: formInitialState };
      const result = await addProductDataListToCart(
        [makeSkuType(productSku, options ? options.campaignId : undefined)],
        formStateMap
      );
      if (result.length > 0) {
        if (result[0].type === "failed") {
          handleAddToCartError(result[0]);
        }
        return result[0];
      }
      return undefined;
    },
    [addProductDataListToCart, handleAddToCartError]
  );

  const presentAddToCartModalWithOrderItem = React.useCallback(
    async (item: OrderItem): Promise<AddToCartFinalResult | undefined> => {
      const result = await addProductDataListToCart([makeOrderItemType(item)]);
      if (result.length > 0) {
        if (result[0].type === "failed") {
          handleAddToCartError(result[0]);
        }
        return result[0];
      }
      return undefined;
    },
    [addProductDataListToCart, handleAddToCartError]
  );

  const presentAddToCartMultipleModal = React.useCallback(
    (
      skus: string[],
      skuFormStateMap?: IndexMap<string, FormState>
    ): Promise<AddToCartFinalResult[]> => {
      return addProductDataListToCart(
        skus.map(sku => makeSkuType(sku)),
        skuFormStateMap
      );
    },
    [addProductDataListToCart]
  );

  const onPurchaseProductModalRequestDismiss = useCallback(() => {
    if (
      presentPurchaseProductModalAddToCartParametersRef.current &&
      presentPurchaseProductModalResolveRef.current
    ) {
      presentPurchaseProductModalResolveRef.current(
        presentPurchaseProductModalAddToCartParametersRef.current
      );
    } else if (presentPurchaseProductModalRejectRef.current) {
      presentPurchaseProductModalRejectRef.current(
        new Error(AddToCartFailedReason.CANCELLED)
      );
    }
  }, []);

  const handlePurchaseProductModalCloseClick = useCallback(() => {
    dismissPurchaseProductModal();
  }, [dismissPurchaseProductModal]);

  const contextValue = React.useMemo(
    () => ({
      presentAddToCartModal,
      presentAddToCartModalWithOrderItem,
      presentAddToCartMultipleModal,
    }),
    [
      presentAddToCartModal,
      presentAddToCartModalWithOrderItem,
      presentAddToCartMultipleModal,
    ]
  );

  const isSoldOutFunction = campaignIdRef.current
    ? isCampaignProductSoldOut
    : isNormalProductSoldOut;

  return (
    <>
      <AddToCartModalContext.Provider value={contextValue}>
        {props.children}
      </AddToCartModalContext.Provider>
      {product != null && formState != null && (
        <PurchaseProductModal
          product={product}
          formInitialState={formState}
          isOpen={isPurchaseProductModalOpen}
          onRequestDismiss={onPurchaseProductModalRequestDismiss}
          onCloseClick={handlePurchaseProductModalCloseClick}
          onProductAddToCart={handlePurchaseProductModalAddToCartParameters}
          isSoldOutFunction={isSoldOutFunction}
        />
      )}
      {addToCartSuccessModalState != null && (
        <AddToCartSuccessModal
          itemsAddedCount={addToCartSuccessModalState.count}
          cart={addToCartSuccessModalState.cart}
          isOpen={isSuccessModalOpen}
          onRequestDismiss={dismissSuccessModal}
        />
      )}
    </>
  );
};

function getFormInitialStateFromOrderItem(
  item: OrderItem,
  product: Product
): FormState {
  const formState = getFormInitialState();

  if (product.configurableOptions != null) {
    // find the option and option value in product from
    // the order item attribute info option value
    const options = product.configurableOptions;
    (item.attributesInfo || []).forEach(info => {
      for (let i = 0; i < options.length; i++) {
        const opt = options[i];
        for (let j = 0; j < opt.values.length; j++) {
          const value = opt.values[j];
          if (value.value === Number(info.optionValue)) {
            formState.configurationOptionValue[opt.id] = value.value;
          }
        }
      }
    });
  }

  return formState;
}

export const AddToCartModalProvider = withProviders(
  AddToCartModalProvider_,
  LoadingModalProvider
);

function getAddToCartErrorMessageID(errorMessage: string): MessageID | null {
  // Case 1
  const regex = new RegExp(
    "Could not add the product with SKU (.+) to the shopping cart: (.+)"
  );
  const matched = regex.exec(errorMessage);
  if (matched) {
    if (matched[2] === "此產品已缺貨。") {
      return "add_to_cart.error.sold_out";
    }
  }
  return null;
}
