import numeral from "numeral";
import Config from "../Config";
import { Order, OrderItem } from "../models/Order";
import { Override } from "./type";

export function gtm(gtmId: string) {
  const str =
    "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); })(window, document, 'script', 'dataLayer', '" +
    gtmId +
    "');";
  const script = document.createElement("script");
  script.innerHTML = str;
  document.head.insertBefore(script, document.head.childNodes[0]);
}

export function setUserID(userId: number | undefined) {
  console.info("GTM", "setUserID", userId);
  if (window.dataLayer) {
    window.dataLayer.push({
      userId,
    });
  }
}

interface GTMProductPageViewParams {
  page: "Product Details";
  productName: string;
  productUrl: string;
  productSku: string;
}

interface GTMCategoryPageViewParams {
  page: "Subcategory";
  categoryName: string;
}

interface GTMRedemptionPageViewParams {
  page: "Redemption";
  categoryName: string;
}

interface GTMArticlePageViewParams {
  page: "Article Page";
  articleTitle: string;
}

type DefinedGTMPageViewParams =
  | GTMProductPageViewParams
  | GTMCategoryPageViewParams
  | GTMRedemptionPageViewParams
  | GTMArticlePageViewParams;

type DefinedPages = DefinedGTMPageViewParams["page"];

type OtherGTMPageViewParams<T extends string> = T extends DefinedPages
  ? never
  : { page: T };

type GTMPageViewParams<T extends string> =
  | DefinedGTMPageViewParams
  | OtherGTMPageViewParams<T>;

type GTMPageViewParamsKeys = Exclude<
  keyof (GTMProductPageViewParams &
    GTMCategoryPageViewParams &
    GTMRedemptionPageViewParams &
    GTMArticlePageViewParams),
  "page"
>;

type ResetGTMPageViewParams = { [key in GTMPageViewParamsKeys]: undefined };

const resetGTMPageViewParams: ResetGTMPageViewParams = {
  productName: undefined,
  productUrl: undefined,
  productSku: undefined,
  categoryName: undefined,
  articleTitle: undefined,
};

export function pageView<T extends string>({
  page,
  ...params
}: GTMPageViewParams<T>) {
  console.info("GTM", "pageView", page, params);
  if (window.dataLayer) {
    window.dataLayer.push({
      event: "pageView",
      page,
      ...resetGTMPageViewParams,
      ...params,
    });
  }
}

export function actionEvent(
  category: string,
  action: string,
  actionTarget: string
) {
  console.info("GTM", "actionEvent", { category, action, actionTarget });
  if (window.dataLayer) {
    window.dataLayer.push({
      event: "actionEvent",
      category,
      action,
      actionTarget,
    });
  }
}

export function purchaseEvent(
  order: Override<
    Pick<
      Order,
      | "incrementID"
      | "items"
      | "shippingAmount"
      | "currencyCode"
      | "subtotal"
      | "discountAmount"
      | "discountDescription"
    >,
    {
      items: Pick<
        OrderItem,
        "subtotal" | "qtyOrdered" | "name" | "sku" | "simpleName" | "simpleSku"
      >[];
    }
  >
) {
  const {
    incrementID,
    items,
    shippingAmount,
    currencyCode,
    subtotal,
    discountAmount,
    discountDescription,
  } = order;

  const revenue =
    subtotal - (discountAmount < 0 ? -discountAmount : discountAmount);

  const obj = {
    ecommerce: {
      currencyCode,
      purchase: {
        actionField: {
          id: incrementID,
          revenue,
          tax: "0.0000",
          shipping: numeral(shippingAmount).format("0.0000"),
          coupon: discountDescription || "",
        },
        products: items.map(item => {
          return {
            id: item.simpleSku || item.sku,
            name: item.simpleName || item.name,
            price: numeral(item.subtotal / item.qtyOrdered).format("0.0000"),
            quantity: numeral(item.qtyOrdered).format("0.0000"),
          };
        }),
      },
    },
  };

  console.info("GTM", "purchaseEvent", obj);

  if (window.dataLayer) {
    window.dataLayer.push({
      event: "purchase",
      ...obj,
    });
  }
}

export function pairUpAcctClickEvent() {
  console.info("GTM", "pairUpAcctClickEvent");
  if (window.dataLayer) {
    window.dataLayer.push({
      event: "app_pair_up_acct_click",
    });
  }
}

export function pairUpAcctSuccessEvent() {
  console.info("GTM", "pairUpAcctSuccessEvent");
  if (window.dataLayer) {
    window.dataLayer.push({
      event: "app_pair_up_acct_success",
    });
  }
}

export function singleSignOnImpressionEvent() {
  console.info("GTM", "singleSignOnImpressionEvent");
  if (window.dataLayer) {
    window.dataLayer.push({
      event: "single_sign_on_impression",
    });
  }
}

export function theClubLoginClickEvent() {
  console.info("GTM", "theClubLoginClickEvent");
  if (window.dataLayer) {
    window.dataLayer.push({
      event: "the_club_login_click",
    });
  }
}

export function clubShoppingLoginClickEvent() {
  console.info("GTM", "clubShoppingLoginClickEvent");
  if (window.dataLayer) {
    window.dataLayer.push({
      event: "club_shopping_login_click",
    });
  }
}

export function clubAcctConsentImpressionEvent(
  method: LoginSignUpMethod,
  optInStatus: OptInStatus
) {
  console.info("GTM", "clubAcctConsentImpressionEvent", { method });
  if (window.dataLayer) {
    window.dataLayer.push({
      event: "club_acct_consent_impression",
      login_sign_up_method: method,
      opt_in_status: optInStatus,
    });
  }
}

export function clubAcctConsentClickEvent(
  method: LoginSignUpMethod,
  optInStatus: OptInStatus
) {
  console.info("GTM", "clubAcctConsentClick", { method, optInStatus });
  if (window.dataLayer) {
    window.dataLayer.push({
      event: "club_acct_consent_click",
      login_sign_up_method: method,
      opt_in_status: optInStatus,
    });
  }
}

export function pairUpAnotherAcctClubClickEvent(
  method: LoginSignUpMethod,
  optInStatus: OptInStatus
) {
  console.info("GTM", "pairUpAnotherAcctClubClick", { method, optInStatus });
  if (window.dataLayer) {
    window.dataLayer.push({
      event: "pair_up_another_acct_club_click",
      login_sign_up_method: method,
      opt_in_status: optInStatus,
    });
  }
}

export function pairedAcctConsentImpressionEvent(
  method: LoginSignUpMethod,
  optInStatus: OptInStatus
) {
  console.info("GTM", "pairedAcctConsentImpression", { method });
  if (window.dataLayer) {
    window.dataLayer.push({
      event: "paired_acct_consent_impression",
      login_sign_up_method: method,
      opt_in_status: optInStatus,
    });
  }
}

export function pairedAcctConsentClickEvent(
  method: LoginSignUpMethod,
  optInStatus: OptInStatus
) {
  console.info("GTM", "pairedAcctConsentClick", { method, optInStatus });
  if (window.dataLayer) {
    window.dataLayer.push({
      event: "paired_acct_consent_click",
      login_sign_up_method: method,
      opt_in_status: optInStatus,
    });
  }
}

export function pairUpAnotherAcctPairedEvent(
  method: LoginSignUpMethod,
  optInStatus: OptInStatus
) {
  console.info("GTM", "pairUpAnotherAcctPaired", { method, optInStatus });
  if (window.dataLayer) {
    window.dataLayer.push({
      event: "pair_up_another_acct_paired",
      login_sign_up_method: method,
      opt_in_status: optInStatus,
    });
  }
}

export function unpairAcctConsentImpressionEvent(method: LoginSignUpMethod) {
  console.info("GTM", "unpairAcctConsentImpression", { method });
  if (window.dataLayer) {
    window.dataLayer.push({
      event: "unpair_acct_consent_impression",
      login_sign_up_method: method,
    });
  }
}

export function unpairAcctConsentClickEvent(method: LoginSignUpMethod) {
  console.info("GTM", "unpairAcctConsentClick", { method });
  if (window.dataLayer) {
    window.dataLayer.push({
      event: "unpair_acct_consent_click",
      login_sign_up_method: method,
    });
  }
}

export function pairUpAnotherAcctUnpairedEvent(method: LoginSignUpMethod) {
  console.info("GTM", "pairUpAnotherAcctUnpaired", { method });
  if (window.dataLayer) {
    window.dataLayer.push({
      event: "pair_up_another_acct_unpaired",
      login_sign_up_method: method,
    });
  }
}

export function loginFormImpressionEvent() {
  console.info("GTM", "loginFormImpressionEvent");
  if (window.dataLayer) {
    window.dataLayer.push({
      event: "login_form_impression",
    });
  }
}

export function singleSignOnFailEvent(
  method: LoginSignUpMethod,
  failType: LoginSignUpFailType
) {
  console.info("GTM", "singleSignOnFailEvent", { method, failType });
  if (window.dataLayer) {
    window.dataLayer.push({
      event: "single_sign_on_fail",
      login_sign_up_method: method,
      login_sign_up_fail_type: failType,
    });
  }
}

const enableBingoListTracking = (() => {
  const isEnableBingoListTrackingObject = (
    obj: unknown
  ): obj is {
    ENABLE_BINGO_LIST_TRACKING: true;
  } => {
    return obj instanceof Object && "ENABLE_BINGO_LIST_TRACKING" in obj;
  };
  return Config.DEVELOPMENT_ENABLE_REFACTOR_ADD_TO_CART &&
    isEnableBingoListTrackingObject(
      Config.DEVELOPMENT_ENABLE_REFACTOR_ADD_TO_CART
    ) &&
    Config.DEVELOPMENT_ENABLE_REFACTOR_ADD_TO_CART.ENABLE_BINGO_LIST_TRACKING
    ? true
    : false;
})();

export function bingolistTooltipsImpressionEvent() {
  if (!enableBingoListTracking) {
    return;
  }
  console.info("GTM", "bingolistTooltipsImpressionEvent");
  if (window.dataLayer) {
    window.dataLayer.push({
      event: "bingolist_tooltips_impression",
    });
  }
}

export function bingolistTooltipsClickEvent() {
  if (!enableBingoListTracking) {
    return;
  }
  console.info("GTM", "bingolistTooltipsClickEvent");
  if (window.dataLayer) {
    window.dataLayer.push({
      event: "bingolist_tooltips_click",
    });
  }
}

export function bingolistProfileImpressionEvent() {
  if (!enableBingoListTracking) {
    return;
  }
  console.info("GTM", "bingolistProfileImpression");
  if (window.dataLayer) {
    window.dataLayer.push({
      event: "bingolist_profile_impression",
    });
  }
}

export function bingolistProfileClickEvent() {
  if (!enableBingoListTracking) {
    return;
  }
  console.info("GTM", "bingolistProfileClickEvent");
  if (window.dataLayer) {
    window.dataLayer.push({
      event: "bingolist_profile_click",
    });
  }
}

export function viewBingoListEvent(
  bingo_list_id: string,
  bingo_list_name: string
) {
  if (!enableBingoListTracking) {
    return;
  }
  console.info("GTM", "viewBingoListEvent", {
    bingo_list_id,
    bingo_list_name,
  });
  if (window.dataLayer) {
    window.dataLayer.push({
      event: "view_bingo_list",
      bingo_list_id,
      bingo_list_name,
    });
  }
}

export function selectBingoListEvent(
  bingo_list_id: string,
  bingo_list_name: string
) {
  if (!enableBingoListTracking) {
    return;
  }
  console.info("GTM", "selectBingoListEvent", {
    bingo_list_id,
    bingo_list_name,
  });
  if (window.dataLayer) {
    window.dataLayer.push({
      event: "select_bingo_list",
      bingo_list_id,
      bingo_list_name,
    });
  }
}

export function addToCartBingoListEvent(
  item_id: string,
  item_name: string,
  price: number
) {
  if (!enableBingoListTracking) {
    return;
  }
  console.info("GTM", "addToCartBingoListEvent", { item_id, item_name, price });
  if (window.dataLayer) {
    window.dataLayer.push({
      event: "add_to_cart_bingo_list",
      item_id,
      item_name,
      price,
    });
  }
}

export function addToCartBingoListFailEvent(
  item_id: string,
  item_name: string,
  error_message: string
) {
  if (!enableBingoListTracking) {
    return;
  }
  console.info("GTM", "addToCartBingoListFailEvent", {
    item_id,
    item_name,
    error_message,
  });
  if (window.dataLayer) {
    window.dataLayer.push({
      event: "add_to_cart_bingo_list_fail",
      item_id,
      item_name,
      error_message,
    });
  }
}
