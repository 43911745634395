import React, { useReducer, useMemo } from "react";
import produce from "immer";

import {
  ProductCustomizationOptionMultipleSelect,
  ProductCustomizableOptionCheckbox,
  requiredCustomizationOptionIds,
  ProductCustomizationOption,
  isProductCustomizableOptionTextArea,
  isProductCustomizableOptionDate,
  isProductCustomizableOptionDropDown,
  isProductCustomizationOptionMultipleSelect,
  isProductCustomizableOptionTextField,
  isProductCustomizableOptionRadio,
  isProductCustomizableOptionCheckbox,
  getProductCustomizationOptionAdditionalPrice,
} from "../../../models/product";
import { Product } from "../../../models/ProductDetails";
import { SubscribeEnum } from "../../../models/cart";

import { IndexMap, indexMapLength } from "../../../utils/type";

export interface PurchaseProductFormStateReducer {
  formState: FormState;
  formStateDispatch: React.Dispatch<Action>;
}

export interface FormState {
  subscribe: SubscribeEnum;
  quantity: number;
  configurationOptionValue: IndexMap<string, number | null>;
  customizableOptionValue: {
    textAreaField: IndexMap<number, string>;
    dateField: IndexMap<number, Date | undefined>;
    dropDownField: IndexMap<number, number | undefined>;
    multiSelect: IndexMap<number, boolean[]>;
    textField: IndexMap<number, string>;
    fileField: IndexMap<number, string>;
    radioField: IndexMap<number, number | undefined>;
    checkbox: IndexMap<number, boolean[]>;
  };
}

function customizedOptionIds(formState: FormState): number[] {
  return Object.keys(formState.customizableOptionValue.textAreaField)
    .filter(k => {
      const value =
        formState.customizableOptionValue.textAreaField[parseInt(`${k}`, 10)];
      if (value) {
        return true;
      }
      return false;
    })
    .concat(
      Object.keys(formState.customizableOptionValue.dateField).filter(k => {
        const value =
          formState.customizableOptionValue.dateField[parseInt(`${k}`, 10)];
        if (value) {
          return true;
        }
        return false;
      })
    )
    .concat(
      Object.keys(formState.customizableOptionValue.dropDownField).filter(k => {
        const value =
          formState.customizableOptionValue.dropDownField[parseInt(`${k}`, 10)];
        if (value) {
          return true;
        }
        return false;
      })
    )
    .concat(
      Object.keys(formState.customizableOptionValue.multiSelect).filter(k => {
        const values =
          formState.customizableOptionValue.multiSelect[parseInt(`${k}`, 10)];
        if (values && values.filter(x => !!x).length) {
          return true;
        }
        return false;
      })
    )
    .concat(
      Object.keys(formState.customizableOptionValue.textField).filter(k => {
        const value =
          formState.customizableOptionValue.textField[parseInt(`${k}`, 10)];
        if (value) {
          return true;
        }
        return false;
      })
    )
    .concat(
      Object.keys(formState.customizableOptionValue.fileField).filter(k => {
        const value =
          formState.customizableOptionValue.fileField[parseInt(`${k}`, 10)];
        if (value) {
          return true;
        }
        return false;
      })
    )
    .concat(
      Object.keys(formState.customizableOptionValue.radioField).filter(k => {
        const value =
          formState.customizableOptionValue.radioField[parseInt(`${k}`, 10)];
        if (value) {
          return true;
        }
        return false;
      })
    )
    .concat(
      Object.keys(formState.customizableOptionValue.checkbox).filter(k => {
        const values =
          formState.customizableOptionValue.checkbox[parseInt(`${k}`, 10)];
        if (values && values.filter(x => !!x).length) {
          return true;
        }
        return false;
      })
    )
    .map(k => Number(k));
}

function hasFormStateConfigured(
  formState: FormState,
  product: Product
): boolean {
  return (
    product.configurableOptions == null ||
    product.configurableOptions.length ===
      indexMapLength(formState.configurationOptionValue)
  );
}

function hasFormStateCustomizedRequiredOptions(
  formState: FormState,
  product: Product
): boolean {
  if (product.customizableOptions == null) {
    return true;
  }

  const chosenOptionIds = customizedOptionIds(formState);
  const requiredOptionIds = requiredCustomizationOptionIds(
    product.customizableOptions
  );
  const chosenRequiredOptionIds = requiredOptionIds.filter(id =>
    chosenOptionIds.includes(id)
  );
  return requiredOptionIds.length === chosenRequiredOptionIds.length;
}

// return true if
// - all configurable option is choosen, AND
// - all required customizable option is choosen
export function isFormStateFulllyConfigured(
  formState: FormState,
  product: Product
): boolean {
  return (
    hasFormStateConfigured(formState, product) &&
    hasFormStateCustomizedRequiredOptions(formState, product)
  );
}

export function getSelectedCustomizedOptions(
  formState: FormState,
  product: Product
): ProductCustomizationOption[] {
  const selectedOptions: ProductCustomizationOption[] = [];
  if (product.customizableOptions != null) {
    for (const option of product.customizableOptions) {
      if (!option) {
        continue;
      }
      if (isProductCustomizableOptionTextArea(option)) {
        const value =
          formState.customizableOptionValue.textAreaField[option.optionId];
        if (value) {
          selectedOptions.push(option);
        }
      }
      if (isProductCustomizableOptionDate(option)) {
        const value =
          formState.customizableOptionValue.dateField[option.optionId];
        if (value) {
          selectedOptions.push(option);
        }
      }
      if (isProductCustomizableOptionDropDown(option)) {
        const value =
          formState.customizableOptionValue.dropDownField[option.optionId];
        if (value) {
          selectedOptions.push({
            ...option,
            dropDownOptions: option.dropDownOptions.filter(o => o.id === value),
          });
        }
      }
      if (isProductCustomizationOptionMultipleSelect(option)) {
        const values =
          formState.customizableOptionValue.multiSelect[option.optionId];
        if (values) {
          selectedOptions.push({
            ...option,
            selectOptions: option.selectOptions.filter(
              (_, i) => values[i] === true
            ),
          });
        }
      }
      if (isProductCustomizableOptionTextField(option)) {
        const value =
          formState.customizableOptionValue.textField[option.optionId];
        if (value) {
          selectedOptions.push(option);
        }
      }
      /** TODO: File
       *
       *
       *
       *
       *
       *
       */
      if (isProductCustomizableOptionRadio(option)) {
        const value =
          formState.customizableOptionValue.radioField[option.optionId];
        if (value) {
          selectedOptions.push({
            ...option,
            radioOptions: option.radioOptions.filter(o => o.id === value),
          });
        }
      }
      if (isProductCustomizableOptionCheckbox(option)) {
        const values =
          formState.customizableOptionValue.checkbox[option.optionId];
        if (values) {
          selectedOptions.push({
            ...option,
            checkboxOptions: option.checkboxOptions.filter(
              (_, i) => values[i] === true
            ),
          });
        }
      }
    }
  }
  return selectedOptions;
}

export function computeAdditionalPrice(
  originalPrice: number,
  selectedCustomizedOptions: ProductCustomizationOption[]
): number {
  return selectedCustomizedOptions
    .map(option =>
      getProductCustomizationOptionAdditionalPrice(originalPrice, option)
    )
    .reduce((sum, current) => sum + current, 0);
}

export function getFormInitialState(): FormState {
  return {
    subscribe: SubscribeEnum.notSubscribe,
    quantity: 1,
    configurationOptionValue: {},
    customizableOptionValue: {
      textAreaField: {},
      dateField: {},
      dropDownField: {},
      multiSelect: {},
      textField: {},
      fileField: {},
      radioField: {},
      checkbox: {},
    },
  };
}

export type Action =
  | {
      type: "Init";
    }
  | {
      type: "UpdateQuantity";
      quantity: number;
    }
  | {
      type: "UpdateConfigurationOptionValue";
      id: number;
      value: number | null;
      childOptionIds: number[];
    }
  | {
      type: "UpdateCustomizableOptionTextAreaValue";
      id: number;
      value: string;
    }
  | {
      type: "UpdateCustomizableOptionDateValue";
      id: number;
      value: Date | undefined;
    }
  | {
      type: "UpdateCustomizableOptionDropDownValue";
      id: number;
      value: number | undefined;
    }
  | {
      type: "UpdateCustomizableOptionMultiSelectValue";
      customizationOption: ProductCustomizationOptionMultipleSelect;
      item: { value: any };
      checked: boolean;
    }
  | {
      type: "UpdateCustomizableOptionTextFieldValue";
      id: number;
      value: string;
    }
  | {
      type: "UpdateCustomizableOptionFileFieldValue";
      id: number;
      value: string;
    }
  | {
      type: "UpdateCustomizableOptionRadioFieldValue";
      id: number;
      value: number | undefined;
    }
  | {
      type: "UpdateCustomizableOptionCheckboxValue";
      customizationOption: ProductCustomizableOptionCheckbox;
      item: { value: number };
      checked: boolean;
    }
  | {
      type: "SetFormState";
      formState: FormState;
    };

function purchaseProductFormStateReducer(
  state: FormState,
  action: Action
): FormState {
  if (action.type === "SetFormState") {
    return action.formState;
  }
  return produce(state, draft => {
    switch (action.type) {
      case "UpdateQuantity":
        draft.quantity = action.quantity;
        break;
      case "UpdateConfigurationOptionValue":
        draft.configurationOptionValue[action.id] = action.value;
        for (const childOptionId of action.childOptionIds) {
          draft.configurationOptionValue[childOptionId] = undefined;
        }
        break;
      case "UpdateCustomizableOptionTextAreaValue":
        draft.customizableOptionValue.textAreaField[action.id] = action.value;
        break;
      case "UpdateCustomizableOptionDateValue":
        draft.customizableOptionValue.dateField[action.id] = action.value;
        break;
      case "UpdateCustomizableOptionDropDownValue":
        draft.customizableOptionValue.dropDownField[action.id] = action.value;
        break;
      case "UpdateCustomizableOptionMultiSelectValue": {
        const id = action.customizationOption.optionId;
        const checkedList = draft.customizableOptionValue.multiSelect[id] || [];
        const index = action.customizationOption.selectOptions.findIndex(o => {
          return o.id === action.item.value;
        });
        if (index !== -1) {
          checkedList[index] = action.checked;
        }
        draft.customizableOptionValue.multiSelect[id] = checkedList;
        break;
      }
      case "UpdateCustomizableOptionTextFieldValue":
        draft.customizableOptionValue.textField[action.id] = action.value;
        break;
      case "UpdateCustomizableOptionFileFieldValue":
        draft.customizableOptionValue.fileField[action.id] = action.value;
        break;
      case "UpdateCustomizableOptionRadioFieldValue":
        draft.customizableOptionValue.radioField[action.id] = action.value;
        break;
      case "UpdateCustomizableOptionCheckboxValue": {
        const id = action.customizationOption.optionId;
        const checkedList = draft.customizableOptionValue.checkbox[id] || [];
        const index = action.customizationOption.checkboxOptions.findIndex(
          o => {
            return o.id === action.item.value;
          }
        );
        if (index !== -1) {
          checkedList[index] = action.checked;
        }
        draft.customizableOptionValue.checkbox[id] = checkedList;
        break;
      }
      default:
        break;
    }
  });
}

export function usePurchaseProductFormStateReducer(
  initialState?: FormState
): PurchaseProductFormStateReducer {
  const _initialState = initialState ? initialState : getFormInitialState();
  const [formState, formStateDispatch] = useReducer(
    purchaseProductFormStateReducer,
    _initialState
  );
  return useMemo(
    () => ({
      formState,
      formStateDispatch,
    }),
    [formState, formStateDispatch]
  );
}
