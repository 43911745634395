import moment from "moment-timezone";
import { Decimal } from "decimal.js";

import { IndexMap, Override } from "../utils/type";
import { enableSmartProtectionByTier } from "../utils/SmartProtection";

import { Customer, isCustomerLinkedToTheClub } from "./Customer";
import { MediaContent } from "./Media";
import { Money, areMoneyEqual } from "./Price";
import { StoreConfig } from "./StoreConfig";

import Config from "../Config";

// For apollo cache
export interface ModelKeys {
  id: number;
  entityId: number;
  sku: string;
}

// For apollo cache
export const keyGraphQLAttributes = `
id
entityId: entity_id
sku
`;

export type ProductVariantProduct<T> = ModelKeys & {
  variants?: {
    product: ModelKeys & T;
  }[];
};

export function getVariantGraphQLAttributes(graphQLAttributes: string) {
  return `
${keyGraphQLAttributes}
... on ConfigurableProduct {
  variants {
    product {
      ${keyGraphQLAttributes}
      ${graphQLAttributes}
    }
  }
}
`;
}

const SERVER_DATETIME_FORMAT = "YYYY-MM-DD HH:mm:ss";

export type ProductTodoAttribute_Number = number;

/**
 * TODO (Steven-Chan):
 * expands this list when need to identifier different types
 *
 * This type is only for the app to handle some special delivery methods
 * specially, but should always assume there are other delivery methods returned
 * from server.
 */

export type ProductDeliveryMethod =
  // CLUB SHOPPING Delivery
  | "warehouse"
  // Self pick-up and e-voucher
  | "evoucher"
  // 3-5 days Consolidated Delivery
  | "consolidate"
  // Merchant Delivery
  | "merchant*";

export function isMerchantProductDeliveryMethod(m: ProductDeliveryMethod) {
  return /merchant/.exec(m) != null;
}

export function isEDDIgnoredByDeliveryMethod(d: ProductDeliveryMethod) {
  return d === "evoucher" || isMerchantProductDeliveryMethod(d);
}

export type ProductStockStatus = "IN_STOCK" | "OUT_OF_STOCK";

export type ThirdPartyProductDisplayType = "BUTTON_WITH_LINK" | "INFO_MESSAGE";

export type PriceTypeEnum = "FIXED" | "PERCENT" | "DYNAMIC";

export type ProductType = "simple" | "configurable" | "virtual";

export type CLClubPoint = string;

export interface Product360Image {
  id: number;
  url: string;
  position: number;
}

export const Product360ImageGraphQLAttributes = `
  id
  url: large_image_url
  position
`;

export interface PriceRange {
  minimumPrice: {
    regularPrice: Money;
    finalPrice: Money;
  };
  maximumPrice: {
    regularPrice: Money;
    finalPrice: Money;
  };
}

// FEATURE_MERGE: club_tier_quota present in prduct interface => ProductOverviewBaseGraphQLAttributes
export const ProductOverviewClubTierQuotaGraphQLAttributes = `
clubTierQuota: club_tier_quota {
  isEnabled: is_enabled
  tierConfig: tier_config {
    endTime: end_time
    max
    startTime: start_time
    tier
  }
}
quota {
  message
  value
}
memberQuota: member_quota {
  message
  value
}
`;

export const ProductOverviewBaseClubTierQuotaGraphQLAttributes = `
${keyGraphQLAttributes}
${ProductOverviewClubTierQuotaGraphQLAttributes}
`;

export type ProductOverviewBaseClubTierQuota = ModelKeys & {
  clubTierQuota: ClubTierQuota | null;
  quota: Quota | null;
  memberQuota: Quota | null;
};

export type RemoteProductOverviewBaseClubTierQuota = Override<
  ProductOverviewBaseClubTierQuota,
  {
    clubTierQuota: RemoteClubTierQuota | null;
  }
>;

export interface Quota {
  message: string | null;
  value: number | null;
}

export interface ClubTierQuota {
  isEnabled: boolean;
  tierConfig: TierConfig[];
}

export type RemoteClubTierQuota = Override<
  ClubTierQuota,
  {
    tierConfig: RemoteTierConfig[];
  }
>;

export interface TierConfig {
  startTime: Date | null;
  endTime: Date | null;
  max: number | null;
  tier: string;
}

export type RemoteTierConfig = Override<
  TierConfig,
  {
    startTime: number | null;
    endTime: number | null;
  }
>;

function transformRemoteTierConfigToTierConfig(
  remoteTierConfig: RemoteTierConfig
): TierConfig {
  const { startTime, endTime } = remoteTierConfig;
  return {
    ...remoteTierConfig,
    startTime: startTime
      ? moment.tz(startTime * 1000, Config.TIMEZONE).toDate()
      : null,
    endTime: endTime
      ? moment.tz(endTime * 1000, Config.TIMEZONE).toDate()
      : null,
  };
}

export function transformRemoteClubTierQuotaToClubTierQuota(
  remoteClubTierQuota: RemoteClubTierQuota
): ClubTierQuota {
  return {
    ...remoteClubTierQuota,
    tierConfig: remoteClubTierQuota.tierConfig.map(
      transformRemoteTierConfigToTierConfig
    ),
  };
}

export type ThirdPartyProduct<
  T extends Partial<{
    displayType: ThirdPartyProductDisplayType | null;
    buttonUrl: string | null;
    infoMessage: string | null;
    showPrice?: boolean | null;
  }>
> = T & {
  displayType: ThirdPartyProductDisplayType;
  buttonUrl: string | null;
  infoMessage: string | null;
  showPrice?: boolean | null;
};

export function isThirdPartyProduct<
  T extends Partial<{
    displayType: ThirdPartyProductDisplayType | null;
    buttonUrl: string | null;
    infoMessage: string | null;
    showPrice?: boolean | null;
  }>
>(product: T): product is ThirdPartyProduct<T> {
  const { displayType } = product;
  return displayType != null;
}

export const ProductConfigurableOptionGraphQLAttributes = `
  id
  label
  attributeCode: attribute_code
  values {
    label: store_label
    value: value_index
  }
  position
`;

export type VariantProductClubTierQuota = ProductVariantProduct<
  RemoteProductOverviewBaseClubTierQuota
>;

export const ProductOverviewVariantProductCubTierQuotaGraphQLAttributes = getVariantGraphQLAttributes(
  ProductOverviewClubTierQuotaGraphQLAttributes
);

export type ProductEnableAgeDeclaration = ModelKeys & {
  enableAgeDeclaration?: boolean;
};

export const ProductEnableAgeDeclarationGraphQLAttributes = `
${keyGraphQLAttributes}
enableAgeDeclaration: enable_age_declaration
`;

export type VariantProductEnableAgeDeclaration = ProductVariantProduct<{
  enableAgeDeclaration?: boolean;
}>;

export const VariantProductEnableAgeDeclarationGraphQLAttributes = getVariantGraphQLAttributes(`
enableAgeDeclaration: enable_age_declaration
`);

export interface RelatedProductLink {
  linkType: "related";
  linkedProductSKU: string;
}

export interface UpsellProductLink {
  linkType: "upsell";
  linkedProductSKU: string;
}

export type ProductLink = RelatedProductLink | UpsellProductLink;

export type RecurringDateType =
  | { type: "unknown"; message: string }
  | { type: "known"; date: Date };

export interface RecurringConfiguration {
  isRecurringEnable: boolean;
  isSubscriptionOnly: boolean;
  billingCycle: string | null;
  isEnableTrial: boolean;
  isFreeShipping: boolean;
  startDate: RecurringDateType | null;
  endDate: RecurringDateType | null;
}

export interface RemoteRecurringConfiguration {
  isRecurringEnable: boolean;
  isSubscriptionOnly: boolean;
  billingCycle: string | null;
  isEnableTrial: boolean;
  isFreeShipping: boolean;
  startDate: string | null;
  endDate: string | null;
}

export function transformRemoteRecurringConfigurationToRecurringConfiguration(
  remoteRecurringConfiguration: RemoteRecurringConfiguration
): RecurringConfiguration {
  const {
    startDate: _startDate,
    endDate: _endDate,
  } = remoteRecurringConfiguration;

  const parseDateStr = (maybeDateStr: string): RecurringDateType => {
    const m = moment.tz(maybeDateStr, "YYYY-MM-DD", Config.TIMEZONE);
    if (m.isValid()) {
      return { type: "known", date: m.toDate() };
    }
    return { type: "unknown", message: maybeDateStr };
  };

  const startDate: RecurringDateType | null = _startDate
    ? parseDateStr(_startDate)
    : null;
  const endDate: RecurringDateType | null = _endDate
    ? parseDateStr(_endDate)
    : null;

  return {
    ...remoteRecurringConfiguration,
    startDate,
    endDate,
  };
}

export type RemoteProductEstimatedDeliveryDate = ModelKeys & {
  estimatedDeliveryDate?: number | null;
};

export type ProductEstimatedDeliveryDate = ModelKeys & {
  estimatedDeliveryDate?: Date | null;
};

export const ProductEstimatedDeliveryDateGraphQLAttributes = `
${keyGraphQLAttributes}
estimatedDeliveryDate: estimated_delivery_date
`;

export type RemoteVariantProductEstimatedDeliveryDate = ProductVariantProduct<
  RemoteProductEstimatedDeliveryDate
>;

export type VariantProductEstimatedDeliveryDate = ProductVariantProduct<
  ProductEstimatedDeliveryDate
>;

export const VariantProductEstimatedDeliveryDateGraphQLAttributes = getVariantGraphQLAttributes(`
estimatedDeliveryDate: estimated_delivery_date
`);

export type ProductIsPreOrder = ModelKeys & {
  isPreOrder?: boolean | null;
};

export const ProductIsPreOrderGraphQLAttributes = `
${keyGraphQLAttributes}
isPreOrder: is_pre_order
`;

export type VariantProductIsPreOrder = ProductVariantProduct<ProductIsPreOrder>;

export const VariantProductIsPreOrderGraphQLAttributes = getVariantGraphQLAttributes(`
isPreOrder: is_pre_order
`);

export function transformRemoteEstimatedDeliveryDate(remote: number): Date {
  return moment.tz(remote * 1000, Config.TIMEZONE).toDate();
}

export interface ProductConfigurableOptionValue {
  label: string;
  value: number;
  // TODO(1285):
  // We temp remove swatch data as server is not fully support swatch data
  // in some products
  // swatchData: {
  //   type: "TEXTUAL" | "COLOR" | "IMAGE" | "EMPTY";
  //   value: string;
  // } | null;
}

export interface ProductConfigurableOption {
  id: number;
  label: string;
  attributeCode: string;
  values: ProductConfigurableOptionValue[];
  position?: number | null;
}

export function isProductConfigurableOptionColorOption(
  _option: ProductConfigurableOption
): boolean {
  // TODO(1285):
  // We temp disable color picker as server is not fully ready for this
  // return option.attributeCode === "color_family";
  return false;
}

export interface ProductConfigurableAttributeOption {
  label: string;
  value: number;
  code?: string;
}

interface ProductCustomizationOptionInterface {
  optionId: number;
  title: string;
  isRequired: boolean;
  sortOrder?: number | null;
}

export const ProductCustomizationOptionInterfaceGraphQLAttributes = `
  optionId: option_id
  title
  isRequired: required
  sortOrder: sort_order
`;

export interface ProductCustomizableOptionCheckbox
  extends ProductCustomizationOptionInterface {
  checkboxOptions: {
    id: number;
    title: string;
    price: number | null;
    priceType: PriceTypeEnum | null;
  }[];
}

export const ProductCustomizableOptionCheckboxGraphQLAttributes = `
  checkboxOptions: value {
    id: option_type_id
    title
    price
    priceType: price_type
  }
`;

export function isProductCustomizableOptionCheckbox(
  option: ProductCustomizationOption
): option is ProductCustomizableOptionCheckbox {
  return (option as any).checkboxOptions != null;
}

interface ProductCustomizableOptionTextField
  extends ProductCustomizationOptionInterface {
  textInput: {
    maxCharacters: number | null;
    price: number | null;
    priceType: PriceTypeEnum | null;
  };
}

export const ProductCustomizableOptionTextFieldGraphQLAttributes = `
  textInput: value {
    maxCharacters: max_characters
    price
    priceType: price_type
  }
`;

export function isProductCustomizableOptionTextField(
  option: ProductCustomizationOption
): option is ProductCustomizableOptionTextField {
  return (option as any).textInput != null;
}

interface ProductCustomizableOptionTextArea
  extends ProductCustomizationOptionInterface {
  areaInput: {
    maxCharacters: number | null;
    price: number | null;
    priceType: PriceTypeEnum | null;
  };
}

export const ProductCustomizationOptionTextAreaGraphQLAttributes = `
  areaInput: value {
    maxCharacters: max_characters
    price
    priceType: price_type
  }
`;

export function isProductCustomizableOptionTextArea(
  option: ProductCustomizationOption
): option is ProductCustomizableOptionTextArea {
  return (option as any).areaInput != null;
}

interface ProductCustomizableOptionDate
  extends ProductCustomizationOptionInterface {
  dateInput: {
    sku: string;
    price: number | null;
    priceType: PriceTypeEnum | null;
  };
}

export const ProductCustomizationOptionDateGraphQLAttributes = `
  dateInput: value {
    sku
    price
    priceType: price_type
  }
`;

export function isProductCustomizableOptionDate(
  option: ProductCustomizationOption
): option is ProductCustomizableOptionDate {
  return (option as any).dateInput != null;
}

interface ProductCustomizableOptionDropDown
  extends ProductCustomizationOptionInterface {
  dropDownOptions: {
    id: number;
    title: string;
    price: number | null;
    priceType: PriceTypeEnum | null;
  }[];
}

export const ProductCustomizationOptionDropDownGraphQLAttributes = `
  dropDownOptions: value {
    id: option_type_id
    title
    price
    priceType: price_type
  }
`;

export function isProductCustomizableOptionDropDown(
  option: ProductCustomizationOption
): option is ProductCustomizableOptionDropDown {
  return (option as any).dropDownOptions != null;
}

export interface ProductCustomizationOptionMultipleSelect
  extends ProductCustomizationOptionInterface {
  selectOptions: {
    id: number;
    title: string;
    price: number | null;
    priceType: PriceTypeEnum | null;
  }[];
}

export const ProductCustomizationOptionMultipleSelectGraphQLAttributes = `
  selectOptions: value {
    id: option_type_id
    title
    price
    priceType: price_type
  }
`;

export function isProductCustomizationOptionMultipleSelect(
  option: ProductCustomizationOption
): option is ProductCustomizationOptionMultipleSelect {
  return (option as any).selectOptions != null;
}

export interface ProductCustomizableOptionFile
  extends ProductCustomizationOptionInterface {
  fileInput: {
    fileExtension: string | null;
    imageSizeX: number | null;
    imageSizeY: number | null;
    price: number | null;
    priceType: PriceTypeEnum | null;
  };
}

export const ProductCustomizableOptionFileGraphQLAttributes = `
  fileInput: value {
    fileExtension: file_extension
    imageSizeX: image_size_x
    imageSizeY: image_size_y
    price
    priceType: price_type
  }
`;

export function isProductCustomizableOptionFile(
  option: ProductCustomizationOption
): option is ProductCustomizableOptionFile {
  return (option as any).fileInput != null;
}

export interface ProductCustomizableOptionRadio
  extends ProductCustomizationOptionInterface {
  radioOptions: {
    id: number;
    title: string;
    price: number | null;
    priceType: PriceTypeEnum | null;
  }[];
}

export const ProductCustomizableOptionRadioGraphQLAttributes = `
  radioOptions: value {
    id: option_type_id
    title
    price
    priceType: price_type
  }
`;

export function isProductCustomizableOptionRadio(
  option: ProductCustomizationOption
): option is ProductCustomizableOptionRadio {
  return (option as any).radioOptions != null;
}

export function isProductSale<
  T extends {
    priceRange: PriceRange | null;
    specialFromDateStr: string | null;
    specialToDateStr: string | null;
  }
>(product: T, now: Date = new Date()): boolean {
  const { priceRange } = product;
  if (priceRange == null) {
    return false;
  }
  const { minimumPrice, maximumPrice } = priceRange;

  if (
    !areMoneyEqual(minimumPrice.regularPrice, minimumPrice.finalPrice) ||
    !areMoneyEqual(maximumPrice.regularPrice, maximumPrice.finalPrice)
  ) {
    const { specialFromDateStr, specialToDateStr } = product;
    const specialFromDate =
      specialFromDateStr != null
        ? moment(specialFromDateStr, SERVER_DATETIME_FORMAT).toDate()
        : null;
    // The to date is inclusive
    const specialToDate =
      specialToDateStr != null
        ? moment(specialToDateStr, SERVER_DATETIME_FORMAT)
            .add(1, "days")
            .toDate()
        : null;
    if (specialFromDate != null && now < specialFromDate) {
      return false;
    }
    if (specialToDate != null && now > specialToDate) {
      return false;
    }
    return true;
  }
  return false;
}

export function isProductNew<
  T extends {
    newFromDateStr: string | null;
    newToDateStr: string | null;
  }
>(product: T): boolean {
  const { newFromDateStr, newToDateStr } = product;
  if (newFromDateStr == null || newToDateStr == null) {
    return false;
  }
  const newFromDate = moment(newFromDateStr, SERVER_DATETIME_FORMAT).toDate();
  // The to date is inclusive
  const newToDate = moment(newToDateStr, SERVER_DATETIME_FORMAT)
    .add(1, "days")
    .toDate();
  const now = new Date();
  if (newFromDate > now) {
    return false;
  }
  if (newToDate < now) {
    return false;
  }
  return true;
}

export function doesProductHave360Images<
  T extends { magic360Images: Product360Image[] }
>(product: T): boolean {
  return product.magic360Images.length > 0;
}

export function getProductMerchant<T>(product: {
  merchant: [T | null];
}): T | null {
  const [merchant] = product.merchant;
  return merchant != null ? merchant : null;
}

export type ProductCustomizationOption =
  | ProductCustomizableOptionTextArea
  | ProductCustomizableOptionDate
  | ProductCustomizableOptionDropDown
  | ProductCustomizationOptionMultipleSelect
  | ProductCustomizableOptionTextField
  | ProductCustomizableOptionFile
  | ProductCustomizableOptionRadio
  | ProductCustomizableOptionCheckbox;

export function isProductCustimizationOption(
  maybeCustomizationOption: ProductCustomizationOption | null
): maybeCustomizationOption is ProductCustomizationOption {
  return maybeCustomizationOption != null;
}

export function getPriceOfCustomizableOptionPriceType(
  originalPrice: number,
  priceType: PriceTypeEnum | null,
  value: number | null
) {
  if (priceType == null || value == null) {
    return 0;
  }
  switch (priceType) {
    case "FIXED":
      return value;
    case "PERCENT":
      return (originalPrice * value) / 100;
    default:
      // TODO: DYNAMIC wont be handled because no use case in web site
      return 0;
  }
}

export function getProductCustomizationOptionAdditionalPrice(
  originalPrice: number,
  option: ProductCustomizationOption
): number {
  function getMaybePrice(): number | null {
    if (isProductCustomizableOptionTextArea(option)) {
      const { priceType, price } = option.areaInput;
      return getPriceOfCustomizableOptionPriceType(
        originalPrice,
        priceType,
        price
      );
    }
    if (isProductCustomizableOptionDate(option)) {
      const { priceType, price } = option.dateInput;
      return getPriceOfCustomizableOptionPriceType(
        originalPrice,
        priceType,
        price
      );
    }
    if (isProductCustomizableOptionDropDown(option)) {
      const { priceType, price } = option.dropDownOptions[0];
      return getPriceOfCustomizableOptionPriceType(
        originalPrice,
        priceType,
        price
      );
    }
    if (isProductCustomizationOptionMultipleSelect(option)) {
      return option.selectOptions.reduce<number>(
        (acc, { priceType, price }) => {
          const p = getPriceOfCustomizableOptionPriceType(
            originalPrice,
            priceType,
            price
          );
          return p ? acc + p : acc;
        },
        0
      );
    }
    if (isProductCustomizableOptionTextField(option)) {
      const { priceType, price } = option.textInput;
      return getPriceOfCustomizableOptionPriceType(
        originalPrice,
        priceType,
        price
      );
    }
    /** TODO: File
     *
     *
     *
     *
     *
     *
     */
    if (isProductCustomizableOptionRadio(option)) {
      const { priceType, price } = option.radioOptions[0];
      return getPriceOfCustomizableOptionPriceType(
        originalPrice,
        priceType,
        price
      );
    }
    if (isProductCustomizableOptionCheckbox(option)) {
      return option.checkboxOptions.reduce<number>(
        (acc, { priceType, price }) => {
          const p = getPriceOfCustomizableOptionPriceType(
            originalPrice,
            priceType,
            price
          );
          return p ? acc + p : acc;
        },
        0
      );
    }

    return null;
  }
  return getMaybePrice() || 0;
}

export function requiredCustomizationOptionIds(
  customizationOptions: (ProductCustomizationOption | null)[]
): number[] {
  return customizationOptions
    .filter(isProductCustimizationOption)
    .filter(opt => opt.isRequired)
    .map(opt => opt.optionId);
}

export interface ProductSpec {
  label: string;
  value: string;
}

export interface ProductColor {
  label: string;
  value: number;
  data:
    | {
        type: "TEXTUAL";
        value: string;
      }
    | {
        type: "COLOR";
        value: string;
      }
    | {
        type: "IMAGE";
        value: string;
      }
    | {
        type: "EMPTY";
      };
}

export function makeProductColorFromProductConfigurableOptionValue(
  option: ProductConfigurableOptionValue
): ProductColor {
  // TODO(1285):
  // We temp remove swatch data as server is not fully support swatch data
  // in some products
  // const { swatchData } = option;
  // if (swatchData == null) {
  //   return {
  //     ...option,
  //     data: {
  //       type: "EMPTY",
  //     },
  //   };
  // }
  // return {
  //   ...option,
  //   data: {
  //     ...swatchData,
  //   },
  // };
  return {
    ...option,
    data: {
      type: "EMPTY",
    },
  };
}

export interface ProductImage {
  disabled: boolean;
  label: string;
  url: string;
}

export const ProductImageGraphQLAttributes = `
  disabled
  url
  label
`;

export type ProductDescriptionMedia =
  | ProductDescriptionImage
  | ProductDescriptionVideo;

export interface ProductDescriptionImage {
  type: "description-image";
  url: string;
}

export interface ProductDescriptionVideo {
  type: "description-video";
  thumbnail: string;
  url: string;
}

export interface ProductTodoAttribute_Review {
  createdAt: Date;
  customer: Customer;
  rating: number;
  comment: string;
  merchantReply: string;
}

export function doesProductHaveOptionsToConfigure<
  T extends {
    configurableOptions?: ProductConfigurableOption[] | null;
    customizableOptions: (ProductCustomizationOption | null)[] | null;
  }
>(product: T): boolean {
  const { configurableOptions, customizableOptions } = product;
  const hasOptionsToConfigure = !(
    (configurableOptions == null || configurableOptions.length === 0) &&
    (customizableOptions == null ||
      customizableOptions.filter(isProductCustimizationOption).length === 0)
  );
  return hasOptionsToConfigure;
}

export function constructMediaUrlForProduct(
  storeConfig: StoreConfig,
  mediaContent: MediaContent
): string {
  return `${storeConfig.baseMediaUrl}catalog/product/${mediaContent.filePath}`;
}

export function getProductShareUrl<T extends { urlKey: string }>(product: T) {
  return `${Config.SITE_URL}/${product.urlKey}.html`;
}

export function augmentProductWithNotImplementedAttribute<T>(
  product: T & {
    qAndACount?: ProductTodoAttribute_Number;
    likeCount?: ProductTodoAttribute_Number;
  }
): T & {
  qAndACount: ProductTodoAttribute_Number;
  likeCount: ProductTodoAttribute_Number;
} {
  return {
    ...product,
    qAndACount: 777,
    likeCount: 777,
  };
}

interface IsProductZeroPriceParams {
  priceRange: PriceRange | null;
  minClubPoint: number;
  variants?: { product: IsProductZeroPriceParams }[] | null;
}

type IsProductZeroPriceFunction = <P extends IsProductZeroPriceParams>(
  p: P
) => boolean;

const isProductZeroPrice: IsProductZeroPriceFunction = ({
  priceRange,
  minClubPoint,
  variants,
}) => {
  if (priceRange) {
    const minMoney = priceRange.minimumPrice.finalPrice;
    const maxMoney = priceRange.maximumPrice.finalPrice;

    if (minMoney.value > 0 || maxMoney.value > 0) {
      return false;
    }
  }

  if (minClubPoint > 0) {
    return false;
  }

  if (variants != null) {
    for (const { product } of variants) {
      if (!isProductZeroPrice(product)) {
        return false;
      }
    }
  }

  return true;
};

interface IsProductOutOfStockParams {
  stockStatus: ProductStockStatus;
  variants?: { product: IsProductOutOfStockParams }[] | null;
}

type IsProductOutOfStockFunction = <P extends IsProductOutOfStockParams>(
  p: P
) => boolean;

const isProductOutOfStock: IsProductOutOfStockFunction = ({
  stockStatus,
  variants,
}) => {
  if (stockStatus === "IN_STOCK") {
    return false;
  }

  if (variants != null) {
    for (const { product } of variants) {
      if (!isProductOutOfStock(product)) {
        return false;
      }
    }
  }

  return true;
};

/**
 * Determine whether the price label should be shown for product
 *
 * |ShouldShowPrice|Normal|Campaign|
 * |---------------|------|--------|
 * |    0 price    | hide |  show  |
 * |    sold out   | show |  show  |
 */

interface ShouldProductShowPriceParams {
  priceRange: PriceRange | null;
  minClubPoint: number;
  variants?: { product: ShouldProductShowPriceParams }[] | null;
}

export type ShouldProductShowPriceFunction = <
  P extends ShouldProductShowPriceParams
>(
  p: P
) => boolean;

export const shouldNormalProductShowPrice: ShouldProductShowPriceFunction = p => {
  return !isProductZeroPrice(p);
};

export const shouldCampaignProductShowPrice: ShouldProductShowPriceFunction = () => {
  return true;
};

/*
 * Determine whether a product should be regarded as sold out
 *
 * |IsSoldOut|Normal|Campaign|
 * |---------|------|--------|
 * | 0 price | true | false  |
 * |sold out | true |  true  |
 */

interface ProductSoldOutParameters {
  stockStatus: ProductStockStatus;
  priceRange: PriceRange | null;
  minClubPoint: number;
  variants?: { product: ProductSoldOutParameters }[] | null;
}

export type IsSoldOutFunction = <P extends ProductSoldOutParameters>(
  p: P
) => boolean;

export const isNormalProductSoldOut: IsSoldOutFunction = p => {
  if (isProductZeroPrice(p)) {
    return true;
  }

  if (isProductOutOfStock(p)) {
    return true;
  }

  return false;
};

export const isCampaignProductSoldOut: IsSoldOutFunction = p => {
  return isProductOutOfStock(p);
};

export function getRebatedClubPoint<
  T extends {
    extraClubpoints: number | null;
    clClubPoint?: CLClubPoint | null;
  }
>(productOverview: T): Decimal {
  const { extraClubpoints, clClubPoint } = productOverview;
  const extraClubPointDecimal = extraClubpoints
    ? new Decimal(extraClubpoints)
    : new Decimal(0);
  const clClubPointDecimal = clClubPoint
    ? new Decimal(clClubPoint)
    : new Decimal(0);
  return extraClubPointDecimal.add(clClubPointDecimal);
}

export function isRebatedClubPointEligible(rebatedClubPoint: number): boolean {
  return rebatedClubPoint > 1;
}

export function getConfiguredProductOfLowestPrice<
  ConfiguredProductType extends {
    priceRange: PriceRange | null;
    minClubPoint: number;
  },
  T extends {
    variants?: { product: ConfiguredProductType }[] | null;
    type: ProductType;
  }
>(product: T, clubPointConversionRate: number): ConfiguredProductType | null {
  const { variants } = product;
  if (
    product.type !== "configurable" ||
    variants == null ||
    variants.length === 0
  ) {
    return null;
  }
  let res: {
    product: ConfiguredProductType;
    fullClubPoint: number;
  } | null = null;
  for (const variant of variants) {
    const { priceRange, minClubPoint } = variant.product;
    if (priceRange == null) {
      continue;
    }
    const finalPrice = priceRange.minimumPrice.finalPrice;
    const fullClubPoint =
      minClubPoint + finalPrice.value * clubPointConversionRate;
    if (res == null || fullClubPoint < res.fullClubPoint) {
      res = { product: variant.product, fullClubPoint };
    }
  }
  return res ? res.product : null;
}

export function getConfiguredProductOfHighestPrice<
  ConfiguredProductType extends {
    priceRange: PriceRange | null;
    minClubPoint: number;
  },
  T extends {
    variants?: { product: ConfiguredProductType }[] | null;
    type: ProductType;
  }
>(product: T, clubPointConversionRate: number): ConfiguredProductType | null {
  const { variants } = product;
  if (
    product.type !== "configurable" ||
    variants == null ||
    variants.length === 0
  ) {
    return null;
  }
  let res: {
    product: ConfiguredProductType;
    fullClubPoint: number;
  } | null = null;
  for (const variant of variants) {
    const { priceRange, minClubPoint } = variant.product;
    if (priceRange == null) {
      continue;
    }
    const finalPrice = priceRange.minimumPrice.finalPrice;
    const fullClubPoint =
      minClubPoint + finalPrice.value * clubPointConversionRate;
    if (res == null || fullClubPoint > res.fullClubPoint) {
      res = { product: variant.product, fullClubPoint };
    }
  }
  return res ? res.product : null;
}

export function getLowestFinalPrice<
  T extends {
    priceRange: PriceRange | null;
  }
>(product: T) {
  if (product.priceRange == null) {
    return 0;
  }
  return product.priceRange.minimumPrice.finalPrice.value;
}

export function isProductOrItsVariantsHasMinClubPoint<
  _ProductType extends {
    minClubPoint: number;
  },
  T extends {
    minClubPoint: number;
    variants?: { product: _ProductType }[] | null;
  }
>(productOverview: T): boolean {
  if (productOverview.minClubPoint && productOverview.minClubPoint > 0) {
    return true;
  }
  if (
    productOverview.variants == null ||
    productOverview.variants.length === 0
  ) {
    return false;
  }
  for (const { product } of productOverview.variants) {
    const { minClubPoint } = product;
    if (minClubPoint && minClubPoint > 0) {
      return true;
    }
  }
  return false;
}

export function sortConfigurableOptions(
  configurableOptions: ProductConfigurableOption[]
) {
  return [...configurableOptions].sort((a, b) =>
    a.position != null && b.position != null ? a.position - b.position : 0
  );
}

export function sortCustomizableOptions(
  customizableOptions: (ProductCustomizationOption | null)[]
) {
  return [...customizableOptions]
    .filter(isProductCustimizationOption)
    .sort((a, b) =>
      a.sortOrder != null && b.sortOrder != null ? a.sortOrder - b.sortOrder : 0
    );
}

export function isProductOrItsVariantsHasClubProtect<
  _ProductType extends {
    priceRange: PriceRange | null;
    enableClubProtection?: boolean | null;
  },
  T extends {
    priceRange: PriceRange | null;
    enableClubProtection?: boolean | null;
    variants?: { product: _ProductType }[] | null;
  }
>(customer: Customer | null, productOverview: T): boolean {
  if (
    productOverview.variants == null ||
    productOverview.variants.length === 0
  ) {
    const { enableClubProtection, priceRange } = productOverview;
    if (enableClubProtection == null || priceRange == null) {
      return false;
    }
    return isClubProtectEligible(
      customer,
      enableClubProtection,
      priceRange.minimumPrice.finalPrice.value
    );
  }
  for (const { product } of productOverview.variants) {
    const { enableClubProtection, priceRange } = product;
    if (enableClubProtection != null && priceRange != null) {
      return isClubProtectEligible(
        customer,
        enableClubProtection,
        priceRange.minimumPrice.finalPrice.value
      );
    }
  }
  return false;
}

export const CLUB_PROTECT_MIN_PRICE = 300;

export function isClubProtectEligible(
  customer: Customer | null,
  enableClubProtection: boolean,
  price: number
): boolean {
  if (
    isCustomerEligibleForClubProtect(customer) &&
    enableClubProtection === true &&
    price >= CLUB_PROTECT_MIN_PRICE
  ) {
    return true;
  }
  return false;
}

export function isCustomerEligibleForClubProtect(
  customer: Customer | null
): boolean {
  if (enableSmartProtectionByTier()) {
    if (!customer) {
      return true;
    }
    if (!isCustomerLinkedToTheClub(customer)) {
      return false;
    }
    const [{ clubTier }] = customer.clubMember;
    if (
      clubTier === "black" ||
      clubTier === "platinum" ||
      clubTier === "gold"
    ) {
      return true;
    }
    return false;
  }
  // Always eligible when feature flag is disabled
  return true;
}

export const ProductThirdPartyProductShowPriceGraphQLAttributes = `
${keyGraphQLAttributes}
... on ThirdPartyProduct {
  showPrice: show_price
}
`;

export type ProductThirdPartyProductShowPriceType = ModelKeys & {
  showPrice?: boolean | null;
};

export const ProductInstalmentGraphQLAttributes = `
${keyGraphQLAttributes}
instalmentAmountPerMonth: installment_amount_per_mo
`;

export type ProductInstalmentType = ModelKeys & {
  instalmentAmountPerMonth?: number | null;
};

export function getIndexedProduct<T extends ModelKeys>(
  items: T[]
): IndexMap<ModelKeys["sku"], T> {
  const map: IndexMap<ModelKeys["sku"], T> = {};
  for (const item of items) {
    map[item.sku] = item;
  }
  return map;
}

export function patchProductFromIndexedMap<
  P extends ModelKeys,
  Q extends ModelKeys
>(product: P, patchMap: IndexMap<ModelKeys["sku"], Q>) {
  const patch = patchMap[product.sku];
  if (patch) {
    return patchProduct(product, patch);
  }
  return product;
}

export function patchProduct<P extends ModelKeys, Q extends ModelKeys>(
  product: P,
  patch: Q
) {
  return {
    ...product,
    ...patch,
  };
}
