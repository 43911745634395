import React, {
  useEffect,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";
import PurchaseProductModal from "./ProductDetailPage/PurchaseProductModal/lazy";
import {
  isClubProtectEligible,
  isNormalProductSoldOut,
} from "../models/product";
import { Product, ConfiguredProduct } from "../models/ProductDetails";
import {
  FormState,
  getFormInitialState,
} from "./ProductDetailPage/PurchaseProductModal/PurchaseProductFormStateHook";
import {
  Cart,
  ReeService,
  SubscribeEnum,
  CustomizableOptionInput,
  getMinClubPointUsed,
  ClubProtectionInput,
} from "../models/cart";
import AddToCartSuccessModal from "./ProductDetailPage/AddToCartSuccessModal.lazy";
import { useFetchProductDetailBySKU } from "../repository/ProductRepository";
import {
  useAddProductToCart,
  useSetClubPointOnCart,
} from "../repository/CartRepository";
import { useCustomer } from "../repository/AuthRepository";
import LoadingModalProvider, {
  LoadingModalContext,
} from "./LoadingModalProvider";
import { withProviders } from "./Provider";
import { ShoppingCartItemCountContext } from "./ShoppingCartItemCountProvider";
import { useModalState } from "../hook/modal";
import { canCustomerSetClubPointOnCart } from "../models/Customer";
import { resolveConfiguredProduct } from "../models/ProductConfigurationDependency";
import { IndexMap } from "../utils/type";
import { addPerformanceRecord } from "../utils/PerformanceRecordStore";
import { CheckoutSession } from "../utils/PerformanceRecordStore/sessions";
import { profiledAsyncActionFn } from "../utils/performance";

import Config from "../Config";

interface AddToCartResultPending {
  type: "pending";
  parameters: AddToCartParameters;
}
interface AddToCartResultSuccess {
  type: "success";
  cart: Cart;
}
type AddToCartResultFailed =
  | { type: "failed"; reason: "PRODUCT_NOT_FOUND" }
  | { type: "failed"; reason: "ERROR"; product?: Product; error: any };
type AddToCartResult =
  | AddToCartResultPending
  | AddToCartResultSuccess
  | AddToCartResultFailed;
function isSuccessResult(
  result: AddToCartResult
): result is AddToCartResultSuccess {
  return result.type === "success";
}

enum AddToCartFailedReason {
  CANCELLED = "CANCELLED",
  PRODUCT_NOT_FOUND = "PRODUCT_NOT_FOUND",
}

interface AddToCartParameters {
  product: Product;
  configuredProduct: ConfiguredProduct | null;
  quantity: number;
  subscribe: SubscribeEnum;
  customizableOptions?: CustomizableOptionInput[];
  options?: {
    ree?: {
      agreement?: boolean;
      service?: ReeService;
    };
    clubprotection?: ClubProtectionInput;
  };
}

interface AddToCartMultipleModalContext {
  presentAddToCartMultipleModal: (
    productSkus: string[],
    skuFormStateMap: IndexMap<string, FormState>
  ) => Promise<unknown>;
}

interface AddToCartSuccessModalProps {
  count: number;
  cart: Cart;
}

export const AddToCartMultipleModalContext = React.createContext<
  AddToCartMultipleModalContext
>(null as any);

const AddToCartMultipleModalProvider_: React.FC = props => {
  const [
    isPurchaseProductModalOpen,
    presentPurchaseProductionModal,
    dismissPurchaseProductionModal,
  ] = useModalState();

  const [
    isSuccessModalOpen,
    presentSuccessModal,
    dismissSuccessModal,
  ] = useModalState();

  const presentPurchaseProductModalReolveRef = useRef<
    ((addToCartParameters: AddToCartParameters) => void) | null
  >(null);
  const presentPurchaseProductModalRejectRef = useRef<
    ((reason?: Error) => void) | null
  >(null);

  const [_product, setProduct] = useState<Product | null>(null);
  const [formState, setFormState] = useState<FormState | null>(null);

  const [
    addToCartSuccessModalState,
    setAddToCartSuccessModalState,
  ] = React.useState<AddToCartSuccessModalProps | null>(null);

  const [, startRequestingWithSku] = useFetchProductDetailBySKU();

  const {
    count: shoppingCartItemCount,
    setCount: setShoppingCartItemCount,
  } = useContext(ShoppingCartItemCountContext);

  const loadingModalContext = React.useContext(LoadingModalContext);

  const prevCartItemCountRef = useRef(shoppingCartItemCount);

  useEffect(() => {
    prevCartItemCountRef.current = shoppingCartItemCount;
  }, [shoppingCartItemCount]);

  const customer = useCustomer();
  const addProductToCart = useAddProductToCart();
  const setClubPointOnCart = useSetClubPointOnCart();

  const handlePurchaseProductModalAddToCartParameters = useCallback(
    async (
      product: Product,
      configuredProduct: ConfiguredProduct | null,
      quantity: number,
      subscribe: SubscribeEnum,
      customizableOptions?: CustomizableOptionInput[],
      options?: {
        ree?: {
          agreement?: boolean;
          service?: ReeService;
        };
        clubprotection?: ClubProtectionInput;
      }
    ) => {
      if (presentPurchaseProductModalReolveRef.current) {
        presentPurchaseProductModalReolveRef.current({
          product,
          configuredProduct,
          quantity,
          subscribe,
          customizableOptions,
          options,
        });
      }
      dismissPurchaseProductionModal();
    },
    [dismissPurchaseProductionModal]
  );

  const getAddProductToCartParameters = useCallback(
    async (
      sku: string,
      _formState: FormState
    ): Promise<AddToCartParameters> => {
      let productResult: Product | null = null;
      try {
        loadingModalContext.show();
        productResult = await profiledAsyncActionFn(
          CheckoutSession(),
          `- Add To Cart fetchProduct (sku=${sku})`,
          startRequestingWithSku
        )(sku);
      } finally {
        loadingModalContext.hide();
      }
      if (productResult == null) {
        throw new Error(AddToCartFailedReason.PRODUCT_NOT_FOUND);
      }
      const {
        enableDisclaimer,
        enableAgeDeclaration,
        recurringConfiguration,
        enableClubProtection,
        priceRange,
      } = productResult;
      const configuredProduct = resolveConfiguredProduct(
        productResult,
        _formState.configurationOptionValue
      );
      const shouldShowPurchaseForm =
        (productResult.type === "configurable" && !configuredProduct) ||
        enableAgeDeclaration ||
        enableDisclaimer ||
        (enableClubProtection != null && priceRange != null
          ? isClubProtectEligible(
              customer,
              enableClubProtection,
              priceRange.minimumPrice.finalPrice.value
            )
          : false) ||
        (productResult.customizableOptions &&
          productResult.customizableOptions.length > 0) ||
        (Config.ENABLE_SUBSCRIPTION &&
          recurringConfiguration &&
          recurringConfiguration.isRecurringEnable);
      if (!shouldShowPurchaseForm) {
        return {
          product: productResult,
          configuredProduct: configuredProduct,
          quantity: _formState.quantity,
          subscribe: SubscribeEnum.notSubscribe,
        };
      }
      setProduct(productResult);
      const addToCartParameters = await new Promise<AddToCartParameters>(
        (resolve, reject) => {
          presentPurchaseProductionModal();
          presentPurchaseProductModalReolveRef.current = resolve;
          presentPurchaseProductModalRejectRef.current = reject;
        }
      );
      presentPurchaseProductModalReolveRef.current = null;
      presentPurchaseProductModalRejectRef.current = null;
      return addToCartParameters;
    },
    [
      loadingModalContext,
      startRequestingWithSku,
      presentPurchaseProductionModal,
      customer,
    ]
  );

  const presentAddToCartMultipleModal = React.useCallback(
    async (
      skus: string[],
      skuFormStateMap: IndexMap<string, FormState>
    ): Promise<void> => {
      addPerformanceRecord(
        CheckoutSession(),
        `Checkout show add to cart form (skus=${skus.join(",")})`
      );
      const pendingAddToCartResults: AddToCartResult[] = [];
      for (let i = 0; i < skus.length; i++) {
        const sku = skus[i];
        const _formState = skuFormStateMap[sku] || getFormInitialState();
        setFormState(_formState);
        try {
          // eslint-disable-next-line no-await-in-loop
          pendingAddToCartResults.push({
            type: "pending",
            parameters: await getAddProductToCartParameters(sku, _formState),
          });
        } catch (e) {
          if (e) {
            if (e.message === AddToCartFailedReason.CANCELLED) {
              // Terminate add to cart process
              return;
            }
            if (e.message === AddToCartFailedReason.PRODUCT_NOT_FOUND) {
              pendingAddToCartResults.push({
                type: "failed",
                reason: "PRODUCT_NOT_FOUND",
              });
              continue;
            }
            pendingAddToCartResults.push({
              type: "failed",
              reason: "ERROR",
              error: e,
            });
          }
        }
      }

      loadingModalContext.show();
      const finalAddToCartResults: Exclude<
        AddToCartResult,
        { type: "pending" }
      >[] = [];
      for (const addToCartResult of pendingAddToCartResults) {
        if (addToCartResult.type !== "pending") {
          finalAddToCartResults.push(addToCartResult);
          continue;
        }
        try {
          // eslint-disable-next-line no-await-in-loop
          finalAddToCartResults.push({
            type: "success",
            cart: await profiledAsyncActionFn(
              CheckoutSession(),
              `- Add To Cart addProductToCart API (sku=${addToCartResult.parameters.product.sku})`,
              addProductToCart
            )(
              addToCartResult.parameters.product,
              addToCartResult.parameters.configuredProduct,
              addToCartResult.parameters.quantity,
              addToCartResult.parameters.subscribe,
              undefined,
              addToCartResult.parameters.customizableOptions,
              addToCartResult.parameters.options
            ),
          });
        } catch (e) {
          finalAddToCartResults.push({
            type: "failed",
            reason: "ERROR",
            product: addToCartResult.parameters.product,
            error: e,
          });
        }
      }
      loadingModalContext.hide();

      const lastSuccessResult = [...finalAddToCartResults]
        .reverse()
        .find(isSuccessResult);

      if (lastSuccessResult != null) {
        let { cart } = lastSuccessResult;

        /**
         * Reset club point on cart after changing cart item
         */
        if (
          customer &&
          canCustomerSetClubPointOnCart(customer, getMinClubPointUsed(cart))
        ) {
          loadingModalContext.show();
          const originalCart = cart;
          cart = await setClubPointOnCart(0).catch(() => originalCart);
          loadingModalContext.hide();
        }

        const prevCartItemCount = prevCartItemCountRef.current;
        let count = 0;
        for (const item of cart.items) {
          count += item.quantity;
        }
        setShoppingCartItemCount(count);
        setAddToCartSuccessModalState({
          count: count - prevCartItemCount,
          cart,
        });
        presentSuccessModal();
      }
    },
    [
      customer,
      addProductToCart,
      getAddProductToCartParameters,
      presentSuccessModal,
      setShoppingCartItemCount,
      setClubPointOnCart,
      loadingModalContext,
    ]
  );

  const onPurchaseProductModalRequestDismiss = useCallback(() => {
    dismissPurchaseProductionModal();
    if (presentPurchaseProductModalRejectRef.current) {
      presentPurchaseProductModalRejectRef.current(
        new Error(AddToCartFailedReason.CANCELLED)
      );
    }
  }, [dismissPurchaseProductionModal]);

  const contextValue = React.useMemo(
    () => ({
      presentAddToCartMultipleModal,
    }),
    [presentAddToCartMultipleModal]
  );

  return (
    <>
      <AddToCartMultipleModalContext.Provider value={contextValue}>
        {props.children}
      </AddToCartMultipleModalContext.Provider>
      {_product != null && formState != null && (
        <PurchaseProductModal
          product={_product}
          formInitialState={formState}
          isOpen={isPurchaseProductModalOpen}
          onRequestDismiss={onPurchaseProductModalRequestDismiss}
          onCloseClick={onPurchaseProductModalRequestDismiss}
          onProductAddToCart={handlePurchaseProductModalAddToCartParameters}
          isSoldOutFunction={isNormalProductSoldOut}
        />
      )}
      {addToCartSuccessModalState != null && (
        <AddToCartSuccessModal
          itemsAddedCount={addToCartSuccessModalState.count}
          cart={addToCartSuccessModalState.cart}
          isOpen={isSuccessModalOpen}
          onRequestDismiss={dismissSuccessModal}
        />
      )}
    </>
  );
};

export const AddToCartMultipleModalProvider = withProviders(
  AddToCartMultipleModalProvider_,
  LoadingModalProvider
);
