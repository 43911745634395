import { useApolloClient } from "@apollo/client";
import React, { useMemo } from "react";
import { useRouteMatch } from "react-router-dom";
import ProductDetailPage from ".";
import {
  fetchCampaignProductBySKU,
  getCampaignProductBySKU,
} from "../../api/Campaign";
import {
  fetchProductSaleBundles,
  fetchProductSaleBundlesForProductSKUOnly,
} from "../../api/ProductSaleBundle";
import Config from "../../Config";
import { ProfileSessionProvider } from "../../contexts/ProfileSessionContext";
import { usePresentAddToCartModal } from "../../hook/usePresentAddToCartModal";
import { useIntl } from "../../i18n/Localization";
import {
  isCampaignProductSoldOut,
  ModelKeys,
  shouldCampaignProductShowPrice,
} from "../../models/product";
import { Product } from "../../models/ProductDetails";
import { ProductSaleBundle } from "../../models/ProductSaleBundle";
import { ResourcesRequestState } from "../../models/ResourcesRequestState";
import { useFetchResources_v2 } from "../../repository/Hooks";
import { useMemoryCampaignProductDetail } from "../../repository/ProductRepository";
import useCLIonLifeCycleContext from "../../utils/CLIonLifeCycleContext";
import { profileAsyncAction, profileSyncAction } from "../../utils/performance";
import { ProductDetailPageSession } from "../../utils/PerformanceRecordStore/sessions";
import { mapNullable } from "../../utils/type";
import { usePurchaseProductFormStateReducer } from "./PurchaseProductModal/PurchaseProductFormStateHook";
import { PageResource } from "./type";

interface RouteParams {
  campaignId: string;
  sku: string;
}

function usePageResource(
  campaignId: number,
  sku: string
): {
  requestState: ResourcesRequestState<PageResource | null>;
  startRequesting: () => Promise<PageResource | null>;
  refresh: () => Promise<PageResource | null>;
} {
  const client = useApolloClient();
  const { locale } = useIntl();

  const [requestState, { call: fetch, refresh }] = useFetchResources_v2<
    PageResource | null,
    () => Promise<PageResource | null>
  >({
    localCacheProvider: async () => {
      const product = profileSyncAction(
        ProductDetailPageSession(sku),
        "Load Product Detail from Cache",
        () => getCampaignProductBySKU(client, campaignId, sku)
      );
      if (product) {
        const relatedProductIds = product.relatedProducts
          ? product.relatedProducts.map(p => p.id)
          : [];
        const [bundle, relatedProductBundles] = Config.ENABLE_BUNDLE_SALE
          ? await Promise.all<
              ProductSaleBundle<Product> | null,
              ProductSaleBundle<ModelKeys>[]
            >([
              fetchProductSaleBundles(
                client,
                product.id,
                locale,
                "cache-only"
              ).catch(() => null),
              fetchProductSaleBundlesForProductSKUOnly(
                client,
                relatedProductIds,
                locale,
                "cache-only"
              ).catch(() => []),
            ])
          : [null, []];
        return {
          product,
          bundle,
          relatedProductBundleProductIdMap: relatedProductBundles.reduce(
            (prev, curr) =>
              mapNullable(curr.mainProduct, p => ({ ...prev, [p.id]: curr })) ||
              prev,
            {}
          ),
        };
      }
      return null;
    },
    remoteResourcesProvider: async () => {
      const product = await profileAsyncAction(
        ProductDetailPageSession(sku),
        "Load Product Detail from Network",
        () =>
          fetchCampaignProductBySKU(
            client,
            campaignId,
            sku,
            locale,
            "network-only"
          )
      );
      if (product) {
        const relatedProductIds = product.relatedProducts
          ? product.relatedProducts.map(p => p.id)
          : [];
        const [bundle, relatedProductBundles] = Config.ENABLE_BUNDLE_SALE
          ? await Promise.all<
              ProductSaleBundle<Product> | null,
              ProductSaleBundle<ModelKeys>[]
            >([
              fetchProductSaleBundles(
                client,
                product.id,
                locale,
                "network-only"
              ).catch(() => null),
              fetchProductSaleBundlesForProductSKUOnly(
                client,
                relatedProductIds,
                locale,
                "network-only"
              ).catch(() => []),
            ])
          : [null, []];
        return {
          product,
          bundle,
          relatedProductBundleProductIdMap: relatedProductBundles.reduce(
            (prev, curr) =>
              mapNullable(curr.mainProduct, p => ({ ...prev, [p.id]: curr })) ||
              prev,
            {}
          ),
        };
      }
      return null;
    },
  });

  return {
    requestState,
    startRequesting: fetch,
    refresh,
  };
}

const CampaignProductDetailPageImpl: React.FC = () => {
  const match = useRouteMatch<RouteParams>();
  const { sku, campaignId: _campaignId } = match.params;
  const campaignId = useMemo(() => parseInt(_campaignId, 10), [_campaignId]);

  const ionLifeCycleContext = useCLIonLifeCycleContext();
  const productDetailInMemory = useMemoryCampaignProductDetail(campaignId, sku);

  const {
    requestState: productResourcesState,
    startRequesting: startRequestingProduct,
    refresh: refreshProduct,
  } = usePageResource(campaignId, sku);

  const purchaseProductFormStateReducer = usePurchaseProductFormStateReducer();
  const configProductFormState = purchaseProductFormStateReducer.formState;
  const presentAddToCartModal = usePresentAddToCartModal();
  const openPurchaseProductModal = React.useCallback(() => {
    presentAddToCartModal(sku, configProductFormState, {
      campaignId,
      addToCartCallback: result => {
        if (result === "OUT OF STOCK") {
          refreshProduct();
        }
      },
    });
  }, [
    presentAddToCartModal,
    configProductFormState,
    sku,
    campaignId,
    refreshProduct,
  ]);

  return (
    <ProductDetailPage
      sku={sku}
      productDetailInMemory={productDetailInMemory}
      pageResourceState={productResourcesState}
      startRequestingPage={startRequestingProduct}
      refreshPage={refreshProduct}
      ionLifeCycleContext={ionLifeCycleContext}
      purchaseProductFormStateReducer={purchaseProductFormStateReducer}
      onAddToCartClick={openPurchaseProductModal}
      allowShare={false}
      isSoldOut={isCampaignProductSoldOut}
      shouldShowPrice={shouldCampaignProductShowPrice}
    />
  );
};

const CampaignProductDetailPage: React.FC = () => {
  const match = useRouteMatch<RouteParams>();
  const { sku } = match.params;
  return (
    <ProfileSessionProvider profileSession={ProductDetailPageSession(sku)}>
      <CampaignProductDetailPageImpl />
    </ProfileSessionProvider>
  );
};

export default CampaignProductDetailPage;
