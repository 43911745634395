import {
  ProductType,
  ProductImage,
  CLClubPoint,
  PriceRange,
  Product360Image,
  ProductStockStatus,
  ThirdPartyProductDisplayType,
  ProductConfigurableAttributeOption,
  Product360ImageGraphQLAttributes,
  ProductDeliveryMethod,
  ProductConfigurableOption,
  RemoteClubTierQuota,
  ProductSpec,
  ProductCustomizationOption,
  ProductLink,
  ProductTodoAttribute_Number,
  RecurringConfiguration,
  ClubTierQuota,
  Quota,
  RemoteRecurringConfiguration,
  ProductConfigurableOptionGraphQLAttributes,
  ProductCustomizationOptionInterfaceGraphQLAttributes,
  ProductCustomizationOptionTextAreaGraphQLAttributes,
  ProductCustomizationOptionDateGraphQLAttributes,
  ProductCustomizationOptionDropDownGraphQLAttributes,
  ProductCustomizationOptionMultipleSelectGraphQLAttributes,
  ProductCustomizableOptionTextFieldGraphQLAttributes,
  ProductCustomizableOptionFileGraphQLAttributes,
  ProductCustomizableOptionRadioGraphQLAttributes,
  ProductCustomizableOptionCheckboxGraphQLAttributes,
  ProductImageGraphQLAttributes,
  keyGraphQLAttributes,
  ModelKeys,
  ProductThirdPartyProductShowPriceType,
  RemoteProductOverviewBaseClubTierQuota,
  ProductEnableAgeDeclaration,
  VariantProductClubTierQuota,
  VariantProductEnableAgeDeclaration,
  patchProduct,
  transformRemoteClubTierQuotaToClubTierQuota,
  transformRemoteRecurringConfigurationToRecurringConfiguration,
  transformRemoteEstimatedDeliveryDate,
  augmentProductWithNotImplementedAttribute,
  ProductInstalmentType,
  ProductVariantProduct,
  ProductEstimatedDeliveryDate,
  RemoteProductEstimatedDeliveryDate,
  ProductIsPreOrder,
  VariantProductIsPreOrder,
  RemoteVariantProductEstimatedDeliveryDate,
} from "./product";
import { MediaContent, MediaContentGraphQLAttributes } from "./Media";
import { MerchantPreview, MerchantPreviewGraphQLAttributes } from "./Merchant";
import { MoneyGraphQLAttributes } from "./Price";
import { fromMaybe, indexMapBy, mapNullable, Override } from "../utils/type";
import { HTMLText, HTMLTextGrapQLAttributes } from "./HTMLText";

import {
  ProductOverview,
  ProductOverviewGraphQLAttributes,
} from "./ProductOverview";

export type Product = ModelKeys & {
  name: string;
  type: ProductType;
  thumbnail: ProductImage | null;
  image: ProductImage | null;
  clubPoint: number;
  minClubPoint: number;
  extraClubpoints: number | null;
  clClubPoint?: CLClubPoint | null;
  priceRange: PriceRange | null;
  specialFromDateStr: string | null;
  specialToDateStr: string | null;
  newFromDateStr: string | null;
  newToDateStr: string | null;

  mediaContents: MediaContent[];
  magic360Images: Product360Image[];
  merchant: [MerchantPreview | null];

  rating: number;

  stockStatus: ProductStockStatus;

  enableDisclaimer: boolean;
  isDisclaimerRequired: boolean;

  enableAgeDeclaration?: boolean;

  manufacturerSuggestedRetailPrice?: string | null;

  configurableOptions?: ProductConfigurableOption[] | null;
  variants?: ProductVariant[] | null;

  displayType?: ThirdPartyProductDisplayType | null;
  buttonUrl?: string | null;
  infoMessage?: string | null;

  shortDescription: HTMLText;
  longDescription: HTMLText;
  specs: ProductSpec[];

  // ---

  deliveryMethod: ProductDeliveryMethod | null;
  deliveryMethodLabel?: string | null;
  deliveryMethodBlockIdentifier: string | null;

  reviewCount: number;
  productLinks: ProductLink[] | null;
  relatedProducts: ProductOverview[] | null;
  urlKey: string;

  customizableOptions: (ProductCustomizationOption | null)[] | null;
  recurringConfiguration: RecurringConfiguration | null;

  // ---

  qAndACount: ProductTodoAttribute_Number;
  likeCount: ProductTodoAttribute_Number;

  clubTierQuota?: ClubTierQuota | null;
  quota?: Quota | null;
  memberQuota?: Quota | null;

  enableClubProtection?: boolean | null;

  isFreeShipping: boolean | null;
} & ProductThirdPartyProductShowPriceType &
  ProductInstalmentType &
  ProductEstimatedDeliveryDate &
  ProductIsPreOrder;

export type RemoteProduct = Override<
  Product,
  RemoteProductEstimatedDeliveryDate & {
    recurringConfiguration: RemoteRecurringConfiguration | null;
    clubTierQuota?: RemoteClubTierQuota | null;

    variants?: RemoteProductVariant[] | null;
  }
>;

export interface ProductVariant {
  product: ConfiguredProduct;
  attributes: ProductConfigurableAttributeOption[];
}

export interface RemoteProductVariant {
  product: RemoteConfiguredProduct;
  attributes: ProductConfigurableAttributeOption[];
}

export type RemoteConfiguredProduct = Override<
  ConfiguredProduct,
  RemoteProductEstimatedDeliveryDate & {
    clubTierQuota?: RemoteClubTierQuota | null;
  }
>;

export type ConfiguredProduct = ModelKeys & {
  name: string;
  type: ProductType;
  thumbnail: ProductImage | null;
  image: ProductImage | null;
  clubPoint: number;
  minClubPoint: number;
  extraClubpoints: number | null;
  clClubPoint?: CLClubPoint | null;
  priceRange: PriceRange | null;
  specialFromDateStr: string | null;
  specialToDateStr: string | null;
  newFromDateStr: string | null;
  newToDateStr: string | null;

  mediaContents: MediaContent[];
  magic360Images: Product360Image[];
  merchant: [MerchantPreview | null];

  rating: number;

  stockStatus: ProductStockStatus;
  manufacturerSuggestedRetailPrice?: string | null;

  shortDescription: HTMLText;
  longDescription: HTMLText;

  specs: ProductSpec[];

  deliveryMethod: ProductDeliveryMethod | null;
  deliveryMethodLabel?: string | null;
  deliveryMethodBlockIdentifier: string | null;

  enableDisclaimer: boolean;
  isDisclaimerRequired: boolean;

  enableAgeDeclaration?: boolean;

  displayType?: ThirdPartyProductDisplayType | null;
  buttonUrl?: string | null;
  infoMessage?: string | null;
  clubTierQuota?: ClubTierQuota | null;
  quota?: Quota | null;
  memberQuota?: Quota | null;

  enableClubProtection?: boolean | null;
  isPreOrder?: boolean | null;

  isFreeShipping: boolean | null;
} & ProductThirdPartyProductShowPriceType &
  ProductInstalmentType &
  ProductEstimatedDeliveryDate &
  ProductIsPreOrder;

export const ProductVariantGrapQLAttributes = `
  product {
    ${keyGraphQLAttributes}
    name
    type: type_id
    thumbnail {
      ${ProductImageGraphQLAttributes}
    }
    image {
      ${ProductImageGraphQLAttributes}
    }
    clubPoint: clubpoints
    minClubPoint: min_clubpoints
    extraClubpoints: extra_clubpoints
    clClubPoint: cl_clubpoints
    priceRange: price_range {
      minimumPrice: minimum_price {
        regularPrice: regular_price {
          ${MoneyGraphQLAttributes}
        }
        finalPrice: final_price {
          ${MoneyGraphQLAttributes}
        }
      }
      maximumPrice: maximum_price {
        regularPrice: regular_price {
          ${MoneyGraphQLAttributes}
        }
        finalPrice: final_price {
          ${MoneyGraphQLAttributes}
        }
      }
    }
    specialFromDateStr: special_from_date
    specialToDateStr: special_to_date
    newFromDateStr: new_from_date
    newToDateStr: new_to_date
    mediaContents: media_gallery_entries {
      ${MediaContentGraphQLAttributes}
    }
    magic360Images: magic360_image {
      ${Product360ImageGraphQLAttributes}
    }
    merchant {
      ${MerchantPreviewGraphQLAttributes}
    }
    rating
    stockStatus: stock_status
    manufacturerSuggestedRetailPrice: pro_msrp

    shortDescription: short_description {
      ${HTMLTextGrapQLAttributes}
    }
    longDescription: description {
      ${HTMLTextGrapQLAttributes}
    }
    specs: custom_attributes {
      label
      value
    }
    enableClubProtection: enable_club_protection

    isFreeShipping: is_free_shipping
  }
  attributes {
    label
    value: value_index
    code
  }
`;

export type RemoteProductBase = Pick<
  RemoteProduct,
  | "id"
  | "entityId"
  | "sku"
  | "name"
  | "type"
  | "thumbnail"
  | "image"
  | "clubPoint"
  | "minClubPoint"
  | "extraClubpoints"
  | "clClubPoint"
  | "priceRange"
  | "specialFromDateStr"
  | "specialToDateStr"
  | "newFromDateStr"
  | "newToDateStr"
  | "mediaContents"
  | "magic360Images"
  | "merchant"
  | "rating"
  | "stockStatus"
  | "enableDisclaimer"
  | "isDisclaimerRequired"
  | "manufacturerSuggestedRetailPrice"
  | "configurableOptions"
  | "variants"
  | "shortDescription"
  | "longDescription"
  | "specs"
  | "displayType"
  | "buttonUrl"
  | "infoMessage"
  | "isFreeShipping"
>;

export const ProductBaseGraphQLAttributes = `
  ${keyGraphQLAttributes}
  name
  type: type_id
  thumbnail {
    ${ProductImageGraphQLAttributes}
  }
  image {
    ${ProductImageGraphQLAttributes}
  }
  clubPoint: clubpoints
  minClubPoint: min_clubpoints
  extraClubpoints: extra_clubpoints
  clClubPoint: cl_clubpoints
  priceRange: price_range {
    minimumPrice: minimum_price {
      regularPrice: regular_price {
        ${MoneyGraphQLAttributes}
      }
      finalPrice: final_price {
        ${MoneyGraphQLAttributes}
      }
    }
    maximumPrice: maximum_price {
      regularPrice: regular_price {
        ${MoneyGraphQLAttributes}
      }
      finalPrice: final_price {
        ${MoneyGraphQLAttributes}
      }
    }
  }
  specialFromDateStr: special_from_date
  specialToDateStr: special_to_date
  newFromDateStr: new_from_date
  newToDateStr: new_to_date
  mediaContents: media_gallery_entries {
    ${MediaContentGraphQLAttributes}
  }
  magic360Images: magic360_image {
    ${Product360ImageGraphQLAttributes}
  }
  merchant {
    ${MerchantPreviewGraphQLAttributes}
  }
  rating
  stockStatus: stock_status
  enableDisclaimer: enable_disclaimer
  isDisclaimerRequired: is_disclaimer_required
  manufacturerSuggestedRetailPrice: pro_msrp

  isFreeShipping: is_free_shipping

  ... on ConfigurableProduct {
    configurableOptions: configurable_options {
      ${ProductConfigurableOptionGraphQLAttributes}
    }
    variants {
      ${ProductVariantGrapQLAttributes}
    }
  }
  ... on ThirdPartyProduct {
    displayType: display_type
    buttonUrl: button_url
    infoMessage: info_message
  }

  shortDescription: short_description {
    ${HTMLTextGrapQLAttributes}
  }
  longDescription: description {
    ${HTMLTextGrapQLAttributes}
  }
  specs: custom_attributes {
    label
    value
  }
  enableClubProtection: enable_club_protection
`;

const RecurringConfigurationGraphQLAttributes = `
isRecurringEnable: is_recurring_enable
isSubscriptionOnly: is_subscription_only
billingCycle: billing_cycle
isEnableTrial: is_enable_trial
isFreeShipping: is_free_shipping
startDate: start_date
endDate: end_date
`;

export type RemoteProductAdditional = Pick<
  RemoteProduct,
  | keyof ModelKeys
  | "deliveryMethod"
  | "deliveryMethodBlockIdentifier"
  | "reviewCount"
  | "stockStatus"
  | "productLinks"
  | "urlKey"
  | "customizableOptions"
  | "recurringConfiguration"
>;

export const ProductDetailsAdditionalGraphQLAttributes = `
  ${keyGraphQLAttributes}
  deliveryMethod: delivery_method
  deliveryMethodLabel: delivery_method_label
  deliveryMethodBlockIdentifier: delivery_method_block_identifier
  reviewCount: review_count
  productLinks: product_links {
    linkType: link_type
    linkedProductSKU: linked_product_sku
  }
  urlKey: url_key
  ... on CustomizableProductInterface {
    customizableOptions: options {
      ${ProductCustomizationOptionInterfaceGraphQLAttributes}
      ... on CustomizableAreaOption {
        ${ProductCustomizationOptionTextAreaGraphQLAttributes}
      }
      ... on CustomizableDateOption {
        ${ProductCustomizationOptionDateGraphQLAttributes}
      }
      ... on CustomizableDropDownOption {
        ${ProductCustomizationOptionDropDownGraphQLAttributes}
      }
      ... on CustomizableMultipleOption {
        ${ProductCustomizationOptionMultipleSelectGraphQLAttributes}
      }
      ... on CustomizableFieldOption {
        ${ProductCustomizableOptionTextFieldGraphQLAttributes}
      }
      ... on CustomizableFileOption {
        ${ProductCustomizableOptionFileGraphQLAttributes}
      }
      ... on CustomizableRadioOption {
        ${ProductCustomizableOptionRadioGraphQLAttributes}
      }
      ... on CustomizableCheckboxOption {
        ${ProductCustomizableOptionCheckboxGraphQLAttributes}
      }
    }
  }
  recurringConfiguration: recurring_configuration {
    ${RecurringConfigurationGraphQLAttributes}
  }
`;

export type ProductRelatedProducts = Pick<
  Product,
  keyof ModelKeys | "relatedProducts"
>;

export const ProductDetailsRelatedProductsGraphQLAttributes = `
  ${keyGraphQLAttributes}
  relatedProducts: related_products {
    ${ProductOverviewGraphQLAttributes}
  }
`;

export function assembleProduct(
  base: RemoteProductBase,
  additional: RemoteProductAdditional,
  relatedProducts: ProductRelatedProducts | null,
  clubTierQuotaData: RemoteProductOverviewBaseClubTierQuota | null,
  enableAgeDeclarationData: ProductEnableAgeDeclaration | null,
  variantProductClubTierQuota: VariantProductClubTierQuota | null,
  variantEnableAgeDeclarationData: VariantProductEnableAgeDeclaration | null,
  thirdPartyProductShowPrice: ProductThirdPartyProductShowPriceType | null,
  instalment: ProductInstalmentType | null,
  variantInstalment: ProductVariantProduct<ProductInstalmentType> | null,
  isPreOrderData: ProductIsPreOrder | null,
  variantIsPreOrderData: VariantProductIsPreOrder | null,
  estimatedDeliveryDateData: RemoteProductEstimatedDeliveryDate | null,
  variantEstimatedDeliveryDateData: RemoteVariantProductEstimatedDeliveryDate | null
): Product {
  let _product: Omit<RemoteProduct, "qAndACount" | "likeCount"> = {
    ...base,
    ...additional,
    ...(relatedProducts
      ? {
          relatedProducts: relatedProducts.relatedProducts,
        }
      : { relatedProducts: null }),
    ...(clubTierQuotaData
      ? {
          clubTierQuota: clubTierQuotaData.clubTierQuota,
          quota: clubTierQuotaData.quota,
          memberQuota: clubTierQuotaData.memberQuota,
        }
      : {}),
    ...(enableAgeDeclarationData
      ? {
          enableAgeDeclaration: enableAgeDeclarationData.enableAgeDeclaration,
        }
      : {}),
    ...instalment,
    ...isPreOrderData,
  };

  if (thirdPartyProductShowPrice) {
    _product = patchProduct(_product, thirdPartyProductShowPrice);
  }

  const variantProductClubTierQuotaMap: {
    [key in number]: {
      clubTierQuota: ClubTierQuota | null;
      quota: Quota | null;
      memberQuota: Quota | null;
    };
  } = {};
  if (variantProductClubTierQuota) {
    const { variants } = variantProductClubTierQuota;
    if (variants != null) {
      for (const { product } of variants) {
        const { id, clubTierQuota, quota, memberQuota } = product;
        variantProductClubTierQuotaMap[id] = {
          clubTierQuota: clubTierQuota
            ? transformRemoteClubTierQuotaToClubTierQuota(clubTierQuota)
            : null,
          quota: quota || null,
          memberQuota: memberQuota || null,
        };
      }
    }
  }

  const variantEnableAgeDeclarationMap: {
    [key in number]: {
      enableAgeDeclaration?: boolean;
    };
  } = {};
  if (variantEnableAgeDeclarationData) {
    const { variants } = variantEnableAgeDeclarationData;
    if (variants != null) {
      for (const { product } of variants) {
        const { id, enableAgeDeclaration } = product;
        variantEnableAgeDeclarationMap[id] = {
          enableAgeDeclaration,
        };
      }
    }
  }

  const recurringConfiguration: RecurringConfiguration | null = _product.recurringConfiguration
    ? transformRemoteRecurringConfigurationToRecurringConfiguration(
        _product.recurringConfiguration
      )
    : null;

  const variantInstalmentMap =
    variantInstalment && variantInstalment.variants
      ? indexMapBy(p => p.id, variantInstalment.variants.map(v => v.product))
      : {};

  const variantIsPreOrderMap = fromMaybe(
    {},
    mapNullable(variantIsPreOrderData, x =>
      mapNullable(x.variants, variants =>
        indexMapBy(p => p.id, variants.map(v => v.product))
      )
    )
  );

  const variantEstimatedDeliveryDateMap = fromMaybe(
    {},
    mapNullable(variantEstimatedDeliveryDateData, x =>
      mapNullable(x.variants, variants =>
        indexMapBy(p => p.id, variants.map(p => p.product))
      )
    )
  );

  return augmentProductWithNotImplementedAttribute({
    ..._product,
    recurringConfiguration,
    estimatedDeliveryDate: mapNullable(estimatedDeliveryDateData, e =>
      mapNullable(e.estimatedDeliveryDate, estimatedDeliveryDate =>
        transformRemoteEstimatedDeliveryDate(estimatedDeliveryDate)
      )
    ),
    variants:
      _product.variants == null
        ? null
        : _product.variants
            // variants with null product are disabled from server
            .filter(v => v.product != null)
            .map(v => ({
              ...v,
              product: augmentProductWithNotImplementedAttribute({
                ...v.product,
                ...variantProductClubTierQuotaMap[v.product.id],
                ...variantEnableAgeDeclarationMap[v.product.id],
                ...variantInstalmentMap[v.product.id],
                ...variantIsPreOrderMap[v.product.id],
                estimatedDeliveryDate: mapNullable(
                  variantEstimatedDeliveryDateMap[v.product.id],
                  p =>
                    mapNullable(
                      p.estimatedDeliveryDate,
                      estimatedDeliveryDate =>
                        transformRemoteEstimatedDeliveryDate(
                          estimatedDeliveryDate
                        )
                    )
                ),
              }),
            })),
    clubTierQuota: _product.clubTierQuota
      ? transformRemoteClubTierQuotaToClubTierQuota(_product.clubTierQuota)
      : null,
  });
}
