import { useCallback, useContext } from "react";
import {
  AddToCartCallback,
  AddToCartModalContext,
} from "../components/AddToCartModalProvider";
import {
  AddToCartFinalResult,
  AddToCartModalContext as AddToCartModalContextV2,
} from "../components/AddToCartModalProviderV2";
import { AddToCartMultipleModalContext } from "../components/AddToCartMultipleModalProvider";
import { FormState } from "../components/ProductDetailPage/PurchaseProductModal/PurchaseProductFormStateHook";
import Config from "../Config";
import { OrderItem } from "../models/Order";
import { IndexMap } from "../utils/type";

export function usePresentAddToCartModal() {
  const addToCartModalContext = useContext(AddToCartModalContext);
  const addToCartModalContextV2 = useContext(AddToCartModalContextV2);

  return useCallback(
    (
      productSku: string,
      formInitialState: FormState,
      options?: {
        campaignId?: number;
        addToCartCallback?: AddToCartCallback | undefined;
        onAddToCartFinalResult?: (
          addToCartFinalResult: AddToCartFinalResult | undefined
        ) => void;
      }
    ) => {
      if (Config.DEVELOPMENT_ENABLE_REFACTOR_ADD_TO_CART) {
        addToCartModalContextV2
          .presentAddToCartModal(productSku, formInitialState, options)
          .then(options ? options.onAddToCartFinalResult : undefined);
      } else {
        addToCartModalContext.presentAddToCartModal(
          productSku,
          formInitialState,
          options
        );
      }
    },
    [addToCartModalContextV2, addToCartModalContext]
  );
}

export function usePresentAddToCartModalWithOrderItem() {
  const addToCartModalContext = useContext(AddToCartModalContext);
  const addToCartModalContextV2 = useContext(AddToCartModalContextV2);

  return useCallback(
    (
      orderItem: OrderItem,
      options?: {
        onAddToCartFinalResult?: (
          addToCartFinalResult: AddToCartFinalResult | undefined
        ) => void;
      }
    ) => {
      if (Config.DEVELOPMENT_ENABLE_REFACTOR_ADD_TO_CART) {
        addToCartModalContextV2
          .presentAddToCartModalWithOrderItem(orderItem)
          .then(options ? options.onAddToCartFinalResult : undefined);
      } else {
        addToCartModalContext.presentAddToCartModalWithOrderItem(orderItem);
      }
    },
    [addToCartModalContextV2, addToCartModalContext]
  );
}

export function usePresentAddToCartMultipleModal() {
  const addToCartMultipleModalContext = useContext(
    AddToCartMultipleModalContext
  );
  const addToCartModalContextV2 = useContext(AddToCartModalContextV2);

  return useCallback(
    (
      productSkus: string[],
      skuFormStateMap: IndexMap<string, FormState>,
      options?: {
        onAddToCartFinalResults?: (
          addToCartFinalResults: AddToCartFinalResult[]
        ) => void;
      }
    ) => {
      if (Config.DEVELOPMENT_ENABLE_REFACTOR_ADD_TO_CART) {
        addToCartModalContextV2
          .presentAddToCartMultipleModal(productSkus, skuFormStateMap)
          .then(options ? options.onAddToCartFinalResults : undefined);
      } else {
        addToCartMultipleModalContext.presentAddToCartMultipleModal(
          productSkus,
          skuFormStateMap
        );
      }
    },
    [addToCartMultipleModalContext, addToCartModalContextV2]
  );
}
