import cn from "classnames";
import React, { useCallback, useMemo, useState } from "react";
import { IonIcon } from "@ionic/react";
import produce from "immer";
import numeral from "numeral";

import {
  getConfiguredProductOfLowestPrice,
  getConfiguredProductOfHighestPrice,
  PriceRange,
  ProductType,
  ModelKeys,
} from "../../models/product";
import {
  ProductSaleBundleProduct,
  ProductSaleBundleItem,
  calculateTotalDiscount,
  calculateTotalPrice,
  ApplyCondition,
  DiscountType,
} from "../../models/ProductSaleBundle";
import { formatPriceValue } from "../../models/Price";
import { getClubPointConversionRate } from "../../models/AppConfig";
import { ConfiguredProduct, ProductVariant } from "../../models/ProductDetails";
import { useAppConfig } from "../../repository/ConfigRepository";
import { LocalizedText } from "../../i18n/Localization";

import Checkbox, { CheckState } from "./Checkbox";
import { PrimaryButton } from "../Button";
import { getListedMoney } from "../ProductDetailPage/ProductPriceViewUtils";
import {
  FormState,
  getFormInitialState,
} from "../ProductDetailPage/PurchaseProductModal/PurchaseProductFormStateHook";
import { ConfigProductFormModal } from "../ProductDetailPage/ProductConfigurationOptionsPanel";

import ProductPriceView from "./ProductPriceView";

import styles from "./styles.module.scss";

import { ConfiguredProductOverview } from "../../models/ProductOverview";
import { IndexMap } from "../../utils/type";
import { resolveConfiguredProductBySelectedConfiguration } from "../../models/ProductConfigurationDependency";
import ProductConfigurationForm from "./ProductConfigurationForm";
import ProductImageList from "./ProductImageList";
import Accordion from "./Accordion";

const formInitialState = { ...getFormInitialState() };

type SkuFormStateMap = IndexMap<ProductSaleBundleProduct["sku"], FormState>;

type DisplayItemType =
  | {
      type: "simple";
      simpleProduct: ProductSaleBundleProduct;
      qty: number;
      discountAmount: number;
    }
  | {
      type: "configured";
      configurableProduct: ProductSaleBundleProduct;
      configuredProduct: ConfiguredProduct;
      qty: number;
      discountAmount: number;
    }
  | {
      type: "configurable";
      configurableProduct: ProductSaleBundleProduct;
      qty: number;
      discountAmount: number;
    };

interface Props {
  mainProduct: ProductSaleBundleProduct | ConfiguredProduct;
  bundleDetails: ProductSaleBundleItem<ProductSaleBundleProduct>;
  onClickProduct: (product: ModelKeys) => void;
  onAddToCartClick: (
    mainProduct: ModelKeys,
    bundleDetails: ProductSaleBundleItem<ModelKeys>,
    itemsSelected: { [key in number]: boolean },
    selectedConfigurableOptionsMap: IndexMap<string, FormState>
  ) => void;
}

function prefillSkuFormStateMap(
  bundleDetails: ProductSaleBundleItem<ProductSaleBundleProduct>
): SkuFormStateMap {
  const res: SkuFormStateMap = {};
  for (const { product, qty } of bundleDetails.items) {
    if (
      product.type === "configurable" &&
      product.variants &&
      product.configurableOptions &&
      product.configurableOptions.length === 1 &&
      product.configurableOptions[0].values.length > 0
    ) {
      for (const configurableOptionValue of product.configurableOptions[0]
        .values) {
        const configuredProduct = resolveConfiguredProductBySelectedConfiguration<
          ConfiguredProduct,
          ProductVariant
        >(product.variants, [configurableOptionValue.value]);
        if (configuredProduct && configuredProduct.stockStatus === "IN_STOCK") {
          res[product.sku] = {
            ...formInitialState,
            quantity: qty,
            configurationOptionValue: {
              [product.configurableOptions[0].id]:
                configurableOptionValue.value,
            },
          };
          break;
        }
      }
    } else {
      res[product.sku] = {
        ...formInitialState,
        quantity: qty,
      };
    }
  }
  return res;
}

const ProductSaleBundleView: React.FC<Props> = props => {
  const {
    mainProduct,
    bundleDetails,
    onClickProduct,
    onAddToCartClick,
  } = props;

  const { items, discountType, discountAmount, applyCondition } = bundleDetails;

  const [skuFormStateMap, setSkuFormStateMap] = useState<SkuFormStateMap>(
    prefillSkuFormStateMap(bundleDetails)
  );

  const displayItems = useMemo<DisplayItemType[]>(() => {
    return items.map(item => {
      if (
        item.product.type === "configurable" &&
        item.product.configurableOptions &&
        item.product.configurableOptions.length === 1 &&
        item.product.variants
      ) {
        const formState = skuFormStateMap[item.product.sku];

        if (formState) {
          const selectedOptionValues: number[] = [];

          for (const configurationOption of item.product.configurableOptions) {
            const value =
              formState.configurationOptionValue[configurationOption.id];

            if (!value) {
              break;
            }

            selectedOptionValues.push(value);
          }

          const configuredProduct = resolveConfiguredProductBySelectedConfiguration<
            ConfiguredProduct,
            ProductVariant
          >(item.product.variants, selectedOptionValues);

          if (configuredProduct) {
            return {
              type: "configured",
              configurableProduct: item.product,
              configuredProduct,
              qty: item.qty,
              discountAmount: item.discountAmount || discountAmount,
            };
          }
        }

        return {
          type: "configurable",
          configurableProduct: item.product,
          qty: item.qty,
          discountAmount: item.discountAmount || discountAmount,
        };
      }

      return {
        type: "simple",
        simpleProduct: item.product,
        qty: item.qty,
        discountAmount: item.discountAmount || discountAmount,
      };
    });
  }, [skuFormStateMap, items, discountAmount]);

  const [itemsSelected, setItemsSelected] = useState<
    { [key in number]: boolean }
  >(
    bundleDetails.items.reduce<{ [key in number]: boolean }>((prev, item) => {
      prev[item.product.id] =
        bundleDetails.applyCondition ===
          ApplyCondition.AllBundleProductsAreChosen ||
        item.product.stockStatus === "IN_STOCK"
          ? true
          : false;
      return prev;
    }, {})
  );

  const allItemsSelected = useMemo(() => {
    for (const item of bundleDetails.items) {
      if (!itemsSelected[item.product.id]) {
        return false;
      }
    }
    return true;
  }, [bundleDetails, itemsSelected]);

  const selectedItemsCount = useMemo(() => {
    let res = 1;
    for (const item of bundleDetails.items) {
      if (itemsSelected[item.product.id]) {
        res += 1;
      }
    }
    return res;
  }, [bundleDetails, itemsSelected]);

  const effectiveMainProductDiscountAmount = useMemo(
    () =>
      (bundleDetails.applyCondition ===
        ApplyCondition.AllBundleProductsAreChosen &&
        allItemsSelected) ||
      bundleDetails.applyCondition === ApplyCondition.AnyBundleProductIsChosen
        ? bundleDetails.discountAmount
        : 0,
    [bundleDetails, allItemsSelected]
  );

  const handleItemSelect = useCallback((id: number, selected: boolean) => {
    setItemsSelected(s =>
      produce(s, draft => {
        draft[id] = selected;
      })
    );
  }, []);

  const handleAddToCartClick = useCallback(() => {
    onAddToCartClick(
      mainProduct,
      bundleDetails,
      itemsSelected,
      skuFormStateMap
    );
  }, [
    onAddToCartClick,
    mainProduct,
    bundleDetails,
    itemsSelected,
    skuFormStateMap,
  ]);

  const productStateList = useMemo(
    () => [
      { product: mainProduct, disabled: false },
      ...bundleDetails.items.map(item => ({
        product: item.product,
        disabled: !itemsSelected[item.product.id],
      })),
    ],
    [mainProduct, bundleDetails, itemsSelected]
  );

  const selectedProductsMap = useMemo(() => {
    const res: IndexMap<
      number,
      ProductSaleBundleProduct | ConfiguredProduct
    > = {};
    for (const item of items) {
      if (itemsSelected[item.product.id]) {
        if (item.product.type !== "configurable") {
          res[item.product.id] = item.product;
        }
        const formState = skuFormStateMap[item.product.sku];
        if (
          formState &&
          item.product.variants &&
          item.product.configurableOptions
        ) {
          const selectedOptionValues: number[] = [];

          for (const configurationOption of item.product.configurableOptions) {
            const value =
              formState.configurationOptionValue[configurationOption.id];

            if (!value) {
              break;
            }

            selectedOptionValues.push(value);
          }

          const configuredProduct = resolveConfiguredProductBySelectedConfiguration<
            ConfiguredProduct,
            ProductVariant
          >(item.product.variants, selectedOptionValues);

          if (configuredProduct) {
            res[item.product.id] = configuredProduct;
          } else {
            res[item.product.id] = item.product;
          }
        }
      }
    }
    return res;
  }, [items, itemsSelected, skuFormStateMap]);

  const getCalculationPrice = useGetCalculationPriceFn();

  const selectedPriceWithQty = useMemo<
    {
      qty: number;
      price: number;
      minClubPoint: number;
      discountAmount: number;
    }[]
  >(() => {
    const res: {
      qty: number;
      price: number;
      minClubPoint: number;
      discountAmount: number;
    }[] = [];
    for (const item of items) {
      const selectedProduct = selectedProductsMap[item.product.id];
      if (selectedProduct) {
        const { price, minClubPoint } = getPriceAndMinClubPoint(
          getCalculationPrice(selectedProduct)
        );
        res.push({
          qty: item.qty,
          price,
          minClubPoint,
          discountAmount: item.discountAmount || bundleDetails.discountAmount,
        });
      }
    }
    return res;
  }, [items, selectedProductsMap, getCalculationPrice, bundleDetails]);

  const totalDiscountAmount = useMemo(
    () =>
      applyCondition === ApplyCondition.AllBundleProductsAreChosen &&
      !allItemsSelected
        ? 0
        : (bundleDetails.applyForParent
            ? calculateTotalDiscount(bundleDetails.discountType, [
                {
                  qty: 1,
                  discountAmount: effectiveMainProductDiscountAmount,
                  price: getPriceAndMinClubPoint(
                    getCalculationPrice(mainProduct)
                  ).price,
                },
              ])
            : 0) +
          calculateTotalDiscount(
            bundleDetails.discountType,
            selectedPriceWithQty
          ),
    [
      applyCondition,
      allItemsSelected,
      bundleDetails,
      selectedPriceWithQty,
      getCalculationPrice,
      mainProduct,
      effectiveMainProductDiscountAmount,
    ]
  );

  const totalPrice = useMemo(() => {
    const mainProductPrice = getPriceAndMinClubPoint(
      getCalculationPrice(mainProduct)
    ).price;
    return (
      (bundleDetails.applyForParent
        ? calculateTotalPrice(bundleDetails.discountType, [
            {
              qty: 1,
              price: mainProductPrice,
              discountAmount: effectiveMainProductDiscountAmount,
            },
          ])
        : mainProductPrice) +
      calculateTotalPrice(bundleDetails.discountType, selectedPriceWithQty)
    );
  }, [
    bundleDetails,
    mainProduct,
    selectedPriceWithQty,
    getCalculationPrice,
    effectiveMainProductDiscountAmount,
  ]);

  const totalMinClubPoint = useMemo(() => {
    let base = getPriceAndMinClubPoint(getCalculationPrice(mainProduct))
      .minClubPoint;
    for (const { minClubPoint, qty } of selectedPriceWithQty) {
      base += qty * minClubPoint;
    }
    return base;
  }, [getCalculationPrice, mainProduct, selectedPriceWithQty]);

  const [configModalFormState, setConfigModalFormState] = useState<
    FormState | undefined
  >(undefined);
  const [
    configModalProduct,
    setConfigModalProduct,
  ] = useState<ProductSaleBundleProduct | null>(null);
  const handleConfigFormClick = useCallback(
    (product: ProductSaleBundleProduct) => {
      const formState = skuFormStateMap[product.sku];
      if (formState) {
        setConfigModalFormState(formState);
      }
      setConfigModalProduct(product);
    },
    [skuFormStateMap]
  );

  const closeConfigModal = useCallback(
    () => setConfigModalFormState(undefined),
    []
  );

  const handleConfigFormSubmit = useCallback(
    (formState: FormState) => {
      if (!configModalProduct) {
        return;
      }
      setSkuFormStateMap(prev => ({
        ...prev,
        [configModalProduct.sku]: formState,
      }));
    },
    [configModalProduct]
  );

  const someProductsAreSoldOut = useMemo(() => {
    for (const item of bundleDetails.items) {
      if (item.product.stockStatus === "OUT_OF_STOCK") {
        return true;
      }
    }
    return false;
  }, [bundleDetails]);

  const someSelectedProductsAreSoldOut = useMemo(() => {
    for (const p of Object.values(selectedProductsMap)) {
      if (p && p.stockStatus === "OUT_OF_STOCK") {
        return true;
      }
    }
    return false;
  }, [selectedProductsMap]);

  const canAddToCart = useMemo(() => {
    return bundleDetails.applyCondition ===
      ApplyCondition.AllBundleProductsAreChosen
      ? allItemsSelected
      : selectedItemsCount >= 2;
  }, [bundleDetails, allItemsSelected, selectedItemsCount]);

  return (
    <div className={styles.productSaleBundleView}>
      {bundleDetails.blockTitle || bundleDetails.blockDescription ? (
        <div className={styles.header}>
          {bundleDetails.blockTitle ? (
            <h4 className={styles.title}>{bundleDetails.blockTitle}</h4>
          ) : null}
          {bundleDetails.blockDescription ? (
            <p className={styles.description}>
              {bundleDetails.blockDescription}
            </p>
          ) : null}
        </div>
      ) : null}
      <div className={styles.productSaleBundleViewContent}>
        <Accordion
          header={
            <div className={styles.contentHeader}>
              <ProductImageList
                items={productStateList}
                onClickProduct={onClickProduct}
              />
            </div>
          }
          body={
            <ul className={styles.productList}>
              <li className={styles.productListItem}>
                <div className={styles.item}>
                  <MainItem
                    discountType={discountType}
                    discountAmount={
                      bundleDetails.applyForParent ? discountAmount : 0
                    }
                    product={mainProduct}
                  />
                </div>
              </li>
              {displayItems.map(item => {
                switch (item.type) {
                  case "simple": {
                    const p = item.simpleProduct;
                    return (
                      <li key={p.sku} className={styles.productListItem}>
                        <div className={styles.item}>
                          <SimpleItem
                            discountType={discountType}
                            discountAmount={item.discountAmount}
                            applyCondition={applyCondition}
                            product={p}
                            selected={itemsSelected[p.id] || false}
                            onSelect={handleItemSelect}
                            onClickProduct={onClickProduct}
                          />
                        </div>
                      </li>
                    );
                  }
                  case "configurable": {
                    const p = item.configurableProduct;
                    return (
                      <li key={p.sku} className={styles.productListItem}>
                        <div className={styles.item}>
                          <SimpleItem
                            discountType={discountType}
                            discountAmount={item.discountAmount}
                            applyCondition={applyCondition}
                            product={p}
                            selected={itemsSelected[p.id] || false}
                            onSelect={handleItemSelect}
                            onClickProduct={onClickProduct}
                          />
                        </div>
                      </li>
                    );
                  }
                  case "configured": {
                    const p = item.configuredProduct;
                    const parentP = item.configurableProduct;
                    return (
                      <li key={p.sku} className={styles.productListItem}>
                        <div className={styles.item}>
                          <ConfiguredItem
                            discountType={discountType}
                            discountAmount={item.discountAmount}
                            applyCondition={applyCondition}
                            parentProduct={parentP}
                            product={p}
                            selectedConfigurations={
                              skuFormStateMap[parentP.sku]
                            }
                            selected={itemsSelected[parentP.id] || false}
                            onSelect={handleItemSelect}
                            onClickProduct={onClickProduct}
                            onConfigFormClick={handleConfigFormClick}
                          />
                        </div>
                      </li>
                    );
                  }
                  default:
                    return <></>;
                }
              })}
            </ul>
          }
          footer={
            <div className={styles.contentFooter}>
              <p className={styles.totalPrice}>
                <LocalizedText messageID="product_sale_bundle.bundle_price" /> :{" "}
                {totalMinClubPoint > 0 ? (
                  <>
                    <IonIcon
                      name="club-point"
                      className={styles.clubPointIcon}
                    />
                    {numeral(totalMinClubPoint).format("0,0")} +{" "}
                  </>
                ) : null}
                {formatPriceValue(totalPrice)}
              </p>
              <p className={styles.savePrice}>
                <LocalizedText messageID="product_sale_bundle.total_save" /> :
                {formatPriceValue(totalDiscountAmount)}
              </p>
            </div>
          }
        />
      </div>
      {((someProductsAreSoldOut || !allItemsSelected) &&
        applyCondition === ApplyCondition.AllBundleProductsAreChosen) ||
      someSelectedProductsAreSoldOut ? (
        <SoldOutButton />
      ) : (
        <AddToCartButton
          onClick={handleAddToCartClick}
          count={selectedItemsCount}
          disabled={!canAddToCart}
        />
      )}
      <ConfigProductFormModal
        isModalOpen={configModalFormState != null}
        onDismiss={closeConfigModal}
        product={configModalProduct}
        formState={configModalFormState}
        onSubmitConfigForm={handleConfigFormSubmit}
      />
    </div>
  );
};

export default ProductSaleBundleView;

interface SimpleItemProps {
  discountType: DiscountType;
  discountAmount: number;
  applyCondition: ApplyCondition;
  product: ProductSaleBundleProduct;
  selected: boolean;
  onSelect: (id: number, selected: boolean) => void;
  onClickProduct: (product: ProductSaleBundleProduct) => void;
}

const SimpleItem: React.FC<SimpleItemProps> = props => {
  const {
    discountType,
    discountAmount,
    applyCondition,
    product,
    selected,
    onSelect,
    onClickProduct,
  } = props;

  const appConfig = useAppConfig();

  const handleCheckedChange = useCallback(
    (checked: boolean) => {
      onSelect(product.id, checked);
    },
    [product, onSelect]
  );

  const handleClickProduct = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();

      onClickProduct(product);
    },
    [onClickProduct, product]
  );
  const productOfMinValue = useMemo(
    () =>
      product.type !== "configurable"
        ? product
        : appConfig
        ? getConfiguredProductOfLowestPrice<
            ConfiguredProductOverview,
            ProductSaleBundleProduct
          >(product, getClubPointConversionRate(appConfig)) || product
        : product,
    [appConfig, product]
  );
  const productOfMaxValue = useMemo(
    () =>
      product.type !== "configurable"
        ? product
        : appConfig
        ? getConfiguredProductOfHighestPrice<
            ConfiguredProductOverview,
            ProductSaleBundleProduct
          >(product, getClubPointConversionRate(appConfig)) || product
        : product,
    [appConfig, product]
  );

  const checkState = useMemo<CheckState>(() => {
    if (product.stockStatus === "OUT_OF_STOCK") {
      return "blocked";
    }
    if (selected) {
      return "checked";
    }
    return "unchecked";
  }, [product, selected]);

  return (
    <div className={styles.product}>
      <div
        className={cn(styles.productRow, {
          [styles.dimmed]: checkState === "blocked",
        })}
      >
        <Checkbox
          checkState={checkState}
          dimmed={
            product.stockStatus === "OUT_OF_STOCK" ||
            applyCondition === ApplyCondition.AllBundleProductsAreChosen
          }
          disabled={
            (!selected && product.stockStatus === "OUT_OF_STOCK") ||
            applyCondition === ApplyCondition.AllBundleProductsAreChosen
          }
          onCheckedChange={handleCheckedChange}
        >
          {product.stockStatus === "OUT_OF_STOCK" ? <OutOfStockLabel /> : null}
          <button
            onClick={handleClickProduct}
            className={styles.productNameText}
          >
            {product.name}{" "}
          </button>
          <ProductPriceView
            discountType={discountType}
            discountAmount={discountAmount}
            productOfMinValue={productOfMinValue}
            productOfMaxValue={productOfMaxValue}
          />
        </Checkbox>
      </div>
    </div>
  );
};

interface ConfiguredItemProps {
  discountType: DiscountType;
  discountAmount: number;
  applyCondition: ApplyCondition;
  product: ConfiguredProduct;
  parentProduct: ProductSaleBundleProduct;
  selectedConfigurations: FormState | undefined;
  selected: boolean;
  onSelect: (id: number, selected: boolean) => void;
  onClickProduct: (product: ProductSaleBundleProduct) => void;
  onConfigFormClick: (product: ProductSaleBundleProduct) => void;
}

const ConfiguredItem: React.FC<ConfiguredItemProps> = props => {
  const {
    discountType,
    discountAmount,
    applyCondition,
    product,
    parentProduct,
    selectedConfigurations,
    selected,
    onSelect,
    onClickProduct,
    onConfigFormClick,
  } = props;

  const appConfig = useAppConfig();

  const handleCheckedChange = useCallback(
    (checked: boolean) => {
      onSelect(parentProduct.id, checked);
    },
    [parentProduct, onSelect]
  );

  const handleClickProduct = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();

      onClickProduct(parentProduct);
    },
    [onClickProduct, parentProduct]
  );

  const productOfMinValue = useMemo(
    () =>
      product.type !== "configurable"
        ? product
        : appConfig
        ? getConfiguredProductOfLowestPrice<
            ConfiguredProduct,
            ProductSaleBundleProduct
          >(parentProduct, getClubPointConversionRate(appConfig)) || product
        : product,
    [appConfig, parentProduct, product]
  );
  const productOfMaxValue = useMemo(
    () =>
      product.type !== "configurable"
        ? product
        : appConfig
        ? getConfiguredProductOfHighestPrice<
            ConfiguredProduct,
            ProductSaleBundleProduct
          >(parentProduct, getClubPointConversionRate(appConfig)) || product
        : product,
    [appConfig, parentProduct, product]
  );

  const checkState = useMemo<CheckState>(() => {
    if (product.stockStatus === "OUT_OF_STOCK") {
      return "blocked";
    }
    if (selected) {
      return "checked";
    }
    return "unchecked";
  }, [product, selected]);

  return (
    <div className={styles.product}>
      <div
        className={cn(styles.productRow, {
          [styles.dimmed]: checkState === "blocked",
        })}
      >
        <Checkbox
          checkState={checkState}
          dimmed={
            product.stockStatus === "OUT_OF_STOCK" ||
            applyCondition === ApplyCondition.AllBundleProductsAreChosen
          }
          disabled={
            (!selected && product.stockStatus === "OUT_OF_STOCK") ||
            applyCondition === ApplyCondition.AllBundleProductsAreChosen
          }
          onCheckedChange={handleCheckedChange}
        >
          {product.stockStatus === "OUT_OF_STOCK" ? <OutOfStockLabel /> : null}
          <button
            onClick={handleClickProduct}
            className={styles.productNameText}
          >
            {product.name}{" "}
          </button>
          <ProductConfigurationForm
            product={parentProduct}
            formState={selectedConfigurations}
            onClick={onConfigFormClick}
          />
          <ProductPriceView
            discountType={discountType}
            discountAmount={discountAmount}
            productOfMinValue={productOfMinValue}
            productOfMaxValue={productOfMaxValue}
          />
        </Checkbox>
      </div>
    </div>
  );
};

interface MainItemProps {
  discountType: DiscountType;
  discountAmount: number;
  product: ProductSaleBundleProduct | ConfiguredProduct;
}

const MainItem: React.FC<MainItemProps> = props => {
  const { discountType, discountAmount, product } = props;
  const appConfig = useAppConfig();
  const productOfMinValue = useMemo(
    () =>
      product.type !== "configurable"
        ? product
        : appConfig
        ? getConfiguredProductOfLowestPrice<
            ConfiguredProductOverview,
            ProductSaleBundleProduct | ConfiguredProduct
          >(product, getClubPointConversionRate(appConfig)) || product
        : product,
    [appConfig, product]
  );
  const productOfMaxValue = useMemo(
    () =>
      product.type !== "configurable"
        ? product
        : appConfig
        ? getConfiguredProductOfHighestPrice<
            ConfiguredProductOverview,
            ProductSaleBundleProduct | ConfiguredProduct
          >(product, getClubPointConversionRate(appConfig)) || product
        : product,
    [appConfig, product]
  );
  return (
    <div className={styles.product}>
      <div className={styles.productRow}>
        <Checkbox checkState="checked" disabled={true} dimmed={true}>
          {product.stockStatus === "OUT_OF_STOCK" ? <OutOfStockLabel /> : null}
          <span className={styles.productNameText}>{product.name}</span>
          <ProductPriceView
            discountType={discountType}
            discountAmount={discountAmount}
            productOfMinValue={productOfMinValue}
            productOfMaxValue={productOfMaxValue}
          />
        </Checkbox>
      </div>
    </div>
  );
};

interface AddToCartButtonProps {
  disabled: boolean;
  count: number;
  onClick: () => void;
}

const AddToCartButton: React.FC<AddToCartButtonProps> = props => {
  const { disabled, count, onClick } = props;

  const handleClick = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();

      onClick();
    },
    [onClick]
  );

  return (
    <PrimaryButton
      onClick={handleClick}
      className={styles.addToCartButton}
      disabled={disabled}
    >
      <span className={styles.addToCartButtonText}>
        <LocalizedText
          messageID="product_sale_bundle.add_to_cart"
          messageArgs={{
            count,
          }}
        />
      </span>
    </PrimaryButton>
  );
};

const SoldOutButton: React.FC = () => {
  return (
    <div className={styles.soldOutButton}>
      <span className={styles.addToCartButtonText}>
        <LocalizedText messageID="product_sale_bundle.add_to_cart.sold_out" />
      </span>
    </div>
  );
};

const OutOfStockLabel: React.FC = () => {
  return (
    <span className={styles.outOfStockLabel}>
      <LocalizedText messageID="product_sale_bundle.item.out_of_stock" />
    </span>
  );
};

function useGetCalculationPriceFn() {
  const appConfig = useAppConfig();

  return useCallback(
    <
      ConfiguredProductType extends {
        priceRange: PriceRange | null;
        minClubPoint: number;
      },
      T extends {
        priceRange: PriceRange | null;
        minClubPoint: number;
        variants?: { product: ConfiguredProductType }[] | null;
        type: ProductType;
      }
    >(
      product: T
    ) => {
      const productOfMinValue =
        product.type !== "configurable"
          ? product
          : appConfig
          ? getConfiguredProductOfLowestPrice<ConfiguredProductType, T>(
              product,
              getClubPointConversionRate(appConfig)
            ) || product
          : product;
      const productOfMaxValue =
        product.type !== "configurable"
          ? product
          : appConfig
          ? getConfiguredProductOfHighestPrice<ConfiguredProductType, T>(
              product,
              getClubPointConversionRate(appConfig)
            ) || product
          : product;
      return getListedMoney(productOfMinValue, productOfMaxValue);
    },
    [appConfig]
  );
}

function getPriceAndMinClubPoint(
  calculationPrice: ReturnType<ReturnType<typeof useGetCalculationPriceFn>>
): { price: number; minClubPoint: number } {
  if (!calculationPrice) {
    return {
      price: 0,
      minClubPoint: 0,
    };
  }
  if (calculationPrice.type === "single") {
    return {
      price: calculationPrice.money.value,
      minClubPoint: 0,
    };
  } else if (calculationPrice.type === "singleMinCP") {
    return {
      price: calculationPrice.money.value,
      minClubPoint: calculationPrice.minClubPoint,
    };
  } else if (calculationPrice.type === "range") {
    return {
      price: calculationPrice.minMoney.value,
      minClubPoint: 0,
    };
  } else if (calculationPrice.type === "cpOnly") {
    return {
      price: 0,
      minClubPoint: calculationPrice.clubPoint,
    };
  }
  return {
    price: 0,
    minClubPoint: 0,
  };
}
