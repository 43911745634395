import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { IonIcon, IonImg } from "@ionic/react";
import classnames from "classnames";
import Dotdotdot from "react-dotdotdot";
import Decimal from "decimal.js";

import { LocalizedText } from "../../i18n/Localization";
import {
  getClubPointConversionRate,
  getRebateRate,
} from "../../models/AppConfig";
import { isRequestLoading } from "../../models/ResourcesRequestState";
import {
  doesProductHave360Images,
  getConfiguredProductOfHighestPrice,
  getConfiguredProductOfLowestPrice,
  getRebatedClubPoint,
  isNormalProductSoldOut,
  isThirdPartyProduct,
  shouldNormalProductShowPrice,
  ThirdPartyProduct,
} from "../../models/product";
import {
  ConfiguredProductOverview,
  ProductOverview,
} from "../../models/ProductOverview";
import { ProductLabel } from "../../models/ProductLabel";
import { useIsProductLiked } from "../../repository/ProductRepository";
import { useAppConfig } from "../../repository/ConfigRepository";
import { useIsLoggedIn } from "../../repository/AuthRepository";
import useInViewPolyfill from "../../hook/useInViewPolyfill";

import Product360Badge from "../Product360Badge";
import LikeButton from "../ProductBlock/LikeButton";
import { WhiteButton } from "../Button";
import { useRequestSubscribeToRestockAlert } from "../SubscribeProductRestockAlert/repository";
import { LoginSignupModalContext } from "../LoginSignupModalProvider";
import { LocalizedAlertContext } from "../LocalizedAlertProvider";
import { useIsProductSubscribedToRestockAlert } from "../SubscribeProductRestockAlert/context";
import ProductBlockPriceView from "../ProductBlockPriceView";

import styles from "./ProductBlock.module.scss";
import RebateMessage from "./RebateMessage";
import ProductBadges from "../ProductBadges";
import CLLink from "../navigation/CLLink";
import ProductLabels from "../ProductLabel";

import placeholderUrl from "../../resources/img-product-default.png";

type ButtonState =
  | ButtonAddToCart
  | ButtonSubscribeRestockAlert
  | ButtonSoldOut;

interface ButtonAddToCart {
  type: "addToCart";
}

interface ButtonSubscribeRestockAlert {
  type: "subscribeRestockAlert";
  requesting: boolean;
  subscribed: boolean;
}

interface ButtonSoldOut {
  type: "soldOut";
}

interface ProductBlockProps {
  product: ProductOverview;
  productLabels?: ProductLabel[];
  hasBundle?: boolean;
  className?: string;
  rank?: number;
  showOnlyInView: boolean;
  hrefForProduct: (productOverview: ProductOverview) => string;
  onClick?: (productOverview: ProductOverview) => void;
  onAddToCart: (productOverview: ProductOverview) => any;
  onClickLikeButton: (productOverview: ProductOverview) => void;
}

function getProductImage(product: ProductOverview) {
  return product.thumbnail && !product.thumbnail.disabled
    ? product.thumbnail.url
    : product.image && !product.image.disabled
    ? product.image.url
    : placeholderUrl;
}

const ProductBlock: React.FC<ProductBlockProps> = props => {
  const {
    product,
    productLabels,
    hasBundle,
    rank,
    showOnlyInView,
    className,
    hrefForProduct,
    onClickLikeButton,
    onAddToCart,
    onClick,
  } = props;

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(product);
    }
  }, [onClick, product]);

  const [imgSrc, setImgSrc] = useState(getProductImage(product));
  useEffect(() => {
    setImgSrc(getProductImage(product));
  }, [product]);

  const [imgLoaded, setImgLoaded] = useState(false);
  // Show image cover to prevent flashing and showing not found image for 1 frame
  const [imgCoverShown, setImgCoverShown] = useState(true);
  const handleImageLoadError = useCallback(() => {
    setImgSrc(placeholderUrl);
  }, []);
  const handleImageDidLoad = useCallback(() => {
    setImgLoaded(true);
    // Hide image cover for 1 frame to prevent image flashing
    setTimeout(() => setImgCoverShown(false));
  }, []);

  // hide the view initially to reduce the initial work load
  const [ref, inView] = useInViewPolyfill();

  const placeholderStyle = useMemo(
    () => ({
      height: 134,
      top: 23,
      borderRadius: 10,
      backgroundColor: "white",
      border: "1px solid #dfdfdf",
    }),
    []
  );

  const [revealed, setRevealed] = useState(!showOnlyInView);

  useEffect(() => {
    if (showOnlyInView) {
      setTimeout(() => setRevealed(inView));
    }
  }, [inView, showOnlyInView]);

  return (
    <div className={styles.observableRoot}>
      {showOnlyInView ? (
        <div ref={ref} className={styles.productVisibleArea} />
      ) : null}
      {showOnlyInView && !inView && (
        <div className={styles.product} style={placeholderStyle} />
      )}
      <CLLink
        className={classnames(styles.product, className, {
          [styles.productRevealed]: revealed,
        })}
        to={hrefForProduct(product)}
        onClick={handleClick}
      >
        <div className={styles.imageContent}>
          <div
            className={classnames(styles.image, {
              [styles.productImageLoaded]: imgLoaded,
            })}
          >
            <IonImg
              src={imgSrc}
              alt=""
              onIonError={handleImageLoadError}
              onIonImgDidLoad={handleImageDidLoad}
              className={styles.img}
            />
            {doesProductHave360Images(product) && (
              <Product360Badge className={styles.badge360Images} />
            )}
            {rank != null ? <div className={styles.rank}>{rank}</div> : null}
            {imgCoverShown && <div className={styles.imgCover} />}
            {productLabels && (
              <ProductLabels
                productLabels={productLabels}
                productLabelMode="category"
              />
            )}
          </div>
          <div className={styles.badges}>
            <ProductBadges
              hasBundle={hasBundle}
              productOverview={product}
              maxNumberOfBadgesToRender={2}
            />
          </div>
        </div>
        <div className={styles.content}>
          {isThirdPartyProduct(product) ? (
            <ThirdPartyProductContent
              product={product}
              onClickLikeButton={onClickLikeButton}
            />
          ) : (
            <NormalPoductContent
              product={product}
              onClickLikeButton={onClickLikeButton}
              onAddToCart={onAddToCart}
            />
          )}
        </div>
      </CLLink>
    </div>
  );
};

export default ProductBlock;

interface ThirdPartyProductContentProps {
  product: ThirdPartyProduct<ProductOverview>;
  onClickLikeButton: (productOverview: ProductOverview) => void;
}

export const ThirdPartyProductContent: React.FC<
  ThirdPartyProductContentProps
> = props => {
  const { onClickLikeButton, product } = props;

  const { name, showPrice } = product;

  const isProductLiked = useIsProductLiked(product.sku);

  const onClickLikeButton_ = useCallback(() => {
    onClickLikeButton(product);
  }, [product, onClickLikeButton]);

  return (
    <>
      <Dotdotdot clamp={3}>
        <p className={styles.name}>{name}</p>
      </Dotdotdot>
      <div className={styles.like}>
        <LikeButton like={isProductLiked} onClick={onClickLikeButton_} />
      </div>
      {showPrice && (
        <div className={styles.priceView}>
          <ProductBlockPriceView
            productOfMinValue={product}
            productOfMaxValue={product}
          />
        </div>
      )}
      <WhiteButton className={styles.addToCart} block={true}>
        <LocalizedText messageID="product.details" />
      </WhiteButton>
    </>
  );
};

interface NormalProductContentProps {
  product: ProductOverview;
  onAddToCart: (productOverview: ProductOverview) => any;
  onClickLikeButton: (productOverview: ProductOverview) => void;
}

export const NormalPoductContent: React.FC<
  NormalProductContentProps
> = props => {
  const { product, onAddToCart, onClickLikeButton } = props;

  const { name } = product;

  const appConfig = useAppConfig();
  const isLoggedIn = useIsLoggedIn();

  const { presentLocalizedAlert } = useContext(LocalizedAlertContext);
  const { presentLoginModal } = useContext(LoginSignupModalContext);

  const isProductSoldOut = useMemo(() => isNormalProductSoldOut(product), [
    product,
  ]);
  const isNoPrice = useMemo(() => !shouldNormalProductShowPrice(product), [
    product,
  ]);

  const isProductLiked = useIsProductLiked(product.sku);
  const isSubscribedToRestockAlert = useIsProductSubscribedToRestockAlert(
    product.id
  );

  const onClickLikeButton_ = useCallback(() => {
    onClickLikeButton(product);
  }, [product, onClickLikeButton]);

  const productOfDisplayPrice = useMemo(() => {
    if (!appConfig) {
      return product;
    }
    return (
      getConfiguredProductOfLowestPrice<
        ConfiguredProductOverview,
        ProductOverview
      >(product, getClubPointConversionRate(appConfig)) || product
    );
  }, [product, appConfig]);

  const productOfHighestPrice = useMemo(() => {
    if (!appConfig) {
      return product;
    }
    return (
      getConfiguredProductOfHighestPrice<
        ConfiguredProductOverview,
        ProductOverview
      >(product, getClubPointConversionRate(appConfig)) || product
    );
  }, [product, appConfig]);

  const rebateAmount = useMemo<number | null>(() => {
    const rebateRate = getRebateRate(appConfig);
    if (!rebateRate) {
      return null;
    }
    const rebatedClubPoint = getRebatedClubPoint(productOfDisplayPrice);
    // https://3.basecamp.com/3096882/buckets/12323301/messages/2788848052#__recording_2802722163
    if (rebatedClubPoint.toNumber() <= 1) {
      return null;
    }
    return rebatedClubPoint
      .times(rebateRate)
      .times(new Decimal(100))
      .toNumber();
  }, [productOfDisplayPrice, appConfig]);

  const rebateCircleData = useMemo<{
    state: "show";
    rebateAmount: number;
  } | null>(() => {
    if (rebateAmount != null && rebateAmount > 1) {
      return {
        state: "show",
        rebateAmount,
      };
    }
    return null;
  }, [rebateAmount]);

  const handleAddToCart = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.stopPropagation();
      e.preventDefault();
      onAddToCart(product);
    },
    [onAddToCart, product]
  );

  const [
    subscribeToRestockAlertRequestState,
    subscribeToRestockAlert,
  ] = useRequestSubscribeToRestockAlert();

  const handleSubscribeRestock = useCallback(
    async (e: React.MouseEvent<unknown>) => {
      e.stopPropagation();
      e.preventDefault();
      if (!isLoggedIn) {
        presentLoginModal();
        return;
      }
      try {
        const subscribed = await subscribeToRestockAlert(product.id);
        presentLocalizedAlert({
          messageId: subscribed
            ? "product.subscribe_restock.success"
            : "product.subscribe_restock.failed",
          buttons: [{ textMessageID: "alert.button.ok" }],
        });
      } catch (_e) {
        const { message } = _e;
        presentLocalizedAlert({
          message: message ? message : undefined,
          messageId: message ? undefined : "product.subscribe_restock.failed",
          buttons: [{ textMessageID: "alert.button.ok" }],
        });
      }
    },
    [
      isLoggedIn,
      subscribeToRestockAlert,
      presentLocalizedAlert,
      product,
      presentLoginModal,
    ]
  );

  const buttonState = useMemo<ButtonState>(() => {
    if (!isProductSoldOut) {
      return { type: "addToCart" };
    }
    if (appConfig && appConfig.isStockAlertAllowed) {
      return {
        type: "subscribeRestockAlert",
        requesting: isRequestLoading(subscribeToRestockAlertRequestState),
        subscribed: isSubscribedToRestockAlert,
      };
    }
    return { type: "soldOut" };
  }, [
    appConfig,
    isProductSoldOut,
    subscribeToRestockAlertRequestState,
    isSubscribedToRestockAlert,
  ]);

  return (
    <>
      <Dotdotdot clamp={3}>
        <p className={styles.name}>{name}</p>
      </Dotdotdot>
      <div className={styles.like}>
        <LikeButton like={isProductLiked} onClick={onClickLikeButton_} />
      </div>
      {isNoPrice ? null : (
        <div className={styles.priceView}>
          <ProductBlockPriceView
            productOfMinValue={productOfDisplayPrice}
            productOfMaxValue={productOfHighestPrice}
          />
        </div>
      )}
      {isNoPrice ? null : rebateCircleData != null ? (
        <RebateMessage
          rebateAmount={rebateCircleData.rebateAmount}
          className={styles.rebateMessage}
        />
      ) : null}
      {buttonState.type === "addToCart" ? (
        <WhiteButton
          className={styles.addToCart}
          block={true}
          onClick={handleAddToCart}
        >
          <IonIcon name="plus" className={styles.addIcon} />
          <span className={styles.displayLarge}>
            <LocalizedText messageID="product.add_to_cart" />
          </span>
          <span className={styles.displaySmall}>
            <LocalizedText messageID="product.add_to_cart.small" />
          </span>
        </WhiteButton>
      ) : buttonState.type === "subscribeRestockAlert" ? (
        <WhiteButton
          className={styles.addToCart}
          block={true}
          loading={buttonState.requesting}
          disabled={buttonState.requesting || buttonState.subscribed}
          onClick={handleSubscribeRestock}
        >
          {buttonState.requesting ? (
            <></>
          ) : (
            <LocalizedText messageID="product.subscribe_restock" />
          )}
        </WhiteButton>
      ) : buttonState.type === "soldOut" ? (
        <WhiteButton className={styles.addToCart} block={true} disabled={true}>
          <LocalizedText messageID="product.sold_out" />
        </WhiteButton>
      ) : null}
    </>
  );
};
