import { InAppBrowserObject } from "@ionic-native/in-app-browser";

export type Subscription = ReturnType<
  ReturnType<InAppBrowserObject["on"]>["subscribe"]
>;

interface InAppBrowserMessageCallbackParams<MessageType extends Object> {
  data: Partial<MessageType>;
}

interface InAppBrowserHandleAnchorTargetBlankMessageType {
  openUrlInBlank: string;
}

// This function will be plugged to the in app browser.
// Since the variable may be renamed during bundling,
// please make sure the variables are provided from iab env through parameters.
function handleAnchorTargetBlank(
  window: Window,
  document: Document,
  JSON: JSON
) {
  const elements = document.querySelectorAll('a[target="_blank"]');
  const makeMessage = (
    url: string
  ): InAppBrowserHandleAnchorTargetBlankMessageType => {
    return {
      openUrlInBlank: url,
    };
  };
  const handler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.currentTarget) {
      (window as any).webkit.messageHandlers.cordova_iab.postMessage(
        JSON.stringify(makeMessage((e.currentTarget as HTMLAnchorElement).href))
      );
    }
  };
  elements.forEach(el => {
    el.addEventListener("click", handler);
  });
}

export function executeHandleAnchorTargetBlank(
  onUrlHandle: (url: string) => void,
  browser: InAppBrowserObject
): () => void {
  const subscriptions: Subscription[] = [];

  const loadstop = browser.on("loadstop");
  if (loadstop) {
    subscriptions.push(
      loadstop.subscribe(() => {
        browser.executeScript({
          code: `(${handleAnchorTargetBlank.toString()})(window, document, JSON);`,
        });
      })
    );
  }

  const message = browser.on("message");
  if (message) {
    subscriptions.push(
      message.subscribe(
        (
          params: InAppBrowserMessageCallbackParams<
            InAppBrowserHandleAnchorTargetBlankMessageType
          >
        ) => {
          if (params.data.openUrlInBlank) {
            onUrlHandle(params.data.openUrlInBlank);
          }
        }
      )
    );
  }

  return () => {
    subscriptions.forEach(subscription => subscription.unsubscribe());
  };
}
