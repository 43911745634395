import React, { useState, useCallback, useMemo } from "react";
import { IonFooter, IonToolbar } from "@ionic/react";

import {
  ProductConfigurableOption,
  sortConfigurableOptions,
} from "../../models/product";
import { Product } from "../../models/ProductDetails";

import { PrimaryButton } from "../Button";
import CLModal from "../CLModal/lazy";
import { LocalizedText } from "../../i18n/Localization";
import ProductInfo from "./PurchaseProductModal/ProductInfo.lazy";
import ProductConfigurationForm from "./PurchaseProductModal/ProductConfigurationForm.lazy";
import {
  usePurchaseProductFormStateReducer,
  FormState,
  getFormInitialState,
} from "./PurchaseProductModal/PurchaseProductFormStateHook";

import { resolveConfiguredProduct } from "../../models/ProductConfigurationDependency";

import { findFirst } from "../../utils/CollectionUtils";
import { actionEvent } from "../../utils/GTM";

import styles from "./ProductConfigurationOptionsPanel.module.scss";
import { ProductInfoProductType } from "./PurchaseProductModal/ProductInfo";

interface Props {
  product: Product;
  formState: FormState;
  onSubmitConfigForm: (formState: FormState) => any;
}

const ProductConfigurationOptionsPanel: React.FC<Props> = React.memo(props => {
  const { product, formState: _formState, onSubmitConfigForm } = props;
  const { configurableOptions: _configurableOptions } = product;

  const configurableOptions = useMemo(() => {
    if (!_configurableOptions) {
      return _configurableOptions;
    }
    return sortConfigurableOptions(_configurableOptions);
  }, [_configurableOptions]);

  const [isModalOpen, setIsModelOpen] = useState(false);
  const closeModal = useCallback(() => {
    setIsModelOpen(false);
  }, [setIsModelOpen]);
  const onClickConfiguraionOpionItem = useCallback(
    (option: ProductConfigurableOption) => {
      actionEvent("Product Details", "Click", option.label);
      setIsModelOpen(true);
    },
    [setIsModelOpen]
  );

  if (configurableOptions == null || configurableOptions.length <= 0) {
    return null;
  }
  return (
    <>
      <div className={styles.productConfigurationOptionsPanelContainer}>
        {configurableOptions.map(c => (
          <ConfiguraionOpionItem
            key={c.id}
            configurationOption={c}
            selectedValue={_formState.configurationOptionValue[c.id]}
            onClick={onClickConfiguraionOpionItem}
          />
        ))}
      </div>
      <ConfigProductFormModal
        isModalOpen={isModalOpen}
        onDismiss={closeModal}
        product={product}
        formState={_formState}
        onSubmitConfigForm={onSubmitConfigForm}
      />
    </>
  );
});

export default ProductConfigurationOptionsPanel;

const ConfiguraionOpionItem: React.FC<{
  configurationOption: ProductConfigurableOption;
  selectedValue: number | null | undefined;
  onClick: (option: ProductConfigurableOption) => any;
}> = React.memo(props => {
  const { configurationOption, selectedValue, onClick } = props;
  const selectedValueLabel = useMemo(() => {
    const selectedOption = findFirst(
      i => i.value === selectedValue,
      configurationOption.values
    );
    return selectedOption ? selectedOption.label : null;
  }, [configurationOption, selectedValue]);
  const handleClick = useCallback(() => {
    onClick(configurationOption);
  }, [onClick, configurationOption]);
  return (
    <div className={styles.productConfiguraionOpionItem}>
      <div className={styles.productConfigurationOptionLabel}>
        {configurationOption.label}
      </div>
      <button
        className={styles.productConfigurationOptionButton}
        onClick={handleClick}
      >
        <span className={styles.productConfigurationOptionButtonText}>
          {selectedValueLabel ? (
            selectedValueLabel
          ) : (
            <LocalizedText messageID="page.product_detail.product_configuration.form.item_picker.placeholder" />
          )}
        </span>
      </button>
    </div>
  );
});

interface ConfigProductFormModelProps {
  isModalOpen: boolean;
  product: Product | null;
  formState: FormState | undefined;
  onDismiss: () => void;
  onSubmitConfigForm: (formState: FormState) => void;
}

export const ConfigProductFormModal: React.FC<
  ConfigProductFormModelProps
> = props => {
  const {
    isModalOpen,
    product,
    formState: _formState,
    onDismiss,
    onSubmitConfigForm,
  } = props;

  const onClickFormDoneButton = useCallback(
    (formState: FormState) => {
      onSubmitConfigForm(formState);
      onDismiss();
    },
    [onDismiss, onSubmitConfigForm]
  );

  const formState = useMemo(() => _formState || getFormInitialState(), [
    _formState,
  ]);

  return (
    <CLModal
      className={styles.modal}
      isOpen={isModalOpen}
      onRequestDismiss={onDismiss}
      backdropDismiss={true}
    >
      <div className={styles.modalContent}>
        <div className={styles.touchToDismissHack} onClick={onDismiss} />
        {product ? (
          <ConfigProductForm
            product={product}
            formInitialState={formState}
            onClickCloseButton={onDismiss}
            onClickDoneButton={onClickFormDoneButton}
          />
        ) : null}
      </div>
    </CLModal>
  );
};

const ConfigProductForm: React.FC<{
  product: Product;
  formInitialState: FormState;
  onClickCloseButton: () => any;
  onClickDoneButton: (formState: FormState) => any;
}> = props => {
  const {
    product,
    formInitialState,
    onClickCloseButton,
    onClickDoneButton,
  } = props;
  const { formState, formStateDispatch } = usePurchaseProductFormStateReducer(
    formInitialState
  );

  const onConfigurationOptionValueChange = useCallback(
    (
      configurableOptionId: number,
      value: number | null,
      childOptionIds: number[]
    ) => {
      formStateDispatch({
        type: "UpdateConfigurationOptionValue",
        id: configurableOptionId,
        value,
        childOptionIds,
      });
    },
    [formStateDispatch]
  );

  const configuredProduct = useMemo(() => {
    return resolveConfiguredProduct(
      product,
      formState.configurationOptionValue
    );
  }, [product, formState.configurationOptionValue]);

  const onClickDoneButton_ = useCallback(() => {
    onClickDoneButton(formState);
  }, [formState, onClickDoneButton]);

  const productInfoProduct = useMemo<ProductInfoProductType>(
    () =>
      configuredProduct
        ? { type: "configuredProduct", configuredProduct }
        : { type: "product", product },
    [configuredProduct, product]
  );

  return (
    <div className={styles.configProductFormContainer}>
      <div className={styles.productInfoContainer}>
        <ProductInfo product={productInfoProduct} />
        <button className={styles.closeButton} onClick={onClickCloseButton} />
      </div>
      <div className={styles.configForm}>
        <ProductConfigurationForm
          product={product}
          formState={formState.configurationOptionValue}
          onConfigurationOptionValueChange={onConfigurationOptionValueChange}
        />
      </div>
      <DoneButton onClick={onClickDoneButton_} />
    </div>
  );
};

const DoneButton: React.FC<{
  onClick: () => any;
}> = React.memo(props => {
  const { onClick } = props;
  return (
    <IonFooter>
      <IonToolbar className={styles.ionFooterToolbar}>
        <div className={styles.doneButtonContainer}>
          <PrimaryButton className={styles.doneButton} onClick={onClick}>
            <LocalizedText messageID="page.product_detail.product_configuration.form.button.done" />
          </PrimaryButton>
        </div>
      </IonToolbar>
    </IonFooter>
  );
});
