import React, {
  useEffect,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";
import PurchaseProductModal from "./ProductDetailPage/PurchaseProductModal/lazy";
import {
  isCampaignProductSoldOut,
  isClubProtectEligible,
  isNormalProductSoldOut,
} from "../models/product";
import { ProductOverview } from "../models/ProductOverview";
import { Product, ConfiguredProduct } from "../models/ProductDetails";
import {
  FormState,
  getFormInitialState,
} from "./ProductDetailPage/PurchaseProductModal/PurchaseProductFormStateHook";
import {
  Cart,
  ReeService,
  SubscribeEnum,
  CustomizableOptionInput,
  getMinClubPointUsed,
  ClubProtectionInput,
} from "../models/cart";
import AddToCartSuccessModal from "./ProductDetailPage/AddToCartSuccessModal.lazy";
import {
  useFetchCampaignProductDetailBySKU,
  useFetchProductDetailBySKU,
} from "../repository/ProductRepository";
import {
  useAddProductToCart,
  useSetClubPointOnCart,
} from "../repository/CartRepository";
import { useCustomer } from "../repository/AuthRepository";
import LoadingModalProvider, {
  LoadingModalContext,
} from "./LoadingModalProvider";
import { LocalizedAlertContext } from "./LocalizedAlertProvider";
import { withProviders } from "./Provider";
import { ShoppingCartItemCountContext } from "./ShoppingCartItemCountProvider";
import { useModalState } from "../hook/modal";
import { useChain } from "../hook/utils";
import { actionEvent, pageView } from "../utils/GTM";
import { profiledAsyncActionFn } from "../utils/performance";
import { CheckoutSession } from "../utils/PerformanceRecordStore/sessions";
import { addPerformanceRecord } from "../utils/PerformanceRecordStore";
import { parseGraphQLError } from "../api/GraphQL";
import { MessageID } from "../i18n/translations/type";
import { OrderItem } from "../models/Order";
import { canCustomerSetClubPointOnCart } from "../models/Customer";

import Config from "../Config";

type AddToCartResult = "SUCCESS" | "FAILED" | "OUT OF STOCK";

export type AddToCartCallback = (result: AddToCartResult) => void;

function isSoldOut(message: string) {
  const normalized = message.toLowerCase();
  const matched = /售罄|sold out/.exec(normalized);
  return !!matched;
}

interface AddToCartModalContext {
  presentAddToCartModal: (
    productSku: string,
    formInitialState: FormState,
    options?: {
      campaignId?: number;
      addToCartCallback?: AddToCartCallback;
    }
  ) => void;

  presentAddToCartModalWithOrderItem: (orderItem: OrderItem) => void;
}

interface AddToCartSuccessModalProps {
  count: number;
  cart: Cart;
}

export const AddToCartModalContext = React.createContext<AddToCartModalContext>(
  null as any
);

const AddToCartModalProvider_: React.FC = props => {
  const [
    isPurchaseProductModalOpen,
    presentPurchaseProductionModal,
    dismissPurchaseProductionModal,
  ] = useModalState();

  const [
    isSuccessModalOpen,
    presentSuccessModal,
    dismissSuccessModal,
  ] = useModalState();

  const [product, setProduct] = React.useState<Product | null>(null);
  const [formState, setFormState] = React.useState<FormState | null>(null);
  const campaignIdRef = React.useRef<number | undefined>(undefined);
  const presentingOrderItem = React.useRef<OrderItem | null>(null);

  const [
    addToCartSuccessModalState,
    setAddToCartSuccessModalState,
  ] = React.useState<AddToCartSuccessModalProps | null>(null);

  const [, startRequestingNormalProductWithSku] = useFetchProductDetailBySKU();
  const [
    ,
    startRequestingCampaignProductWithSku,
  ] = useFetchCampaignProductDetailBySKU();

  const {
    count: shoppingCartItemCount,
    setCount: setShoppingCartItemCount,
  } = useContext(ShoppingCartItemCountContext);

  const { presentLocalizedAlert } = useContext(LocalizedAlertContext);

  const loadingModalContext = React.useContext(LoadingModalContext);

  const prevCartItemCountRef = useRef(shoppingCartItemCount);

  const [cartItemCountDiff, setCartItemCountDiff] = useState(0);

  useEffect(() => {
    prevCartItemCountRef.current = shoppingCartItemCount;
  }, [shoppingCartItemCount]);

  const customer = useCustomer();
  const addProductToCart = useAddProductToCart();
  const setClubPointOnCart = useSetClubPointOnCart();
  const onProductAddToCart = useCallback(
    async (
      _product: ProductOverview,
      configuredProduct: ConfiguredProduct | null,
      quantity: number,
      subscribe: SubscribeEnum,
      customizableOptions?: CustomizableOptionInput[],
      options?: {
        ree?: {
          agreement?: boolean;
          service?: ReeService;
        };
        clubprotection?: ClubProtectionInput;
      }
    ) => {
      actionEvent("Add To Cart", "Click", "Add To Cart");
      loadingModalContext.show();
      try {
        let cart = await profiledAsyncActionFn(
          CheckoutSession(),
          `- Add To Cart addProductToCart API (sku=${_product.sku})`,
          addProductToCart
        )(
          _product,
          configuredProduct,
          quantity,
          subscribe,
          campaignIdRef.current,
          customizableOptions,
          options
        );
        /**
         * Reset club point on cart after changing cart item
         */
        if (
          customer &&
          canCustomerSetClubPointOnCart(customer, getMinClubPointUsed(cart))
        ) {
          cart = await setClubPointOnCart(0);
        }
        let count = 0;
        for (const item of cart.items) {
          count += item.quantity;
        }
        setCartItemCountDiff(count - prevCartItemCountRef.current);
        setShoppingCartItemCount(count);
        presentingOrderItem.current = null;
        setAddToCartSuccessModalState({ count: 1, cart });
        dismissPurchaseProductionModal();
        presentSuccessModal();
        if (addToCartCallbackRef.current) {
          addToCartCallbackRef.current("SUCCESS");
        }
      } catch (e) {
        const errorMessage = parseGraphQLError(e);
        const displayMessageID = errorMessage
          ? getAddToCartErrorMessageID(errorMessage)
          : null;
        presentLocalizedAlert({
          headerId: "alert.error.title",
          messageId: displayMessageID
            ? displayMessageID
            : errorMessage
            ? undefined
            : "alert.error.message",
          message: displayMessageID
            ? undefined
            : errorMessage
            ? errorMessage
            : undefined,
          buttons: [
            {
              textMessageID: "alert.button.back",
            },
          ],
        });

        if (addToCartCallbackRef.current) {
          if (errorMessage && isSoldOut(errorMessage)) {
            addToCartCallbackRef.current("OUT OF STOCK");
          } else {
            addToCartCallbackRef.current("FAILED");
          }
        }
      } finally {
        loadingModalContext.hide();
      }
    },
    [
      customer,
      addProductToCart,
      setShoppingCartItemCount,
      loadingModalContext,
      presentLocalizedAlert,
      setAddToCartSuccessModalState,
      dismissPurchaseProductionModal,
      presentSuccessModal,
      setClubPointOnCart,
    ]
  );

  const fetchProduct = useCallback(
    async (sku: string) => {
      return campaignIdRef.current
        ? startRequestingCampaignProductWithSku(campaignIdRef.current, sku)
        : startRequestingNormalProductWithSku(sku);
    },
    [startRequestingNormalProductWithSku, startRequestingCampaignProductWithSku]
  );

  const addProductToCartBySKU = useCallback(
    async (sku: string) => {
      loadingModalContext.show();
      try {
        const productResult = await profiledAsyncActionFn(
          CheckoutSession(),
          `- Add To Cart fetchProduct API (sku=${sku})`,
          fetchProduct
        )(sku);
        loadingModalContext.hide();
        if (productResult != null) {
          const {
            enableDisclaimer,
            enableAgeDeclaration,
            recurringConfiguration,
            enableClubProtection,
            priceRange,
          } = productResult;
          const shouldShowPurchaseForm =
            productResult.type === "configurable" ||
            enableAgeDeclaration ||
            enableDisclaimer ||
            (enableClubProtection != null && priceRange != null
              ? isClubProtectEligible(
                  customer,
                  enableClubProtection,
                  priceRange.minimumPrice.finalPrice.value
                )
              : false) ||
            (productResult.customizableOptions &&
              productResult.customizableOptions.length > 0) ||
            (Config.ENABLE_SUBSCRIPTION &&
              recurringConfiguration &&
              recurringConfiguration.isRecurringEnable);
          if (!shouldShowPurchaseForm) {
            onProductAddToCart(
              productResult,
              null,
              1,
              SubscribeEnum.notSubscribe
            );
            return;
          }
          if (presentingOrderItem.current != null) {
            setFormState(
              getFormInitialStateFromOrderItem(
                presentingOrderItem.current,
                productResult
              )
            );
          }
          setProduct(productResult);
          presentPurchaseProductionModal();
          pageView({ page: "Add To Cart" });
        } else {
          presentingOrderItem.current = null;
          presentLocalizedAlert({
            messageId: "error.unable_to_find_product",
            buttons: [
              {
                textMessageID: "alert.button.ok",
              },
            ],
          });
        }
      } catch (e) {
        presentingOrderItem.current = null;
        loadingModalContext.hide();
        const errorMessage = parseGraphQLError(e);
        presentLocalizedAlert({
          message: errorMessage ? errorMessage : undefined,
          messageId: errorMessage ? undefined : "error.unknown",
          buttons: [
            {
              textMessageID: "alert.button.ok",
            },
          ],
        });
      }
    },
    [
      loadingModalContext,
      fetchProduct,
      onProductAddToCart,
      presentPurchaseProductionModal,
      presentLocalizedAlert,
      customer,
    ]
  );

  const addToCartCallbackRef = useRef<AddToCartCallback | undefined>(undefined);

  useEffect(() => {
    if (!isPurchaseProductModalOpen) {
      // Cleanup callback ref
      addToCartCallbackRef.current = undefined;
    }
  }, [isPurchaseProductModalOpen]);

  const presentAddToCartModal = React.useCallback(
    (
      sku: string,
      _formInitialState: FormState,
      options?: {
        campaignId?: number;
        addToCartCallback?: AddToCartCallback;
      }
    ) => {
      addPerformanceRecord(CheckoutSession(), `Add To Cart (sku=${sku})`);
      addToCartCallbackRef.current = options
        ? options.addToCartCallback
        : undefined;
      campaignIdRef.current = options ? options.campaignId : undefined;
      setFormState(_formInitialState);
      addProductToCartBySKU(sku);
    },
    [addProductToCartBySKU]
  );

  const presentAddToCartModalWithOrderItem = React.useCallback(
    (item: OrderItem, addToCartCallback?: AddToCartCallback) => {
      addToCartCallbackRef.current = addToCartCallback;
      presentingOrderItem.current = item;
      setFormState(getFormInitialState());
      addProductToCartBySKU(item.sku);
    },
    [addProductToCartBySKU]
  );

  const onPurchaseProductModalRequestDismiss = useChain(
    dismissPurchaseProductionModal
  );

  const contextValue = React.useMemo(
    () => ({
      presentAddToCartModal,
      presentAddToCartModalWithOrderItem,
    }),
    [presentAddToCartModal, presentAddToCartModalWithOrderItem]
  );

  const isSoldOutFunction = campaignIdRef.current
    ? isCampaignProductSoldOut
    : isNormalProductSoldOut;

  return (
    <>
      <AddToCartModalContext.Provider value={contextValue}>
        {props.children}
      </AddToCartModalContext.Provider>
      {product != null && formState != null && (
        <PurchaseProductModal
          product={product}
          formInitialState={formState}
          isOpen={isPurchaseProductModalOpen}
          onRequestDismiss={onPurchaseProductModalRequestDismiss}
          onCloseClick={onPurchaseProductModalRequestDismiss}
          onProductAddToCart={onProductAddToCart}
          isSoldOutFunction={isSoldOutFunction}
        />
      )}
      {addToCartSuccessModalState != null && (
        <AddToCartSuccessModal
          itemsAddedCount={cartItemCountDiff}
          cart={addToCartSuccessModalState.cart}
          isOpen={isSuccessModalOpen}
          onRequestDismiss={dismissSuccessModal}
        />
      )}
    </>
  );
};

function getFormInitialStateFromOrderItem(
  item: OrderItem,
  product: Product
): FormState {
  const formState = getFormInitialState();

  if (product.configurableOptions != null) {
    // find the option and option value in product from
    // the order item attribute info option value
    const options = product.configurableOptions;
    (item.attributesInfo || []).forEach(info => {
      for (let i = 0; i < options.length; i++) {
        const opt = options[i];
        for (let j = 0; j < opt.values.length; j++) {
          const value = opt.values[j];
          if (value.value === Number(info.optionValue)) {
            formState.configurationOptionValue[opt.id] = value.value;
          }
        }
      }
    });
  }

  return formState;
}

export const AddToCartModalProvider = withProviders(
  AddToCartModalProvider_,
  LoadingModalProvider
);

function getAddToCartErrorMessageID(errorMessage: string): MessageID | null {
  // Case 1
  const regex = new RegExp(
    "Could not add the product with SKU (.+) to the shopping cart: (.+)"
  );
  const matched = regex.exec(errorMessage);
  if (matched) {
    if (matched[2] === "此產品已缺貨。") {
      return "add_to_cart.error.sold_out";
    }
  }
  return null;
}
